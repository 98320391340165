import { NavigationItem } from '@red/components/navigation';
import { AppConstant } from '../../app.constant';

export const defaultNavigation: NavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'dashboard',
    link: '/dashboard',
    meta: {
      permission: {
        disabled: true,
      },
    },
  },
  {
    id: 'human_resource',
    title: 'Human Resource',
    type: 'collapsable',
    icon: 'hr',
    isSvgIcon: true,
    children: [
      {
        id: 'human_resource.agent_manager',
        title: 'Agent Manager',
        type: 'basic',
        link: `/${AppConstant.ROUTE.PERSONAL_PARTICULARS}`,
        meta: {
          permission: {
            slug: 'hr:agent:read',
          },
        },
      },
      // {
      //   id: 'human_resource.agent_manager',
      //   title: 'Agent Manager',
      //   type: 'collapsable',
      //   children: [
      //     {
      //       id: 'human_resource.agent_manager.personal_particular',
      //       title: 'Personal Particular',
      //       type: 'basic',
      //       link: `/${AppConstant.ROUTE.PERSONAL_PARTICULARS}`,
      //       meta: {
      //         permission: {
      //           slug: 'hr:agent:read',
      //         },
      //       },
      //     },
      //     {
      //       id: 'human_resource.agent_manager.career_progression',
      //       title: 'Career Progression',
      //       type: 'basic',
      //       link: `/${AppConstant.ROUTE.CAREER_PROGRESSIONS}`,
      //       meta: {
      //         permission: {
      //           slug: ['hr:agent:read', 'hr:career_progression:read'],
      //         },
      //       },
      //     },
      //     {
      //       id: 'human_resource.agent_manager.additional_information',
      //       title: 'Additional Information',
      //       type: 'basic',
      //       link: `/${AppConstant.ROUTE.ADDITIONAL_INFORMATIONS}`,
      //       meta: {
      //         permission: {
      //           slug: ['hr:agent:read', 'hr:additional_information:read'],
      //         },
      //       },
      //     },
      //     {
      //       id: 'human_resource.agent_manager.education_record',
      //       title: 'Education Records',
      //       type: 'basic',
      //       link: `/${AppConstant.ROUTE.EDUCATION_RECORDS}`,
      //       meta: {
      //         permission: {
      //           slug: ['hr:agent:read', 'hr:education_record:read'],
      //         },
      //       },
      //     },
      //     {
      //       id: 'human_resource.agent_manager.employment_history',
      //       title: 'Employment History',
      //       type: 'basic',
      //       link: `/${AppConstant.ROUTE.EMPLOYMENT_HISTORIES}`,
      //       meta: {
      //         permission: {
      //           slug: ['hr:agent:read', 'hr:employment_history:read'],
      //         },
      //       },
      //     },
      //     {
      //       id: 'human_resource.agent_manager.upload_document',
      //       title: 'Upload Document',
      //       type: 'basic',
      //       link: AppConstant.ROUTE.PERSONAL_PARTICULAR_UPLOAD_DOCUMENT,
      //       meta: {
      //         permission: {
      //           slug: ['hr:agent:read', 'hr:upload_document:read'],
      //         },
      //       },
      //     },
      //   ],
      // },
      {
        id: 'human_resource.promotion',
        title: 'Promotion',
        type: 'collapsable',
        children: [
          // {
          //   id   : 'human_resource.promotion.designation',
          //   title: 'Designation ',
          //   type : 'basic',
          //   link : `/${AppConstant.ROUTE.DESIGNATION}`
          // },
          {
            id: 'human_resource.promotion.designation',
            title: 'HR Comm Scheme',
            type: 'basic',
            link: `/${AppConstant.ROUTE.COMMISSION_SCHEME}`,
            meta: {
              permission: {
                slug: ['hr:commission_salesperson_schemes:read', 'hr:commission_leadership_schemes:read'],
              },
            },
          },
          {
            id: 'human_resource.promotion.multi_tier_sharing',
            title: 'Multi-Tier Sharing',
            type: 'basic',
            link: `/${AppConstant.ROUTE.PROFIT_SHARING}`,
            meta: {
              permission: {
                slug: 'hr:profit_sharing:read',
              },
            },
          },
          {
            id: 'human_resource.promotion.promotion_approval',
            title: 'Promotion Approval',
            type: 'basic',
            link: AppConstant.ROUTE.PROMOTION_APPROVAL,
            meta: {
              permission: {
                slug: ['hr:promotion_approval:read', 'hr:promotion_approval_history:read'],
              },
            },
          },
        ],
      },
      {
        id: 'human_resource.recruitment',
        title: 'Recruitment',
        type: 'collapsable',
        children: [
          {
            id: 'human_resource.my_recruitment',
            title: 'My Recruitment',
            type: 'basic',
            link: AppConstant.ROUTE.MY_RECRUITMENT,
            meta: {
              permission: {
                slug: 'hr:my_recruitment:read',
              },
            },
          },
          {
            id: 'human_resource.list_recruitment',
            title: 'Manage Recruitments',
            type: 'basic',
            link: AppConstant.ROUTE.RECRUITMENT,
            meta: {
              permission: {
                slug: 'hr:recruitment:read',
              },
            },
          },
          {
            id: 'human_resource.verification_recruitment',
            title: 'Verification List',
            type: 'basic',
            link: AppConstant.ROUTE.RECRUITMENT_VERIFICATION,
            meta: {
              permission: {
                slug: 'hr:recruitment_verification:read',
              },
            },
          },
        ],
      },
      {
        id: 'human_resource.personal_verification',
        title: 'Personal Info Verification',
        type: 'basic',
        link: AppConstant.ROUTE.PERSONAL_VERIFICATION,
        meta: {
          permission: {
            slug: ['hr:personal_info_verification:read', 'hr:bank_info_verification:read'],
          },
        },
      },
      {
        id: 'human_resource.gce_opening_amount',
        title: 'GCE Opening Amount',
        type: 'basic',
        link: AppConstant.ROUTE.GCE_OPENING_AMOUNT,
        meta: {
          permission: {
            slug: 'hr:gce_opening_amount:read',
          },
        },
      },
      {
        id: 'human_resource.ir8a_opening_amount',
        title: 'IR8A Opening Amount',
        type: 'basic',
        link: AppConstant.ROUTE.IR8A_OPENING_AMOUNT,
        meta: {
          permission: {
            slug: 'hr:ir8a_opening_amount:read',
          },
        },
      },
      {
        id: 'human_resource.team_org_chart',
        title: 'Team Org Chart',
        type: 'basic',
        link: AppConstant.ROUTE.TEAM_ORG_CHART,
        meta: {
          permission: {
            slug: 'hr:team_org_chart:read',
          },
        },
      },
      {
        id: 'human_resource.hr_report',
        title: 'HR Report',
        type: 'basic',
        link: `/${AppConstant.ROUTE.HR_REPORT}`,
        meta: {
          permission: {
            slug: 'hr:hr_report:read',
          },
        },
      },
      {
        id: 'human_resource.awards_report',
        title: 'Awards Reports',
        type: 'basic',
        link: `/${AppConstant.ROUTE.AWARDS_REPORT}`,
        meta: {
          permission: {
            slug: 'hr:awards_report:read',
          },
        },
      },
    ],
  },
  {
    id: 'ecdd',
    title: 'ECDD',
    type: 'basic',
    icon: 'ecdd',
    isSvgIcon: true,
    link: AppConstant.ROUTE.ECDD,
    meta: {
      permission: {
        slug: 'ecdd:ecdd:read',
      },
    },
  },
  {
    id: 'transaction',
    title: 'Transaction',
    type: 'collapsable',
    icon: 'transaction',
    isSvgIcon: true,
    children: [
      {
        id: 'transaction.project_admin',
        title: 'Project Admin',
        type: 'collapsable',
        children: [
          {
            id: 'transaction.project_admin.project_transaction',
            title: 'Project Transaction',
            type: 'collapsable',
            children: [
              {
                id: 'transaction.project_admin.project_setup',
                title: 'Project Setup',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_SETUP}`,
                meta: {
                  permission: {
                    slug: 'project:project_setup:read',
                  },
                },
              },
              {
                id: 'transaction.project_admin.project_unit',
                title: 'Project Unit List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_UNIT}`,
                meta: {
                  permission: {
                    slug: 'project:project_setup:read',
                  },
                },
              },
              {
                id: 'transaction.project_admin.key_appointment_list',
                title: 'Key Appointment List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.KEY_APPOINTMENT_LIST}`,
                meta: {
                  permission: {
                    slug: 'project:key_appointment_list:read',
                  },
                },
              },
              {
                id: 'transaction.project_admin.project_consultant',
                title: 'Project Consultant List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_CONSULTANTS}`,
                meta: {
                  permission: {
                    slug: 'project:project_consultant_list:read',
                  },
                },
              },
              {
                id: 'transaction.data_setup.commission_matrix',
                title: 'Comm Matrix List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_COMMISSION_MATRIX}`,
                meta: {
                  permission: {
                    slug: ['project:comm_matrix:read', 'project:comm_matrix-hi:read'],
                  },
                },
              },
              {
                id: 'transaction.data_setup.project_transaction',
                title: 'Transaction List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_TRANSACTIONS}`,
                meta: {
                  permission: {
                    slug: 'project:transaction:read',
                  },
                },
              },
              {
                id: 'transaction.project_admin.blank_form',
                title: 'Blank Form List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.BLANK_FORM}`,
                meta: {
                  permission: {
                    slug: 'project:bf_transaction:read',
                  },
                },
              },
            ],
          },
          {
            id: 'transaction.project_admin.process',
            title: 'Process',
            type: 'collapsable',
            children: [
              {
                id: 'transaction.process.project_comm_matrix_verification',
                title: 'Comm Matrix Verification List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_COMMISSION_MATRIX_VERIFICATION}`,
                meta: {
                  permission: {
                    slug: ['project:comm_matrix_verification:read', 'project:comm_matrix_verification-hi:read'],
                  },
                },
              },
              {
                id: 'transaction.process.project_transaction_verification',
                title: 'Transaction Verification List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_TRANSACTION_VERIFICATIONS}`,
                meta: {
                  permission: {
                    slug: 'project:pending_verification:read',
                  },
                },
              },
              {
                id: 'transaction.process.project_transaction_approval',
                title: 'Transaction Approval List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_TRANSACTION_APPROVAL}`,
                meta: {
                  permission: {
                    slug: 'project:pending_approval:read',
                  },
                },
              },
              {
                id: 'transaction.process.project_transaction_pending_invoice',
                title: 'Transaction Invoice List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_TRANSACTION_PENDING_INVOICE}`,
                meta: {
                  permission: {
                    slug: 'project:pending_invoice_verification:read',
                  },
                },
              },
              {
                id: 'transaction.process.batching_approval',
                title: 'Transaction Batching & Approval List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.BATCHING_APPROVAL}`,
                meta: {
                  permission: {
                    slug: 'project:batch:read',
                  },
                },
              },
              {
                id: 'transaction.project_admin.other.blank_form_verification',
                title: 'Blank Form Verification List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.BLANK_FORM_VERIFICATION}`,
                meta: {
                  permission: {
                    slug: 'project:bfv_transaction:read',
                  },
                },
              },
              {
                id: 'transaction.project_admin.other.blank_form_approval',
                title: 'Blank Form Approval List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.BLANK_FORM_APPROVAL}`,
                meta: {
                  permission: {
                    slug: 'project:bfa_transaction:read',
                  },
                },
              },
              {
                id: 'transaction.project_admin.other.blank_form_invoice',
                title: 'Blank Form Invoice List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.BLANK_FORM_INVOICE}`,
                meta: {
                  permission: {
                    slug: 'project:bfi_transaction:read',
                  },
                },
              },
              {
                id: 'transaction.project_admin.other.blank_form_batching_approval',
                title: 'Blank Form Batching & Approval List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.BLANK_FORM_BATCHING_APPROVAL}`,
                meta: {
                  permission: {
                    slug: 'project:bfba_transaction:read',
                  },
                },
              },
              {
                id: 'transaction.project_admin.other.project_adjustment_verification',
                title: 'Project Adjustment Verification List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_ADJUSTMENT_VERIFICATION}`,
                meta: {
                  permission: {
                    slug: 'project:bfav_transaction:read',
                  },
                },
              },
              {
                id: 'transaction.project_admin.other.project_adjustment_approval',
                title: 'Project Adjustment Approval List',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.PROJECT_ADJUSTMENT_APPROVAL}`,
                meta: {
                  permission: {
                    slug: 'project:bfaa_transaction:read',
                  },
                },
              },
            ],
          },
          {
            id: 'transaction.project_admin.setup',
            title: 'Setup',
            type: 'collapsable',
            children: [
              // {
              //   id: 'transaction.project_admin.developer',
              //   title: 'Developer',
              //   type: 'basic',
              //   link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.DEVELOPERS}`,
              //   meta: {
              //     permission: {
              //       slug: 'project:developer:read',
              //     },
              //   },
              // },
              {
                id: 'transaction.project_admin.management_commission',
                title: 'Management Comm',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.MANAGEMENT_COMMISSION}`,
                meta: {
                  permission: {
                    slug: 'project:management_commission:read',
                  },
                },
              },
              {
                id: 'transaction.data_setup.appointment_type',
                title: 'Appointment Type',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.APPOINTMENT_TYPES}`,
                meta: {
                  permission: {
                    slug: 'project:appointment_type:read',
                  },
                },
              },
              {
                id: 'transaction.data_setup.bonus_distribution',
                title: 'IC Bonus Distribution',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.BONUS_DISTRIBUTIONS}`,
                meta: {
                  permission: {
                    slug: 'project:bonus_distribution:read',
                  },
                },
              },
              {
                id: 'transaction.data_setup.overriding_distribution',
                title: 'IC Overriding Distribution',
                type: 'basic',
                link: `/${AppConstant.ROUTE.PROJECT_ADMIN}/${AppConstant.ROUTE.OVERRIDING_DISTRIBUTIONS}`,
                meta: {
                  permission: {
                    slug: 'project:overriding_distribution:read',
                  },
                },
              },
            ],
          },
        ],
      },
      {
        id: 'transaction.resale_admin',
        title: 'Resale Admin',
        type: 'collapsable',
        children: [
          {
            id: 'transaction.resale_admin.transactions',
            title: 'Transactions',
            type: 'basic',
            link: `/${AppConstant.ROUTE.RESALE_ADMIN}/${AppConstant.ROUTE.RESALE_TRANSACTIONS}`,
            meta: {
              permission: {
                slug: 'project:resale_transaction:read',
              },
            },
          },
          {
            id: 'transaction.resale_admin.submission',
            title: 'Submission List',
            type: 'basic',
            link: `/${AppConstant.ROUTE.RESALE_ADMIN}/${AppConstant.ROUTE.RESALE_SUBMISSION}`,
            meta: {
              permission: {
                slug: 'project:resale_verification:read',
              },
            },
          },
          {
            id: 'transaction.resale_admin.verification',
            title: 'Verification List',
            type: 'basic',
            link: `/${AppConstant.ROUTE.RESALE_ADMIN}/${AppConstant.ROUTE.RESALE_VERIFICATION}`,
            meta: {
              permission: {
                slug: 'project:resale_verification:read',
              },
            },
          },
          {
            id: 'transaction.resale_admin.invoice_interface',
            title: 'Invoice Interface',
            type: 'basic',
            link: `/${AppConstant.ROUTE.RESALE_ADMIN}/${AppConstant.ROUTE.RESALE_INVOICE_INTERFACE}`,
            meta: {
              permission: {
                slug: 'project:resale_invoice_interface:read',
              },
            },
          },
          {
            id: 'transaction.resale_admin.invoice_delivery',
            title: 'Invoice Delivery',
            type: 'basic',
            link: `/${AppConstant.ROUTE.RESALE_ADMIN}/${AppConstant.ROUTE.RESALE_INVOICE_DELIVERY}`,
            meta: {
              permission: {
                slug: 'project:resale_invoice_delivery:read',
              },
            },
          },
        ],
      },
      {
        id: 'project.resale_adjustment',
        title: 'Resale Adjustment',
        type: 'collapsable',
        children: [
          {
            id: 'project.resale_adjustment.resale_adjustment',
            title: 'Resale Adjustment',
            type: 'basic',
            link: `/${AppConstant.ROUTE.RESALE_ADJUSTMENT}/${AppConstant.ROUTE.RESALE_ADJUSTMENT}`,
            meta: {
              permission: {
                slug: 'project:resale_adjustment:read',
              },
            },
          },
          {
            id: 'project.resale_adjustment.resale_adjustment_approval',
            title: 'Resale Adjustment Approval',
            type: 'basic',
            link: `/${AppConstant.ROUTE.RESALE_ADJUSTMENT}/${AppConstant.ROUTE.RESALE_ADJUSTMENT_APPROVAL}`,
            meta: {
              permission: {
                slug: 'project:resale_adjustment_approval:read',
              },
            },
          },
        ],
      },
      {
        id: 'transaction.search_engine',
        title: 'Search Engine',
        type: 'collapsable',
        children: [
          {
            id: 'transaction.search_engine.project_search_engine',
            title: 'Project Search Engine',
            type: 'basic',
            link: `/${AppConstant.ROUTE.SEARCH_ENGINE}/${AppConstant.ROUTE.PROJECT_SEARCH_ENGINE}`,
            meta: {
              permission: {
                slug: 'project:project_search_engine:read',
              },
            },
          },
          {
            id: 'transaction.search_engine.batch_search_engine',
            title: 'Batch Search Engine',
            type: 'basic',
            link: `/${AppConstant.ROUTE.SEARCH_ENGINE}/${AppConstant.ROUTE.BATCH_SEARCH_ENGINE}`,
            meta: {
              permission: {
                slug: 'project:batch_search_engine:read',
              },
            },
          },
          {
            id: 'transaction.search_engine.resale_search_engine',
            title: 'Resale Search Engine',
            type: 'basic',
            link: `/${AppConstant.ROUTE.SEARCH_ENGINE}/${AppConstant.ROUTE.RESALE_SEARCH_ENGINE}`,
            meta: {
              permission: {
                slug: 'project:resale_search_engine:read',
              },
            },
          },
        ],
      },
      {
        id: 'transaction.project_report',
        title: 'Project Report',
        type: 'basic',
        link: `/${AppConstant.ROUTE.PROJECT_REPORT}`,
        meta: {
          permission: {
            slug: 'project:project_report:read',
          },
        },
      },
      {
        id: 'transaction.resale_report',
        title: 'Resale Report',
        type: 'basic',
        link: `/${AppConstant.ROUTE.RESALE_REPORT}`,
        meta: {
          permission: {
            slug: 'project:resale_report:read',
          },
        },
      },
      {
        id: 'transaction.reports',
        title: 'Reports',
        type: 'basic',
        link: `/${AppConstant.ROUTE.REPORTS}/${AppConstant.ROUTE.COMMON}`,
        meta: {
          permission: {
            slug: 'project:common_report:read',
          },
        },
      },
    ],
  },
  {
    id: 'finance',
    title: 'Finance',
    type: 'collapsable',
    icon: 'finance',
    isSvgIcon: true,
    children: [
      {
        id: 'finance.general_ledger',
        title: 'General Ledger',
        type: 'collapsable',
        children: [
          {
            id: 'finance.general_ledger.charts_of_account',
            title: 'Charts Of Accounts',
            type: 'basic',
            link: `/${AppConstant.ROUTE.GENERAL_LEDGER}/${AppConstant.ROUTE.GENERAL_LEDGER_CHART_OF_ACCOUNT}`,
            meta: {
              permission: {
                slug: ['finance:chart_of_account_local:read', 'finance:chart_of_account_master:read'],
              },
            },
          },
          {
            id: 'finance.general_ledger.account_setup',
            title: 'Account Setup',
            type: 'basic',
            link: `/${AppConstant.ROUTE.GENERAL_LEDGER}/${AppConstant.ROUTE.GENERAL_LEDGER_ACCOUNT_SETUP}`,
            meta: {
              permission: {
                slug: 'finance:account_setup:read',
              },
            },
          },
          {
            id: 'finance.general_ledger.profit_centres',
            title: 'Profit Centres',
            type: 'basic',
            link: `/${AppConstant.ROUTE.GENERAL_LEDGER}/${AppConstant.ROUTE.GENERAL_LEDGER_PROFIT_CENTES}`,
            meta: {
              permission: {
                slug: 'finance:profit_center:read',
              },
            },
          },
          {
            id: 'finance.general_ledger.journal_entries',
            title: 'Journal Entries',
            type: 'basic',
            link: `/${AppConstant.ROUTE.GENERAL_LEDGER}/${AppConstant.ROUTE.GENERAL_LEDGER_JOURNAL_ENTRIES}`,
            meta: {
              permission: {
                slug: 'finance:journal:read',
              },
            },
          },
          {
            id: 'finance.general_ledger.accounting_periods',
            title: 'Accounting Periods',
            type: 'basic',
            link: `/${AppConstant.ROUTE.GENERAL_LEDGER}/${AppConstant.ROUTE.GENERAL_LEDGER_ACCOUNTING_PERIODS}`,
            meta: {
              permission: {
                slug: 'finance:accounting_period:read',
              },
            },
          },
        ],
      },
      {
        id: 'finance.account_receivables',
        title: 'Account Receivables',
        type: 'collapsable',
        children: [
          {
            id: 'finance.account_receivables.customer',
            title: 'Customer',
            type: 'basic',
            link: `/${AppConstant.ROUTE.ACCOUNT_RECEIVABLES}/${AppConstant.ROUTE.CUSTOMER}`,
            meta: {
              permission: {
                slug: 'finance:customer:read',
              },
            },
          },
          {
            id: 'finance.account_receivables.tax_invoice',
            title: 'Tax Invoice',
            type: 'basic',
            link: `/${AppConstant.ROUTE.ACCOUNT_RECEIVABLES}/${AppConstant.ROUTE.TAX_INVOICE}/${AppConstant.ROUTE.CUSTOMER}`,
            meta: {
              permission: {
                slug: 'finance:tax_invoice:read',
              },
            },
          },
          {
            id: 'finance.account_receivables.credit_note',
            title: 'Credit Note',
            type: 'basic',
            link: `/${AppConstant.ROUTE.ACCOUNT_RECEIVABLES}/${AppConstant.ROUTE.CREDIT_NOTE}/${AppConstant.ROUTE.CUSTOMER}`,
            meta: {
              permission: {
                slug: 'finance:credit_note:read',
              },
            },
          },
          {
            id: 'finance.account_receivables.debit_note',
            title: 'Debit Note',
            type: 'basic',
            link: `/${AppConstant.ROUTE.ACCOUNT_RECEIVABLES}/${AppConstant.ROUTE.DEBIT_NOTE}/${AppConstant.ROUTE.CUSTOMER}`,
            meta: {
              permission: {
                slug: 'finance:debit_note:read',
              },
            },
          },
        ],
      },
      {
        id: 'finance.account_payables',
        title: 'Account Payables',
        type: 'collapsable',
        children: [
          {
            id: 'finance.account_payables.supplier',
            title: 'Supplier',
            type: 'basic',
            link: `/${AppConstant.ROUTE.ACCOUNT_PAYABLES}/${AppConstant.ROUTE.SUPPLIER}`,
            meta: {
              permission: {
                slug: 'finance:supplier:read',
              },
            },
          },
          {
            id: 'finance.account_payables.tax_invoice',
            title: 'Supplier Invoice',
            type: 'basic',
            link: `/${AppConstant.ROUTE.ACCOUNT_PAYABLES}/${AppConstant.ROUTE.TAX_INVOICE}/${AppConstant.ROUTE.SUPPLIER}`,
            meta: {
              permission: {
                slug: 'finance:supplier_invoice:read',
              },
            },
          },
          {
            id: 'finance.account_payables.credit_note',
            title: 'Supplier Credit Note',
            type: 'basic',
            link: `/${AppConstant.ROUTE.ACCOUNT_PAYABLES}/${AppConstant.ROUTE.CREDIT_NOTE}/${AppConstant.ROUTE.SUPPLIER}`,
            meta: {
              permission: {
                slug: 'finance:supplier_credit_note:read',
              },
            },
          },
        ],
      },
      {
        id: 'finance.fixed_asset',
        title: 'Fixed Asset',
        type: 'collapsable',
        children: [
          {
            id: 'finance.fixed_asset.asset_category',
            title: 'Asset Category',
            type: 'basic',
            link: `/${AppConstant.ROUTE.FIXED_ASSET}/${AppConstant.ROUTE.ASSET_CATEGORY}`,
            meta: {
              permission: {
                slug: 'finance:asset_category:read',
              },
            },
          },
          {
            id: 'finance.fixed_asset.asset-register',
            title: 'Asset Register',
            type: 'basic',
            link: `/${AppConstant.ROUTE.FIXED_ASSET}/${AppConstant.ROUTE.ASSET_REGISTER}`,
            meta: {
              permission: {
                slug: 'finance:asset_register:read',
              },
            },
          },
          {
            id: 'finance.fixed_asset.asset-depreciation',
            title: 'Asset Depreciation',
            type: 'basic',
            link: `/${AppConstant.ROUTE.FIXED_ASSET}/${AppConstant.ROUTE.ASSET_DEPRECIATION}`,
            meta: {
              permission: {
                slug: 'finance:asset_depreciation:read',
              },
            },
          },
          // {
          //   id: 'finance.fixed_asset.asset-disposal',
          //   title: 'Asset Disposals',
          //   type: 'basic',
          //   link: `/${AppConstant.ROUTE.FIXED_ASSET}/${AppConstant.ROUTE.ASSET_DISPOSALS}`,
          //   meta: {
          //     permission: {
          //       // slug: 'finance:asset_register:read',
          //       disabled: true,
          //     },
          //   },
          // },
        ],
      },
      {
        id: 'finance.finance_report',
        title: 'Financial Reports',
        type: 'basic',
        link: `/${AppConstant.ROUTE.FINANCE_REPORT}`,
        meta: {
          permission: {
            slug: 'finance:finance_report:read',
          },
        },
      },
      {
        id: 'finance.customer_report',
        title: 'Customer Reports',
        type: 'basic',
        link: `/${AppConstant.ROUTE.CUSTOMER_REPORT}`,
        meta: {
          permission: {
            slug: 'finance:customer_report:read',
          },
        },
      },
      {
        id: 'finance.supplier_report',
        title: 'Supplier Reports',
        type: 'basic',
        link: `/${AppConstant.ROUTE.SUPPLIER_REPORT}`,
        meta: {
          permission: {
            slug: 'finance:supplier_report:read',
          },
        },
      },
      {
        id: 'finance.bank_report',
        title: 'Banking Reports',
        type: 'basic',
        link: `/${AppConstant.ROUTE.BANK_REPORT}`,
        meta: {
          permission: {
            slug: 'finance:banking_report:read',
          },
        },
      },
      {
        id: 'finance.banking',
        title: 'Banking',
        type: 'collapsable',
        children: [
          {
            id: 'finance.banking.bank_account',
            title: 'Bank Accounts',
            type: 'basic',
            link: `/${AppConstant.ROUTE.BANK_ACCOUNT}`,
            meta: {
              permission: {
                slug: 'finance:bank_account:read',
              },
            },
          },
          {
            id: 'finance.banking.cheque',
            title: 'Cheque Books',
            type: 'basic',
            link: `/${AppConstant.ROUTE.CHEQUE}`,
            meta: {
              permission: {
                slug: 'finance:cheque:read',
              },
            },
          },
          {
            id: 'finance.banking.receipt',
            title: 'Receipts',
            type: 'basic',
            link: `/${AppConstant.ROUTE.RECEIPT}`,
            meta: {
              permission: {
                slug: 'finance:receipt:read',
              },
            },
          },
          {
            id: 'finance.banking.payment',
            title: 'Payments',
            type: 'basic',
            link: `/${AppConstant.ROUTE.PAYMENT}`,
            meta: {
              permission: {
                slug: 'finance:payment:read',
              },
            },
          },
          {
            id: 'finance.banking.direct_debit_payment',
            title: 'Direct Debit Payment',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DIRECT_DEBIT_PAYMENT}`,
            meta: {
              permission: {
                slug: 'finance:direct_debit_payment:read',
              },
            },
          },
          {
            id: 'finance.banking.payment_generator',
            title: 'Payment Generator',
            type: 'basic',
            link: `/${AppConstant.ROUTE.PAYMENT_GENERATOR}`,
            meta: {
              permission: {
                slug: 'finance:payment_generator:read',
              },
            },
          },
          {
            id: 'finance.banking.payment_reversal',
            title: 'Reversal Manager',
            type: 'basic',
            link: `/${AppConstant.ROUTE.REVERSAL_MANAGER}`,
            meta: {
              permission: {
                slug: 'finance:payment_reversal:read',
              },
            },
          },
        ],
      },
      // {
      //   id: 'finance.invoice-writeoff',
      //   title: 'Write Off',
      //   type: 'basic',
      //   link: `/${AppConstant.ROUTE.INVOICE_WRITEOFF}`,
      //   meta: {
      //     permission: {
      //       // slug: 'finance:finance-report:read',
      //       disabled: true,
      //     },
      //   },
      // },
      {
        id: 'finance.search_engine_finance',
        title: 'Search Engine',
        type: 'collapsable',
        children: [
          {
            id: 'finance.search_engine_finance.project_search_engine',
            title: 'Project Search Engine',
            type: 'basic',
            link: `/${AppConstant.ROUTE.SEARCH_ENGINE_FINANCE}/${AppConstant.ROUTE.PROJECT_SEARCH_ENGINE}`,
            meta: {
              permission: {
                slug: 'finance:project_search_engine:read',
              },
            },
          },
          {
            id: 'finance.search_engine_finance.batch_search_engine',
            title: 'Batch Search Engine',
            type: 'basic',
            link: `/${AppConstant.ROUTE.SEARCH_ENGINE_FINANCE}/${AppConstant.ROUTE.BATCH_SEARCH_ENGINE}`,
            meta: {
              permission: {
                slug: 'finance:batch_search_engine:read',
              },
            },
          },
          {
            id: 'finance.search_engine_finance.resale_search_engine',
            title: 'Resale Search Engine',
            type: 'basic',
            link: `/${AppConstant.ROUTE.SEARCH_ENGINE_FINANCE}/${AppConstant.ROUTE.RESALE_SEARCH_ENGINE}`,
            meta: {
              permission: {
                slug: 'finance:resale_search_engine:read',
              },
            },
          },
        ],
      },
      {
        id: 'finance.data_import',
        title: 'Data Import',
        type: 'collapsable',
        children: [
          {
            id: 'finance.data_import.contact',
            title: 'Import Customers / Suppliers',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_IMPORT}/${AppConstant.ROUTE.IMPORT_CONTACT}`,
            meta: {
              permission: {
                slug: ['finance:customer:create', 'finance:supplier:create'],
              },
            },
          },
          {
            id: 'finance.data_import.chart_of_accounts',
            title: 'Import Chart Of Accounts',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_IMPORT}/${AppConstant.ROUTE.IMPORT_ACCOUNT}`,
            meta: {
              permission: {
                slug: ['finance:chart_of_account_master:create', 'finance:chart_of_account_master:verify'],
              },
            },
          },
          {
            id: 'finance.data_import.receipt',
            title: 'Import Receipts',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_IMPORT}/${AppConstant.ROUTE.IMPORT_RECEIPT}`,
            meta: {
              permission: {
                slug: ['finance:receipt:create'],
              },
            },
          },
          {
            id: 'finance.data_import.payment',
            title: 'Import Payments',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_IMPORT}/${AppConstant.ROUTE.IMPORT_PAYMENT}`,
            meta: {
              permission: {
                slug: ['finance:payment:create'],
              },
            },
          },
          {
            id: 'finance.data_import.journal_entry',
            title: 'Import Journal Entries',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_IMPORT}/${AppConstant.ROUTE.IMPORT_JOURNAL_ENTRY}`,
            meta: {
              permission: {
                slug: ['finance:journal:create'],
              },
            },
          },
          {
            id: 'configuration.data_import.supplier_invoice',
            title: 'Import Supplier Invoices',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_IMPORT}/${AppConstant.ROUTE.IMPORT_SUPPLIER_INVOICE}`,
            meta: {
              permission: {
                slug: 'finance:supplier_invoice:create',
              },
            },
          },
          {
            id: 'configuration.data_import.ar',
            title: 'Import Tax Invoices / CN / DN',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_IMPORT}/${AppConstant.ROUTE.IMPORT_AR}`,
            meta: {
              permission: {
                slug: ['finance:tax_invoice:create', 'finance:credit_note:create', 'finance:debit_note:create'],
              },
            },
          },
        ],
      },
    ],
  },
  {
    id: 'configuration',
    title: 'Configuration',
    type: 'collapsable',
    icon: 'settings',
    children: [
      {
        id: 'configuration.access_control',
        title: 'User & Access Control',
        type: 'collapsable',
        children: [
          {
            id: 'configuration.access_control.user',
            title: 'User Account',
            type: 'basic',
            link: `/${AppConstant.ROUTE.ACCESS_CONTROL}/${AppConstant.ROUTE.USER_ACCOUNTS}`,
            meta: {
              permission: {
                slug: 'settings:users:read',
              },
            },
          },
          // {
          //   id: 'configuration.access_control.user_group',
          //   title: 'User Group',
          //   type: 'basic',
          //   link: `/${AppConstant.ROUTE.ACCESS_CONTROL}/${AppConstant.ROUTE.USER_GROUPS}`,
          //   meta: {
          //     permission: {
          //       slug: 'settings:groups:read',
          //     },
          //   },
          // },
          {
            id: 'configuration.access_control.user_role',
            title: 'Roles',
            type: 'basic',
            link: `/${AppConstant.ROUTE.ACCESS_CONTROL}/${AppConstant.ROUTE.USER_ROLES}`,
            meta: {
              permission: {
                slug: 'settings:roles:read',
              },
            },
          },
        ],
      },
      {
        id: 'configuration.data_setup',
        title: 'Data Setup',
        type: 'collapsable',
        children: [
          {
            id: 'configuration.data_setup.lookup_tables',
            title: 'Lookup Tables',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.LOOKUP_TABLES}`,
            meta: {
              permission: {
                slug: 'configuration:lookup:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.bank',
            title: 'Bank',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.BANK}`,
            meta: {
              permission: {
                slug: 'configuration:bank:read',
              },
            },
          },
          // {
          //   id: 'configuration.data_setup.commission_matrix',
          //   title: 'Commission Matrix Setup',
          //   type: 'basic',
          //   link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.COMMISSION_MATRIX}`,
          //   meta: {
          //     permission: {
          //       disabled: true,
          //     },
          //   },
          // },
          {
            id: 'configuration.data_setup.contact_category',
            title: 'Contact Category',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.CONTACT_CATEGORY}`,
            meta: {
              permission: {
                slug: 'configuration:contact_category:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.country',
            title: 'Country',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.COUNTRY}`,
            meta: {
              permission: {
                slug: 'configuration:country:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.dialect',
            title: 'Dialect',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.DIALECT}`,
            meta: {
              permission: {
                slug: 'configuration:dialect:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.deparment',
            title: 'Department',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.DEPARTMENT}`,
            meta: {
              permission: {
                slug: 'configuration:deparment:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.discipline',
            title: 'Discipline',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.DISCIPLINE}`,
            meta: {
              permission: {
                slug: 'configuration:discipline:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.gst_rate',
            title: 'GST Rate',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.GST_RATE}`,
            meta: {
              permission: {
                slug: 'configuration:gst_rate:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.currency_rate',
            title: 'Currency Rate',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.CURRENCY_RATE}`,
            meta: {
              permission: {
                slug: 'configuration:currency_rate:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.id_type',
            title: 'ID Type',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.ID_TYPE}`,
            meta: {
              permission: {
                slug: 'configuration:id_type:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.nationality',
            title: 'Nationality',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.NATIONALITY}`,
            meta: {
              permission: {
                slug: 'configuration:nationality:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.qualification',
            title: 'Qualification',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.QUALIFICATION}`,
            meta: {
              permission: {
                slug: 'configuration:qualification:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.scheme_type',
            title: 'Scheme Type',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.SCHEME_TYPE}`,
            meta: {
              permission: {
                slug: 'configuration:scheme_type:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.comm_matrix_type',
            title: 'Comm Matrix Type',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.COMMMATRIX_TYPE}`,
            meta: {
              permission: {
                slug: 'configuration:category:read',
              },
            },
          },
          {
            id: 'configuration.data_setup.transaction_type',
            title: 'Transaction Type',
            type: 'basic',
            link: `/${AppConstant.ROUTE.DATA_SETUP}/${AppConstant.ROUTE.TRANSACTION_TYPE}`,
            meta: {
              permission: {
                slug: 'configuration:category_transaction_type:read',
              },
            },
          },
        ],
      },
      {
        id: 'configuration.message-outbox',
        title: 'Message Outbox',
        type: 'basic',
        link: `/${AppConstant.ROUTE.MESSAGE_OUTBOX}`,
        meta: {
          permission: {
            slug: 'configuration:message_outbox:read',
          },
        },
      },
    ],
  },
];
export const compactNavigation: NavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    tooltip: 'Dashboard',
    type: 'aside',
    icon: 'dashboard',
    link: '/dashboard',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'human_resource',
    title: 'Human Resource',
    tooltip: 'Human Resource',
    type: 'aside',
    icon: 'hr',
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'ecdd',
    title: 'ECDD',
    tooltip: 'ECDD',
    type: 'aside',
    link: AppConstant.ROUTE.ECDD,
    icon: 'ecdd',
    isSvgIcon: true,
    meta: {
      permission: {
        slug: 'ecdd:ecdd:read',
      },
    },
  },
  {
    id: 'transaction',
    title: 'Transaction',
    tooltip: 'Transaction',
    type: 'aside',
    icon: 'transaction',
    isSvgIcon: true,
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
  {
    id: 'finance',
    title: 'Finance',
    type: 'aside',
    tooltip: 'Finance',
    icon: 'finance',
    isSvgIcon: true,
    children: [],
  },
  {
    id: 'configuration',
    title: 'Configuration',
    tooltip: 'Configuration',
    type: 'aside',
    icon: 'settings',
    children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
  },
];
