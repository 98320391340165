<div class="flex flex-col flex-auto relative">
  <div class="sticky z-10 top-0 border-b max-h-16 flex flex-col w-full">
    <div class="flex flex-row items-center justify-between bg-theme-navy-blue">
      <div class="flex flex-row items-center gap-4 p-3 ">
        <div >
          <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon class="text-white" svgIcon="menu"></mat-icon>
          </button>
        </div>
        <a routerLink="/">
          <img class="max-h-10" src="assets/images/brand/logo_white.png" alt="logo app">
        </a>
      </div>
      <div class="flex flex-row items-center gap-6 pr-5">  
        <cms-user></cms-user>
      </div>
    </div>

  </div>
 <div class="flex z-0 flex-row flex-auto">
   <!-- Navigation -->
  <red-vertical-navigation
  *ngIf="navigation"
  class="bg-white print:hidden"
  [appearance]="isScreenSmall ? 'compact': 'default'"
  mode="side"
  [name]="'mainNavigation'"
  [navigation]="isScreenSmall ? navigation.compact : navigation.default"
  [autoCollapse]="false">
  <!-- Navigation header hook -->
  </red-vertical-navigation>
  <!-- Content -->
<div class="flex flex-col flex-auto layout-content">

      <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
       Otherwise, layout changes won't be registered and the view won't be updated! -->
      <router-outlet *ngIf="true"></router-outlet>
    </div>
  </div>

</div>
