import { BaseModel, Default, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EFinanceCalculatingOrigin } from '../enums';
import { EBalanceDocumentPostingType } from '../enums/tax-invoice-enhanced.enum';

export class DebitNoteRecordDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  itemCode?: string;

  @Expose()
  @Type(() => Number)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  discount?: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Default(false)
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  @Type(() => Number)
  accountId?: number;

  @Expose()
  @Type(() => String)
  accountCode!: string;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  remarks?: string;

  @Expose()
  description?: string;

  @Expose()
  uom?: string;
}

export class DebitNotePostingDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => String)
  accountCode!: string;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => Number)
  debit?: number;

  @Expose()
  @Type(() => Number)
  credit?: number;

  @Expose()
  type?: EBalanceDocumentPostingType;
}

export class DebitNoteDto extends BaseModel {
  //  ====== General ======
  // @Expose()
  // @Default(ECustomerOrSupplier.CUSTOMER)
  // invoiceType!: ECustomerOrSupplier;

  @Expose()
  @Type(() => Number)
  contactId?: number;

  @Expose()
  @Type(() => Number)
  contactPersonId?: number;

  @Expose()
  @Type(() => Number)
  contactAddressId?: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  debitNoteDate!: Date;

  // @Expose()
  // @Default('SGD')
  // @Transform(({ value }) => 'SGD')
  // currency!: string;

  @Expose()
  @Type(() => Number)
  currencyId!: number;

  //  ====== Other detail ======
  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  @Expose()
  // @Type(() => Number)
  // @Default(0)
  // paymentTerm?: number;
  paymentTerm?: string;

  // @Expose()
  // printFormat?: string;

  @Expose()
  @Type(() => Number)
  salePersonId?: number;

  @Expose()
  billingAddressCustom?: string;

  @Expose()
  contactPersonCustom?: string;

  @Expose()
  reference?: string;

  // @Expose()
  // @Type(() => Number)
  // templateId?: number;

  //  ====== Calculate data =====
  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => Number)
  gstPercent?: number;

  // @Expose()
  // @Type(() => Number)
  // taxValue?: number;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  gstEdited?: boolean;

  @Expose()
  @Type(() => DebitNoteRecordDto)
  details!: DebitNoteRecordDto[];

  @Expose()
  @Type(() => DebitNotePostingDto)
  postings!: DebitNotePostingDto[];
}

export class DebitNotePostingReqItemDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => String)
  accountCode!: string;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  discount!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  @Type(() => Number)
  amount?: number;
}

export class DebitNotePostingReqDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  @Default(8)
  gstPercent!: number;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  gstEdited!: boolean;

  @Expose()
  @Type(() => Number)
  amount!: number;

  // @Expose()
  // @Default(ECustomerOrSupplier.CUSTOMER)
  // type!: ECustomerOrSupplier;

  @Expose()
  @Type(() => Number)
  contactId!: number | null;

  @Expose()
  @Type(() => DebitNotePostingReqItemDto)
  items!: DebitNotePostingReqItemDto[];
}

export class DebitNoteCalculateSummaryReqDto extends BaseModel {
  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  @Default(8)
  gstPercent!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Boolean)
  @Default(false)
  gstEdited!: boolean;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  gstValue!: number;

  // @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  // @Type(() => Number)
  // amount!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => DebitNoteRecordDto)
  details!: DebitNoteRecordDto[];
}

// Pdf Preview
export class DebitNotePreviewInvoicePdfDetailDto extends BaseModel {
  @Expose()
  id?: number;

  @Expose()
  description!: string;

  @Expose()
  quantity!: number;

  @Expose()
  unitPrice!: number;

  @Expose()
  itemCode?: string;

  @Expose()
  gstValue?: number;

  @Expose()
  discount?: number;

  @Expose()
  amount!: number;

  @Expose()
  gstInclusive!: boolean;
}

export class DebitNotePreviewInvoicePdfDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  invoiceId?: number;

  @Expose()
  @Default(21)
  @Type(() => Number)
  templateId?: number;

  @Expose()
  customerName?: string;

  @Expose()
  billingAddress?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDate?: string;

  @Expose()
  invoiceNumber?: string;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  @Expose()
  reference?: string;

  @Expose()
  @Type(() => Number)
  @Default(8)
  gstPercent?: number;

  @Expose()
  currency?: string;

  @Expose()
  contactPerson?: string;

  @Expose()
  contactCode?: string;

  // @Expose()
  // @Type(() => Number)
  // taxValue?: number;

  @Expose()
  @Type(() => DebitNotePreviewInvoicePdfDetailDto)
  details?: DebitNotePreviewInvoicePdfDetailDto[];
}
