import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { CompanyModel, ReceiptModel, ReferralExternalModel } from '@shared/data-access/models';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MasterDataApiService {
  static REFERRAL_EXTERNALS_POINT = 'transaction/master-data/portal/referral-externals';
  static RECEIPTS_POINT = 'transaction/master-data/receipts';
  static COMPANIES = 'settings/companies/list-common';
  // static REFERRAL_FORMULAS_POINT = 'transaction/master-data/referral-formulas';
  // static TRANSACTION_TAX_INVOICE_POINT = 'transaction/master-data/tax-invoices';

  constructor(private apiService: ApiService) { }

  getReferralExternals(query: any = {}, option?: IApiOption): Observable<PaginationAdapter<ReferralExternalModel>> {
    return this.apiService.get(MasterDataApiService.REFERRAL_EXTERNALS_POINT, query, option).pipe(map(data => new PaginationAdapter(ReferralExternalModel, data)));
  }

  getReceipts(query: any = {}, option?: IApiOption): Observable<ReceiptModel[]> {
    return this.apiService.get(MasterDataApiService.RECEIPTS_POINT, query, option).pipe(map(res => res.map((data: any) => ReceiptModel.fromJson(data) as ReceiptModel)));
  }

  getCompanies(query: any = {}, option?: IApiOption): Observable<any> {
    return this.apiService.get(MasterDataApiService.COMPANIES, query, option).pipe(map(data => new PaginationAdapter(CompanyModel, data)));
  }
}
