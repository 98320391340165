import { BaseModel, PhoneNumber } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { BUYER_PROPERTY_TYPE_OPTIONS, BUYER_TYPE_OPTIONS, CITIZEN_OPTIONS, GENDER_OPTIONS, NUMBER_OF_OPTIONS, PURPOSE_OPTIONS, YES_NO_OPTIONS } from '../data';
import { EBuyerPropertyType, EBuyerType, ECitizen, EGender, ENumberOf, EPurpose } from '../enums';
import { IBlankFormBuyer, IBlankFormBuyerInfo } from '../interfaces';
import { AddressModel } from './address.model';
import { ContactCategoryModel } from './contact-category.model';
import { DistrictModel } from './district.model';
import { MaritalModel } from './marital.model';
import { NationalityModel } from './nationality.model';

export class BlankFormBuyerInfoModel extends BaseModel implements IBlankFormBuyerInfo {
  @Expose()
  remark!: string;
}

export class BlankFormBuyerModel extends BaseModel implements IBlankFormBuyer {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  type!: EBuyerType;

  @Expose()
  gender!: EGender;

  @Expose()
  @Type(() => MaritalModel)
  marital!: MaritalModel;

  @Expose()
  @Type(() => Number)
  maritalId!: number;

  @Expose()
  propertyType!: EBuyerPropertyType;

  @Expose()
  categoryId!: number;

  @Expose()
  @Type(() => ContactCategoryModel)
  category!: ContactCategoryModel;

  @Expose()
  postal!: string;

  @Expose()
  nricNo!: string;

  @Expose()
  citizen!: ECitizen;

  @Expose()
  yearOfBirth!: number;

  @Expose()
  @Type(() => AddressModel)
  address!: AddressModel;

  @Expose()
  nationalityId!: number;

  @Expose()
  nationality!: string;

  @Expose()
  @Type(() => NationalityModel)
  nationalityObj!: NationalityModel;

  @Expose()
  @Type(() => PhoneNumber)
  mobile!: PhoneNumber;

  @Expose()
  email!: string;

  @Expose()
  ownership!: ENumberOf;

  @Expose()
  districtId!: number;

  @Expose()
  occupation!: string;

  @Expose()
  @Type(() => DistrictModel)
  district!: DistrictModel;

  @Expose()
  isHdbOwnership!: number;

  @Expose()
  purposeOfPurchase!: EPurpose;

  //#region timestamps
  @Expose()
  createdAt!: string;
  @Expose()
  updatedAt!: string;
  @Expose()
  deletedAt?: string;
  @Expose()
  createdBy?: string;
  @Expose()
  updatedBy?: string;
  @Expose()
  deletedBy?: string;
  //#endregion

  //#region getter
  get typeInfo() {
    return BUYER_TYPE_OPTIONS[this.type] ?? null;
  }
  get genderInfo() {
    return GENDER_OPTIONS[this.gender] ?? null;
  }
  get propertyTypeInfo() {
    return BUYER_PROPERTY_TYPE_OPTIONS[this.propertyType] ?? null;
  }
  get citizenInfo() {
    return CITIZEN_OPTIONS[this.citizen] ?? null;
  }
  get ownershipInfo() {
    return NUMBER_OF_OPTIONS[this.ownership] ?? null;
  }
  get isHdbOwnershipInfo() {
    switch (this.isHdbOwnership) {
      case 1:
        return YES_NO_OPTIONS.Yes;
      case 0:
        return YES_NO_OPTIONS.No;
      default:
        return null;
    }
  }
  get purposeOfPurchaseInfo() {
    return PURPOSE_OPTIONS[this.purposeOfPurchase] ?? null;
  }
  //#endregion
}
