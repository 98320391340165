import { EReceiptMemoType } from '../enums';
import { ReceiptMode, ReceiptStatus, ReceiptType } from '../types/receipt.type';
import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { IDealValue } from './deal-value.interface';
import { IDebitNote } from './debit-note-enhanced.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IProfitCentres } from './profit-centres.interface';
import { ITaxInvoice } from './tax-invoice-enhanced.interface';

export interface IReceiptMemoContact {
  id: number;
  code: string;
  name: string;
  receiveAccountId: number;
  receiveAccount: Partial<ILedgerAccount>;
  paidAccountId: number;
  paidAccount: Partial<ILedgerAccount>;
}

export interface IReceiptMemoPostingAccount {
  accountCode: string;
  accountId: number;
  accountName: string;
}

export interface IReceiptMemo {
  id: number;
  code: string;
  invoiceDate: string;
  contactId: number;
  total: number;
  type: 'IV' | 'DN';
  paidAmount: number;
  balanceDue: number;
  // createdAt?: Date;
  // updatedAt?: Date;
  // deletedAt?: Date;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  contact: IReceiptMemoContact;
  posting: IReceiptMemoPostingAccount
}

export interface IReceipt {
  id: number;
  businessUnitId: number;
  code: string;
  accountId: number;
  accountCode: string;
  account: ILedgerAccount;
  contactId: number;
  contact: IContactCustomerAndSupplier;
  payerName: string;
  currency: string;
  reference: string;
  // receiptDate: Date;
  receiptDate: string;
  type: ReceiptType;
  mode: ReceiptMode;
  totalAmount: number;
  templateId: number;
  // createdAt: Date;
  // updatedAt: Date;
  createdAt: string;
  updatedAt: string;
  status: ReceiptStatus;
  inClosedPeriod: boolean;
  // inPaymentGenerator: boolean;
  inPaymentBilling: boolean;
  isBlocked?: boolean;
}

export interface IReceiptRecordMetadata {
  taxPercent: number;
  isEdit: boolean;
}

export interface IReceiptSupplierCreditNoteDetail {
  id: number;
  taxInvoiceId: number;
  paidAmount: number;
  total: number;
  balanceDue: number;
  creditNoteId: number;
}

export interface IReceiptRecord {
  id: number;
  type: string;

  receiptId?: number;
  receipt?: IReceipt;

  accountId: number;
  accountCode: string;
  account: ILedgerAccount;

  profitCenterId?: number;
  profitCenter?: IProfitCentres;

  invoiceId: number;
  invoiceNumber?: string;
  invoiceDate?: string;
  invoice: ITaxInvoice;
  invoiceRate: number;
  invoiceBalance: number;

  receiptMemoId?: number;
  receiptMemoType?: `${EReceiptMemoType}`;
  customerInvoice?: ITaxInvoice;
  customerDebitNote?: IDebitNote;

  supplierCreditNoteDetail?: IReceiptSupplierCreditNoteDetail;
  supplierCreditNoteDetailId?: number;

  description: string;

  debit: IDealValue;
  credit: IDealValue;

  isAdvance?: boolean;
  isDefault?: boolean;
  isTaxable?: boolean;

  taxAccountId?: number;
  taxAccount?: ILedgerAccount;
  taxDescription?: string;

  gstCategory?: string;
  gstType?: 'gstExclusive' | 'gstInclusive';

  amount?: number;
  taxUnit?: 'percentage' | 'number';
  taxValue?: number;
  taxAmount?: number;

  metadata?: IReceiptRecordMetadata;

  sequence?: number;

  // createdAt: Date;
  // updatedAt: Date;
  createdAt: string;
  updatedAt: string;
}

export interface IReceiptPosting {
  id: number;
  accountId: number;
  accountCode: string;
  account: ILedgerAccount;
  amount: number;
  credit: number;
  debit: number;
  description: string;
  profitCenterId: number;
  profitCenter: IProfitCentres;
  sequence: number;
  taxAccountId?: number;
  taxAccount?: ILedgerAccount;
  taxDescription?: string;
  taxUnit?: 'percentage' | 'number';
  taxValue?: number;
  taxAmount?: number;
  isTaxable?: boolean;
}

export interface IReceiptPostingDataSource {
  postings: IReceiptPosting[];
  total: number;
}

export interface IReceiptOffset {
  type: string;
  id: number;
  ref: string;
  amount: number;
  // date: Date;
  date: string;
}
