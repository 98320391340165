import { PhoneNumber } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { CITIZEN_OPTIONS, GENDER_OPTIONS, INDUSTRY_EXAM_OPTIONS, RECRUITMENT_DIVISION_OPTIONS, RECRUITMENT_STATUS_OPTIONS, RECRUITMENT_TYPE_OPTIONS } from '../data';
import { ERecruitmentDivision, ERecruitmentStatus, ERecruitmentType } from '../enums';
import { IPersonalParticular, IRecruitment } from '../interfaces';
import { CommSchemeGenericModel } from './commission-scheme.model';
import { PackageModel } from './package.model';
import { PersonalParticularModel } from './personal-particular.model';
import { RecruitmentApplicationModel } from './recruitment-application.model';
import { UpdatedByModel } from './updated-by.model';

export class RecruitmentModel extends RecruitmentApplicationModel implements IRecruitment {
  //#region recruitment
  @Expose()
  id!: number;

  @Expose()
  applicantType!: ERecruitmentType;

  @Expose()
  applicantName!: string;

  @Expose()
  status!: ERecruitmentStatus;

  @Expose()
  division!: ERecruitmentDivision;

  @Expose()
  @Type(() => Boolean)
  isParent!: boolean;

  @Expose()
  @Type(() => PersonalParticularModel)
  parent!: PersonalParticularModel;

  @Expose()
  parentId!: number;

  @Expose()
  parentName!: string;

  @Expose()
  parentEmail!: string;

  @Expose()
  parentCeaRegNo!: string;

  @Expose()
  @Type(() => Boolean)
  isRecruiter!: boolean;

  @Expose()
  @Type(() => PersonalParticularModel)
  recruiter!: PersonalParticularModel;

  @Expose()
  recruiterId!: number;

  @Expose()
  recruiterName!: string;

  @Expose()
  recruiterEmail!: string;

  @Expose()
  recruiterCeaRegNo!: string;

  @Expose()
  @Type(() => CommSchemeGenericModel)
  designation!: CommSchemeGenericModel;

  @Expose()
  @Type(() => Number)
  designationId!: number;

  @Expose()
  @Type(() => PackageModel)
  package!: PackageModel;

  @Expose()
  @Type(() => Number)
  packageId!: number;

  @Expose()
  @Type(() => PhoneNumber)
  applicantMobile!: PhoneNumber;

  @Expose()
  @Type(() => Number)
  protectionTemplateId!: number;

  @Expose()
  @Type(() => Number)
  authorisationTemplateId!: number;

  @Expose()
  @Type(() => Number)
  agreementTemplateId!: number;

  @Expose()
  joinOption!: string;

  @Expose()
  remarks!: string;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  applicantAt!: string;

  @Expose()
  recruiterAt!: string;

  @Expose()
  submittedAt!: string;

  @Expose()
  reviewedAt!: string;

  @Expose()
  @Type(() => PersonalParticularModel)
  reviewedBy!: IPersonalParticular;

  @Expose()
  registeredAt!: string;

  @Expose()
  @Type(() => PersonalParticularModel)
  registeredBy!: IPersonalParticular;

  @Expose()
  uuid!: string;
  //#endregion

  //#region timestamps
  @Expose()
  createdAt!: string;
  @Expose()
  updatedAt!: string;
  @Expose()
  deletedAt!: string;
  @Expose()
  createdBy!: UpdatedByModel;
  @Expose()
  updatedBy!: UpdatedByModel;
  @Expose()
  deletedBy!: UpdatedByModel;
  //#endregion

  //#region getter
  get typeInfo() {
    return RECRUITMENT_TYPE_OPTIONS[this.applicantType] ?? null;
  }

  get divisionInfo() {
    return RECRUITMENT_DIVISION_OPTIONS[this.division] ?? null;
  }

  get statusInfo() {
    return RECRUITMENT_STATUS_OPTIONS[this.status] ?? null;
  }

  get genderInfo() {
    return GENDER_OPTIONS[this.gender] ?? null;
  }

  get citizenInfo() {
    return CITIZEN_OPTIONS[this.citizen] ?? null;
  }

  get industryExamInfo() {
    return INDUSTRY_EXAM_OPTIONS[this.industryExam] ?? null;
  }

  get recruitmentName() {
    if (this.status === ERecruitmentStatus.draft) {
      return this.applicantName;
    }

    return this.businessName ?? this.applicantName;
  }

  get recruiterInfo() {
    return this.isRecruiter
      ? this.recruiter
      : PersonalParticularModel.fromJson({
        nricName: this.recruiterName,
        ceaRegNo: this.recruiterCeaRegNo,
        email1: this.recruiterEmail,
      });
  }

  get parentInfo() {
    return this.isParent
      ? this.parent
      : PersonalParticularModel.fromJson({
        nricName: this.parentName,
        ceaRegNo: this.parentCeaRegNo,
        email1: this.parentEmail,
      });
  }
  //#endregion
}

export class RecruitmentModelWithHover extends RecruitmentModel {
  @Expose()
  isHover?: boolean;
}
