import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadDirective } from './upload.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { FileDropModule } from '..';
import { RedFileSizeModule } from '../../pipes';
import { UploadComponent } from './upload.component';

@NgModule({
  declarations: [UploadDirective, UploadComponent],
  imports: [CommonModule, FileDropModule, MatIconModule, RedFileSizeModule, MatProgressBarModule, MatButtonModule],
  exports: [UploadDirective, UploadComponent],
})
export class UploadModule {}
