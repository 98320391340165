import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { SUPPLIER_INVOICE_PAID_STATUSES, SUPPLIER_INVOICE_STATUSES, SUPPLIER_INVOICE_TYPES } from '../data/supplier-invoice.data';
import { ESupplierInvoicePaidStatus, ESupplierInvoiceStatus } from '../enums';
import {
  EBalanceDocumentCode,
  EBalanceDocumentPostingType,
  ECustomerOrSupplier,
  EGstCategorySupplier,
  EInvoiceCreateMode,
  ESupplierInvoice,
} from '../enums/tax-invoice-enhanced.enum';
import {
  IStatusDescription,
  ISupplierInvoice,
  ISupplierInvoicePayment,
  ISupplierInvoicePosting,
  ISupplierInvoiceRecord,
  ISupplierInvoiceSummary,
  ITaxInvoice,
} from '../interfaces';
import { ContactAddressModel } from './contact-address.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { ContactPersonModel } from './contact-person.model';
import { CurrencyRateModel } from './currency-rate.model';
import { LedgerAccountModel } from './ledger-account.model';
import { ProfitCentresModel } from './profit-centres.model';
import { TaxInvoiceModel } from './tax-invoice-enhanced.model';
import { UpdatedByModel } from './updated-by.model';
import { GstCategoryLookupModel } from './gst-category.model';
import { Helper } from '@red/utils';

export class SupplierInvoiceCodeModel extends BaseModel {
  @Expose()
  code!: string;
}

export class SupplierInvoiceRecordModel extends BaseModel implements ISupplierInvoiceRecord {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  itemCode?: string;

  @Expose()
  description!: string;

  @Expose()
  uom?: string;

  @Expose()
  @Default('')
  remarks?: string;

  @Expose()
  amount!: number;

  @Expose()
  quantity!: number;

  @Expose()
  unitPrice!: number;

  @Expose()
  balanceDue!: number;

  @Expose()
  paidAmount!: number;

  @Expose()
  discount!: number;

  @Expose()
  total?: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => GstCategoryLookupModel)
  gstCategoryLookup?: GstCategoryLookupModel;

  @Expose()
  @Transform(({ obj }) => obj.gstPercent ?? Number(obj.gstCategoryLookup?.gst_rate))
  @Type(() => Number)
  gstPercent?: number;

  @Expose()
  @Transform(({ obj }) => obj.gstCharged ?? Number(obj.gstCategoryLookup?.gst_charged))
  @Type(() => Number)
  gstCharged?: number;

  @Expose()
  @Transform(({ obj }) => {
    switch (obj.gstCategory) {
      case EGstCategorySupplier.TX7:
      case EGstCategorySupplier.TX8:
        return 'Y';
      case EGstCategorySupplier.EP:
        return 'E';
      case EGstCategorySupplier.ZP:
        return 'N';
      default:
        return obj.gstCategory;
    }
  })
  gstCategoryNewDisplay!: string;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  gstValue?: number;

  // @Expose()
  // gstPercent?: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Transform((object) => {
    if (object.obj?.account) {
      return object.obj?.account?.code
    }
    return object.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter!: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  taxInvoice?: Partial<ITaxInvoice>;

  @Expose()
  taxInvoiceId?: number;

  @Expose()
  supplierInvoiceId?: number;

  @Expose()
  supplierInvoice?: Partial<ISupplierInvoice>;

  @Expose()
  posting?: {
    accountName: string;
    accountId: number;
    accountCode: string;
  }

  // @Expose()
  // get grossAmount(): number {
  //   return (this.unitPrice ?? 1) * (this.quantity ?? 1);
  // }

  @Expose()
  get grossAmount(): number {
    if (this.gstPercent === 0) {
      return (this.total ?? 0) - this.paidAmount
    }
    else if (this.gstPercent && this.gstPercent > 0 && this.gstInclusive) {
      return (this.total ?? 0) - this.paidAmount
    }
    else if (this.gstPercent && this.gstPercent > 0 && !this.gstInclusive) {
      const roundAmount = Helper.round((((this.total ?? 0) - this.paidAmount)) * 100 / (100 + this.gstPercent), 2);
      return roundAmount
    }
    return (this.unitPrice ?? 1) * (this.quantity ?? 1);
  }

  // Base
  @Expose()
  createdAt?: string;

  @Expose()
  updatedAt?: string;

  @Expose()
  deletedAt?: string;

  @Expose()
  createdBy?: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  deletedBy?: string;
}

export class SupplierInvoicePostingModel extends BaseModel implements ISupplierInvoicePosting {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Transform((object) => {
    console.log("🚀 ~ SupplierInvoiceRecordModel ~ @Transform ~ oject:", object)
    if (object.obj?.account) {
      return object.obj?.account?.code
    }
    return object.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  description?: string;

  @Expose()
  debit!: number;

  @Expose()
  credit!: number;

  @Expose()
  amount!: number;

  @Expose()
  currency?: string;

  @Expose()
  taxInvoiceId!: number;

  @Expose()
  taxInvoiceDetailId?: number;

  @Expose()
  taxInvoiceDetail?: TaxInvoiceModel;

  @Expose()
  type?: EBalanceDocumentPostingType;
}

export class SupplierInvoiceModel extends BaseModel implements ISupplierInvoice {
  // General
  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact!: ContactCustomerAndSupplierModel;

  @Expose()
  @Type(() => ContactAddressModel)
  billingAddress!: ContactAddressModel;

  @Expose()
  contactId?: number;

  @Expose()
  contactAddressId!: number;

  @Expose()
  invoiceNumber?: string;

  @Expose()
  invoiceDate!: string;

  @Expose()
  invoiceDueDate?: string;

  @Expose()
  ecbInvoiceDate?: string;

  @Expose()
  @Type(() => ContactPersonModel)
  contactPerson?: ContactPersonModel;

  @Expose()
  contactPersonId?: number;

  @Expose()
  contactPersonCustom?: string;

  @Expose()
  customerName?: string;

  // Summary
  @Expose()
  amount!: number;

  // @Expose()
  // discount!: number;

  @Expose()
  subTotal!: number;

  @Expose()
  gstPercent!: number;

  @Expose()
  gstValue!: number;

  @Expose()
  total!: number;

  @Expose()
  gstEdited!: boolean;

  @Expose()
  gst?: number;

  @Expose()
  inClosedPeriod?: boolean;

  // Other Details
  @Expose()
  currencyId!: number;

  @Expose()
  @Type(() => CurrencyRateModel)
  currency!: CurrencyRateModel;

  @Expose()
  currencyCode?: string;

  @Expose()
  reference?: string;

  @Expose()
  remarks?: string;

  @Expose()
  creditTerm?: number;

  @Expose()
  fileAttachedName?: string;

  @Expose()
  fileAttachedViewName?: string;

  // General
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  status?: ESupplierInvoiceStatus;

  @Expose()
  @Transform(({ obj }) => {
    return SUPPLIER_INVOICE_STATUSES[obj.status as ESupplierInvoiceStatus];
  })
  displayStatus!: IStatusDescription;

  @Expose()
  createdMode?: EInvoiceCreateMode;

  @Expose()
  invoiceType?: ECustomerOrSupplier.SUPPLIER;

  @Expose()
  balanceDocumentCodeType?: EBalanceDocumentCode;

  @Expose()
  @Transform(({ value }) => {
    if (!value) return undefined;
    if (typeof value === 'string' && !isNaN(+value)) return undefined;

    return SUPPLIER_INVOICE_PAID_STATUSES[value as ESupplierInvoicePaidStatus]?.name;
  })
  paidStatus?: string;

  @Expose()
  balanceDue?: number;

  @Expose()
  @Default([])
  paymentGenerators!: ISupplierInvoicePayment[];

  @Expose()
  @Default([])
  paymentDirects!: ISupplierInvoicePayment[];

  // Base
  @Expose()
  createdAt?: string;

  @Expose()
  updatedAt?: string;

  @Expose()
  deletedAt?: string;

  @Expose()
  createdBy?: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  deletedBy?: string;

  // For list / detail display
  @Expose()
  projectId?: number;

  @Expose()
  paidAmount?: number;

  @Expose()
  parentInvoice?: TaxInvoiceModel;

  @Expose()
  parentInvoiceId?: number;

  @Expose()
  batchComment?: string;

  @Expose()
  batchName?: string;

  @Expose()
  batchCode?: string;

  @Expose()
  attention?: string;

  @Expose()
  supplierInvoiceType?: ESupplierInvoice;

  @Expose()
  @Transform(({ obj }) => {
    return SUPPLIER_INVOICE_TYPES[obj.supplierInvoiceType as ESupplierInvoice]?.name;
  })
  supplierInvoiceTypeDisplay?: string;

  @Expose()
  adjustment?: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return obj.status !== ESupplierInvoiceStatus.Pending && obj.isWithHold === false;
  })
  confirmed?: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = [ESupplierInvoiceStatus.Pending, ESupplierInvoiceStatus.PaymentInProgress, ESupplierInvoiceStatus.Withhold].includes(obj.status);
    const isPaidStatusInvalid = [
      ESupplierInvoicePaidStatus.PaymentInProgress,
      ESupplierInvoicePaidStatus.PENDING,
      ESupplierInvoicePaidStatus.FULLY_PAID,
      ESupplierInvoicePaidStatus.CREDITED,
    ].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.isWithHold && !obj.inClosedPeriod;
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = [ESupplierInvoiceStatus.Pending, ESupplierInvoiceStatus.PaymentInProgress, ESupplierInvoiceStatus.Withhold].includes(obj.status);
    const isPaidStatusInvalid = [
      ESupplierInvoicePaidStatus.PaymentInProgress,
      ESupplierInvoicePaidStatus.PENDING,
      ESupplierInvoicePaidStatus.FULLY_PAID,
      ESupplierInvoicePaidStatus.CREDITED,
    ].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.isWithHold && !obj.inClosedPeriod;
  })
  canDelete!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    // return obj.status === ESupplierInvoiceStatus.Confirmed;

    const isStatusInvalid = [ESupplierInvoiceStatus.PaymentInProgress, ESupplierInvoiceStatus.Withhold].includes(obj.status);
    const isPaidStatusInvalid = [
      ESupplierInvoicePaidStatus.PaymentInProgress,
      ESupplierInvoicePaidStatus.PENDING,
      ESupplierInvoicePaidStatus.FULLY_PAID,
      ESupplierInvoicePaidStatus.CREDITED,
    ].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.isWithHold;
  })
  isLock!: boolean;
}

export class SupplierInvoiceSummaryModel extends BaseModel implements ISupplierInvoiceSummary {
  // @Expose()
  // amount!: number;

  // @Expose()
  // discount!: number;

  @Expose()
  subTotal!: number;

  @Expose()
  gstPercent!: number;

  @Expose()
  gstValue!: number;

  @Expose()
  total!: number;

  @Expose()
  gstEdited!: boolean;
}

export class SupplierInvoiceForCloneModel extends SupplierInvoiceModel {
  @Expose()
  taxInvoice!: Partial<ITaxInvoice>;
}
