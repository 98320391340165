import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterContentInit, ContentChildren, Directive, ElementRef, HostBinding, Inject, Optional, QueryList } from '@angular/core';
import { MatLine, setLines } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-option[multi-line-option], mat-option[multiLineOption]',
})
export class MultiLineOptionDirective implements AfterContentInit {
  @ContentChildren(MatLine, { descendants: true, read: MatLine })
  public lines!: QueryList<MatLine>;

  @HostBinding('class') class = 'multi-line-option';
  constructor(protected element: ElementRef<HTMLElement>, @Inject(MatSelect) public matSelect: MatSelect, @Optional() private viewPort: CdkVirtualScrollViewport) {
    // console.log('has viewport -->', this.matSelect, this.viewPort);
    if (this.viewPort?.elementRef) {
      this.viewPort.elementRef.nativeElement.classList.add('disable-horizontal-scroll');
      // const wraper = this.viewPort.elementRef.nativeElement.querySelector('.cdk-virtual-scroll-content-wrapper');
      // if (wraper) {
      //   (wraper as HTMLElement).style.maxWidth = '100%';
      //   console.log('has viewport -->', wraper);
      //   wraper.classList.add('tessst');
      // }
      // this.matSelect.panelClass = 'disable-horizontal-scroll';
    }
  }

  ngAfterContentInit(): void {
    setLines(this.lines, this.element);
  }
}
