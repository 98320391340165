import { IGrossCommissionEarned } from './gross-commission-earned.interface';

import { EProjectPropertyType, EProjectTransactionPropertyType } from '../enums';
import { ICommissionMatrix } from './commission-matrix.interface';
import { ICurrency } from './currency.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IProjectHICommMatrix } from './project-hi-commission-matrix.interface';
import { IProjectTransactionBuyerInfo } from './project-transaction-buyer-info.interface';
import { IProjectTransactionRelatedAgent } from './project-transaction-related-agent.interface';
import { IProjectTransactionRelatedCobroke } from './project-transaction-related-cobroke.interface';
import { IProject } from './project.interface';
import { ITransactedType } from './transacted-type.interface';
import { IUnit } from './unit.interface';

export interface IProjectTransaction {
  agentGrossComm: IGrossCommissionEarned;
  optionDate: string;
  buyer: IProjectTransactionBuyerInfo;
  closingAgentGrossComm: IGrossCommissionEarned;
  currencyId?: number;
  originalCurrencyId?: number;
  originalCurrency?: ICurrency;
  currencyCode?: string;
  originalCurrencyCode?: string;
  submissionDate?: string;
  code: string;
  companyGrossComm: IGrossCommissionEarned;
  createdAt: string;
  devGrossComm?: IGrossCommissionEarned;
  generatedBD?: number;
  generatedBonus?: number;
  generatedOverriding?: number;
  generatedPD?: number;
  generatedPool?: number;
  generatedReferral?: number;
  grossComm: IGrossCommissionEarned;
  hiGrossComm?: IGrossCommissionEarned;
  profomaGrossComm?: IGrossCommissionEarned;
  id: number;
  managementCommId?: number | null;
  optionNo: string;
  paymentDates: string[];
  originalOptionDate?: string;
  project: IProject;
  projectCommInternational: IProjectHICommMatrix;
  projectCommInternationalId: number;
  projectCommission: ICommissionMatrix;
  projectCommissionId: number;
  projectId: number;
  // propertyType: EProjectTransactionPropertyType;
  propertyType: EProjectPropertyType;
  landAreaSqft: number;
  landAreaSqm: number;
  relatedAgent: {
    main: IProjectTransactionRelatedAgent;
    leaders: IProjectTransactionRelatedAgent[];
    internals: IProjectTransactionRelatedAgent[];
    externals: IProjectTransactionRelatedCobroke[];
  };
  remarks: string;
  status: string;
  statusObj: {
    className: string,
    value: string,
    displayName: string
  };
  summary: {
    closingAgentAmount: number;
    icBonusAmount: number;
    icOverridingAmount: number;
    otherFundAmount: number;
    teamLeaderAmount: number;
    bdAmount: number;
    headHiAmount: number;
    hiCompanyAmount: number;
  };
  submittedBy: {
    id: number;
    name: string;
  };
  transactedPrice: number;
  originalTransactedPrice?: number;
  transactedTypes: ITransactedType[];
  hiTransactedTypes?: ITransactedType[];
  unit?: IUnit;
  unitId?: number;
  updatedAt: string;
  isReissue?: boolean;
  reissueDate?: string;
  grouping?: string;
  submittedAt: string;
  salePersonId?: number;
  batchDetails?: {
    batchId: number;
    status: string;
  }[];
  internationalRef?: string;
  metadata?: {
    admin: string;
    isFirstTimeSubmitted: boolean;
  };
  payments?: {
    totalAmount: number;
    itemCode: string
    code: string;
    paymentDate: string;
  }[];
  totalPaymentAmount?: number;
}
