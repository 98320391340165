import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform } from 'class-transformer';
import { LedgerAccountModel } from '../models';

export class BankAccountCreateDto extends BaseModel {
  @Expose()
  accountId!: number;

  @Expose()
  account!: LedgerAccountModel;

  @Expose()
  @Transform((oject) => {
    if (oject.obj?.account) {
      return oject.obj?.account?.code
    }
    return oject.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  bankId!: number;

  @Expose()
  name!: string;

  @Expose()
  bankAccountNumber!: string;

  @Expose()
  currency!: string;

  @Expose()
  @Default(false)
  requireBankReturnCollectionFile!: boolean;

  @Expose()
  @Default(false)
  requireBankReturnPaymentFile!: boolean;

  @Expose()
  directDebitCollectionFileId!: number;

  @Expose()
  directDebitPaymentFileId!: number;

  @Expose()
  remarks!: string;
}

export class BankAccountUpdateDto extends BaseModel {
  @Expose()
  accountId!: number;

  @Expose()
  account!: LedgerAccountModel;

  @Expose()
  @Transform((oject) => {
    if (oject.obj?.account) {
      return oject.obj?.account?.code
    }
    return oject.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  bankId!: number;

  @Expose()
  name!: string;

  @Expose()
  bankAccountNumber!: string;

  @Expose()
  currency!: string;

  @Expose()
  @Default(false)
  requireBankReturnCollectionFile!: boolean;

  @Expose()
  @Default(false)
  requireBankReturnPaymentFile!: boolean;

  @Expose()
  directDebitCollectionFileId!: number;

  @Expose()
  directDebitPaymentFileId!: number;

  @Expose()
  remarks!: string;
}
