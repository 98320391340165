import { Directive, ElementRef, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[uppercase], textarea[uppercase]',
})
export class UppercaseDirective {
  @Input() enableUppercase = true;

  constructor(private el: ElementRef, @Optional() private ngControl?: NgControl) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    if (this.enableUppercase) {
      // const initalValue = this.el.nativeElement.value;
      // this.el.nativeElement.value = initalValue.toUpperCase();
      // this.ngControl?.control?.setValue(this.el.nativeElement.value);
      // event.stopPropagation();

      const initialValue = this.el.nativeElement.value;
      const cursorPosition = this.el.nativeElement.selectionStart; // save cursor position
      this.el.nativeElement.value = initialValue.toUpperCase();
      this.ngControl?.control?.setValue(this.el.nativeElement.value);
      this.el.nativeElement.setSelectionRange(cursorPosition, cursorPosition); // reset cursor position
      event.stopPropagation();
    }


  }
}
