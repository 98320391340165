import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLineModule } from '@angular/material/core';
import { MultiLineOptionDirective } from './multi-line-option.directive';



@NgModule({
  declarations: [
    MultiLineOptionDirective
  ],
  imports: [
    CommonModule,
    MatLineModule
  ],
  exports: [
    MatLineModule,
    MultiLineOptionDirective
  ]
})
export class MultiLineOptionModule { }
