export const PAYMENT_GENERATOR_PAYMENT_MODES = [
  {
    id: 'Cheque',
    name: 'By Cheque',
  },
  {
    id: 'Giro',
    name: 'Giro',
  },
] as const;

export const PAYMENT_GENERATOR_STATUS = [
  {
    id: 'Selected',
    name: 'Selected',
  },
  {
    id: 'Computed',
    name: 'Computed',
  },
  {
    id: 'Assigned',
    name: 'Assigned',
  },
  {
    id: 'EmailSent',
    name: 'Email Sent',
  },
  {
    id: 'Posted',
    name: 'Posted',
  },
];
