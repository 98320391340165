import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  Component, EventEmitter, forwardRef, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'red-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements ControlValueAccessor,OnInit, OnDestroy {


  @Input() size: 'small' | 'medium' | 'large' = 'large';

  @Input() placeholder = 'Search...';

  /** Event emitted when the search's value changes. */
  @Output() readonly search: EventEmitter<string> = new EventEmitter<string>();
  @Output() readonly afterClear: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class.small')
  get small() {
    return this.size === 'small';
  }

  @HostBinding('class.medium')
  get medium() {
    return this.size === 'medium';
  }

  @HostBinding('class.large')
  get large() {
    return this.size === 'large';
  }
  data = '';
  isFocus!: boolean;

  inputControl = new UntypedFormControl();
  unsubscribeAll = new Subject<void>();

  /** Value of the select control. */
  // @Input()
  // get value(): string {
  //     return this.data;
  // }
  // set value(newValue: string) {
  //     if (newValue !== this.data) {
  //         this.writeValue(newValue);
  //         this.data = newValue;
  //     }
  // }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  propagateChange = (_: any) => { };
  writeValue(value: any): void {
    // if (value !== undefined) {
      this.inputControl.setValue(value);
    // }
  }
  // registers 'fn' that will be fired wheb changes are made
  // this is how we emit the changes back to the form
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  // not used, used for touch input
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched() { }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }
  private emitChangeEvent(): void {
    this.search.emit(this.data);
  }
  // onChange(event: any): void {
  //   console.log('event', event)
  //   // get value from input
  //   this.data = event.target.value;
  //   this.propagateChange(this.data);
  //   this.emitChangeEvent();
  // }
  ngOnInit(): void {
    this.inputControl.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this.unsubscribeAll)
    ).subscribe(val =>{
      this.data = val;
      this.propagateChange(this.data);
      this.emitChangeEvent();
    })
  }
  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.afterClear.complete();
  }
  forcusInput() {
    this.isFocus = true;
  }
  blur() {
    this.isFocus = false;
  }
  clear() {
    this.writeValue('');
    this.afterClear.emit();
  }
}
