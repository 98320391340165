import { NgModule, inject } from '@angular/core';
import { ExtraOptions, RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { SwitchCompanyComponent, SwitchCompanyResolver, SwitchCompanyStateService } from '@cms/backgrounds/switch-company';
import { AuthGuard, NoAuthGuard, TokenAuthGuard, TOKEN_AUTH_NAME } from '@shared/features/auth';
import { AppConstant } from './app.constant';
import { InitialDataResolver } from './app.resolvers';
// import { AuthGuard, NoAuthGuard } from './core/auth/guards';
import { LayoutComponent } from './layout/layout.component';
import { SwitchCompanyGuard } from './core/auth/switch-company/switch-company.guard';
import { SwitchCompanyWraperComponent } from './core/auth/switch-company/switch-company-wraper/switch-company-wraper.component';
import { PermissionGuard } from '@shared/permission';
import { EList } from '@shared/data-access/enums';

const routerConfig: ExtraOptions = {
  // preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
};
export const companyChildRouteMatcher: (url: UrlSegment[]) => UrlMatchResult | null = (url: UrlSegment[]) => {
  // Prepare consumed url and positional parameters
  const consumed = url;
  const posParams = {};
  // Company
  if (url[0].path === 'company') {
    // Do not match
    return null;
  }
  // Path under company scope
  else if (routesUnderCompany.every(route => route.path !== url[0].path)) {
    // Do not match
    return null;
  }

  return {
    consumed,
    posParams,
  };
};
export const routesUnderCompany: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/finance/finance-dashboard/finance-dashboard.module').then(m => m.FinanceDashboardModule),
  },
  {
    path: AppConstant.ROUTE.GENERAL_LEDGER,
    loadChildren: () => import('./modules/finance/general-ledger/general-ledger.module').then(m => m.GeneralLedgerModule),
  },
  {
    path: AppConstant.ROUTE.ACCOUNT_RECEIVABLES,
    loadChildren: () => import('./modules/finance/account-receivables/account-receivables.module').then(m => m.AccountReceivablesModule),
    resolve: {
      company: SwitchCompanyResolver,
    },
  },
  {
    path: AppConstant.ROUTE.ACCOUNT_PAYABLES,
    loadChildren: () => import('./modules/finance/account-payables/account-payables.module').then(m => m.AccountPayablesModule),
  },
  {
    path: AppConstant.ROUTE.BANK_ACCOUNT,
    loadChildren: () => import('./modules/finance/banking/bank-account/bank-account.module').then(m => m.BankAccountModule),
  },
  {
    path: AppConstant.ROUTE.PAYMENT,
    loadChildren: () => import('./modules/finance/banking/payment/payment.module').then(m => m.PaymentModule),
  },
  {
    path: AppConstant.ROUTE.CHEQUE,
    loadChildren: () => import('./modules/finance/banking/cheque/cheque.module').then(m => m.ChequeModule),
  },
  {
    path: AppConstant.ROUTE.RECEIPT,
    loadChildren: () => import('./modules/finance/banking/receipt/receipt.module').then(m => m.ReceiptModule),
    resolve: {
      company: SwitchCompanyResolver,
    },
  },
  {
    path: AppConstant.ROUTE.DIRECT_DEBIT_PAYMENT,
    loadChildren: () => import('./modules/finance/banking/direct-debit-payment/direct-debit-payment.module').then(m => m.DirectDebitPaymentModule),
  },
  {
    path: AppConstant.ROUTE.PAYMENT_GENERATOR,
    loadChildren: () => import('./modules/finance/banking/payment-generator/payment-generator.module').then(m => m.PaymentGeneratorModule),
  },
  {
    path: AppConstant.ROUTE.FIXED_ASSET,
    loadChildren: () => import('./modules/finance/fixed-asset/fixed-asset.module').then(m => m.FixedAssetModule),
  },
  {
    path: AppConstant.ROUTE.SEARCH_ENGINE_FINANCE,
    loadChildren: () => import('./modules/finance/search-engine/search-engine.module').then(m => m.SearchEngineModule),
  },
  {
    path: AppConstant.ROUTE.REVERSAL_MANAGER,
    loadChildren: () => import('./modules/finance/banking/payment-reversal/payment-reversal.module').then(m => m.PaymentReversalModule),
  },
  {
    path: AppConstant.ROUTE.INVOICE_WRITEOFF,
    loadChildren: () => import('./modules/finance/invoice-writeoff/invoice-writeoff.module').then(m => m.InvoiceWriteoffModule),
  },
  {
    path: AppConstant.ROUTE.FINANCE_REPORT,
    loadChildren: () => import('./modules/finance/finance-reports/finance-reports.module').then(m => m.FinanceReportsModule),
  },
  {
    path: AppConstant.ROUTE.CUSTOMER_REPORT,
    loadChildren: () => import('./modules/finance/customer-report/customer-report.module').then(m => m.CustomerReportModule),
  },
  {
    path: AppConstant.ROUTE.SUPPLIER_REPORT,
    loadChildren: () => import('./modules/finance/supplier-report/supplier-report.module').then(m => m.SupplierReportModule),
  },
  {
    path: AppConstant.ROUTE.BANK_REPORT,
    loadChildren: () => import('./modules/finance/bank-report/bank-report.module').then(m => m.BankReportModule),
  },
  {
    path: AppConstant.ROUTE.DATA_IMPORT,
    loadChildren: () => import('./modules/configuration/data-import/data-import.module').then(m => m.DataImportModule),
  },
];
const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  {
    path: '',
    component: LayoutComponent,
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: AppConstant.ROUTE.LOGIN,
        loadChildren: () => import('./modules/auth/sign-in/sign-in.module').then(m => m.SignInModule),
      },
      {
        path: AppConstant.ROUTE.FORGOT_ACCOUNT,
        loadChildren: () => import('./modules/auth/forgot-account/forgot-account.module').then(m => m.ForgotAccountModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: AppConstant.ROUTE.LOGOUT,
        loadChildren: () => import('./modules/auth/sign-out/sign-out.module').then(m => m.SignOutModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    data: {
      layout: 'classic',
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: AppConstant.ROUTE.DESIGNATION,
        loadChildren: () => import('./modules/human-resource/promotion/pages/designation/designation.module').then(m => m.DesignationModule),
      },
      {
        path: AppConstant.ROUTE.TEAM_ORG_CHART,
        loadChildren: () => import('./modules/human-resource/team-org-chart/team-org-chart.module').then(m => m.TeamOrgChartModule),
      },
      {
        path: AppConstant.ROUTE.GCE_OPENING_AMOUNT,
        loadChildren: () => import('./modules/human-resource/gce-amount/gce-amount.module').then(m => m.GceAmountModule),
      },
      {
        path: AppConstant.ROUTE.IR8A_OPENING_AMOUNT,
        loadChildren: () => import('./modules/human-resource/ir8a-amount/ir8a-amount.module').then(m => m.Ir8aAmountModule),
      },
      {
        path: AppConstant.ROUTE.HR_REPORT,
        loadChildren: () => import('./modules/human-resource/agent-report/agent-report.module').then(m => m.AgentReportModule),
      },
      {
        path: AppConstant.ROUTE.AWARDS_REPORT,
        loadChildren: () => import('./modules/human-resource/awards-report/awards-report.module').then(m => m.AwardsReportModule),
      },
      {
        path: AppConstant.ROUTE.PROMOTION_APPROVAL,
        loadChildren: () => import('./modules/human-resource/promotion-approval/promotion-approval.module').then(m => m.PromotionApprovalModule),
      },
      {
        path: AppConstant.ROUTE.RECRUITMENT,
        loadChildren: () => import('./modules/human-resource/recruitment/recruitment.module').then(m => m.RecruitmentModule),
      },
      {
        path: AppConstant.ROUTE.MY_RECRUITMENT,
        loadChildren: () => import('./modules/human-resource/my-recruitment/my-recruitment.module').then(m => m.MyRecruitmentModule),
      },
      {
        path: AppConstant.ROUTE.RECRUITMENT_VERIFICATION,
        canActivate: [PermissionGuard],
        data: {
          listType: EList.verification,
          permission: {
            slug: 'hr:recruitment_verification:read',
          },
        },
        loadChildren: () => import('./modules/human-resource/recruitment/recruitment-list/recruitment-list.module').then(m => m.RecruitmentListModule),
      },
      {
        path: AppConstant.ROUTE.PERSONAL_VERIFICATION,
        loadChildren: () => import('./modules/human-resource/personal-verification/personal-verification.module').then(m => m.PersonalVerificationModule),
      },
      {
        path: AppConstant.ROUTE.COMMISSION_SCHEME,
        loadChildren: () => import('./modules/human-resource/promotion/commission-scheme/commission-scheme.module').then(m => m.CommissionSchemeModule),
      },
      {
        path: AppConstant.ROUTE.PROFIT_SHARING,
        loadChildren: () => import('./modules/human-resource/promotion/profit-sharing/profit-sharing.module').then(m => m.ProfitSharingModule),
      },
      {
        path: AppConstant.ROUTE.ECDD,
        loadChildren: () => import('./modules/human-resource/ecdd/ecdd.module').then(m => m.EcddModule),
      },
      {
        path: AppConstant.ROUTE.PROJECT_ADMIN,
        loadChildren: () => import('./modules/transaction/project-admin/project-admin.module').then(m => m.ProjectAdminModule),
      },
      {
        path: AppConstant.ROUTE.RESALE_ADMIN,
        loadChildren: () => import('./modules/transaction/resale-admin/resale-admin.module').then(m => m.ResaleAdminModule),
      },
      {
        path: AppConstant.ROUTE.PROJECT_REPORT,
        loadChildren: () => import('./modules/transaction/project-report/project-report.module').then(m => m.ProjectReportModule),
      },
      {
        path: AppConstant.ROUTE.RESALE_REPORT,
        loadChildren: () => import('./modules/transaction/resale-report/resale-report.module').then(m => m.ResaleReportModule),
      },
      {
        path: AppConstant.ROUTE.REPORTS,
        loadChildren: () => import('./modules/transaction/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: AppConstant.ROUTE.DATA_SETUP,
        loadChildren: () => import('./modules/configuration/data-setup/data-setup.module').then(m => m.DataSetupModule),
      },
      {
        path: AppConstant.ROUTE.ACCESS_CONTROL,
        loadChildren: () => import('./modules/configuration/access-control/access-control.module').then(m => m.AccessControlModule),
      },
      {
        path: AppConstant.ROUTE.RESALE_ADJUSTMENT,
        loadChildren: () => import('./modules/finance/resale-adjustment/resale-adjustment-wrapper.module').then(m => m.ResaleAdjustmentWrapperModule),
      },
      {
        path: AppConstant.ROUTE.SEARCH_ENGINE,
        loadChildren: () => import('./modules/transaction/search-engine/search-engine.module').then(m => m.SearchEngineModule),
      },
      {
        path: AppConstant.ROUTE.MESSAGE_OUTBOX,
        loadChildren: () => import('./modules/configuration/message-outbox/message-outbox.module').then(m => m.MessageOutboxModule),
      },
      {
        path: '',
        component: SwitchCompanyComponent,
        canActivate: [SwitchCompanyGuard],
        children: [
          {
            path: 'company',
            children: [
              {
                path: ':companyId',
                canActivate: [SwitchCompanyGuard],
                children: routesUnderCompany,
              },
            ],
          },
          {
            matcher: companyChildRouteMatcher,
            component: SwitchCompanyWraperComponent,
          },
        ],
      },
      {
        path: '',
        loadChildren: () => import('./modules/human-resource/agent-manager/agent-manager.module').then(m => m.AgentManagerModule),
      },
      // ]
      //  }
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: AppConstant.ROUTE.RESET_PASSWORD,
        providers: [
          {
            provide: TOKEN_AUTH_NAME,
            useValue: 'resetToken',
          },
          TokenAuthGuard,
        ],
        canActivate: [TokenAuthGuard],
        loadChildren: () => import('./modules/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
      },
      // Error
      {
        path: 'error',
        children: [
          {
            path: '403',
            loadChildren: () => import('@shared/components/error-page').then(m => m.Error403Module),
          },
          {
            path: '404',
            loadChildren: () => import('@shared/components/error-page').then(m => m.Error404Module),
          },
          {
            path: '500',
            loadChildren: () => import('@shared/components/error-page').then(m => m.Error500Module),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
