import { YesNoBlankRecord, YesNoRecord } from '../types';
import { EYesNo, EYesNoBlank } from '../enums';

export const YES_NO_OPTIONS: YesNoRecord = {
  [EYesNo.Yes]: {
    id: EYesNo.Yes,
    value: true,
    viewValue: 'Yes',
    viewClass: 'status-box-success',
  },
  [EYesNo.No]: {
    id: EYesNo.No,
    value: false,
    viewValue: 'No',
    viewClass: 'status-box-default',
  },
};

export const YES_NO_BLANK_OPTIONS: YesNoBlankRecord = {
  [EYesNoBlank.Blank]: {
    id: EYesNoBlank.Blank,
    value: null,
    viewValue: '',
    viewClass: '',
  },
  [EYesNoBlank.Yes]: {
    id: EYesNoBlank.Yes,
    value: 1,
    viewValue: 'Yes',
    viewClass: 'status-box-success',
  },
  [EYesNoBlank.No]: {
    id: EYesNoBlank.No,
    value: 0,
    viewValue: 'No',
    viewClass: 'status-box-default',
  }
};

