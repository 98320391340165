import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IBankAccount } from '../interfaces';
import { AttachmentModel } from './attachment.model';
import { BankModel } from './bank.model';
import { LedgerAccountModel } from './ledger-account.model';
import { UpdatedByModel } from './updated-by.model';

export class BankAccountModel extends BaseModel implements IBankAccount {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  accountId!: number;

  @Expose()
  @Transform((oject) => {
    if (oject.obj?.account) {
      return oject.obj?.account?.code
    }
    return oject.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  bankId!: number;

  @Expose()
  @Type(() => BankModel)
  bank!: BankModel;

  @Expose()
  bankAccountNumber!: string;

  @Expose()
  currency!: string;

  @Expose()
  @Default(false)
  requireBankReturnCollectionFile!: boolean;

  @Expose()
  @Default(false)
  requireBankReturnPaymentFile!: boolean;

  @Expose()
  directDebitCollectionFileId!: number;

  @Expose()
  @Type(() => AttachmentModel)
  directDebitCollectionFile!: AttachmentModel;

  @Expose()
  directDebitPaymentFileId!: number;

  @Expose()
  @Type(() => AttachmentModel)
  directDebitPaymentFile!: AttachmentModel;

  @Expose()
  remarks!: string;

  @Expose()
  // @Type(() => Date)
  // createdAt!: Date;
  createdAt!: string;

  @Expose()
  // @Type(() => Date)
  // updatedAt!: Date;
  updatedAt!: string;

  @Expose()
  updatedBy?: UpdatedByModel;
}
