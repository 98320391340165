export interface IPermission {
  id: number;
  module: string;
  feature: string;
  scope: string;
  slug: string;
  description: string;
  sequence: number
}

export interface IPermissionConfig {
  prefix?: string[];
  redirectCommand?: string[];
}
