import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'red-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent implements OnInit {
  @Input() id = this.generateId();
  @Input() itemsPerPage!: any;
  @Input() maxSize = 7;
  @Input() pageSizeOptions: number[] = [10, 20, 50];

  get directionLinks(): boolean {
    return this._directionLinks;
  }
  set directionLinks(value: boolean) {
    this._directionLinks = coerceBooleanProperty(value);
  }
  @Input()
  get autoHide(): boolean {
    return this._autoHide;
  }
  set autoHide(value: boolean) {
    this._autoHide = coerceBooleanProperty(value);
  }
  @Input()
  get responsive(): boolean {
    return this._responsive;
  }
  set responsive(value: boolean) {
    this._responsive = coerceBooleanProperty(value);
  }

  @Input()
  get hidePageSize(): boolean {
    return this._hidePageSize;
  }
  set hidePageSize(value: boolean | null | undefined) {
    this._hidePageSize = coerceBooleanProperty(value);
  }
  @Input() previousLabel = 'Previous';
  @Input() nextLabel = 'Next';
  @Input() screenReaderPaginationLabel = 'Pagination';
  @Input() screenReaderPageLabel = 'page';
  @Input() screenReaderCurrentLabel = `You're on page`;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageBoundsCorrection: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemsPerPageChange: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
    if (typeof this.itemsPerPage === 'string') {
      this.itemsPerPage = parseInt(this.itemsPerPage)
    }
  }

  private _directionLinks = true;
  private _autoHide = false;
  private _responsive = false;
  private _hidePageSize = false;

  trackByIndex(index: number) {
    return index;
  }
  private generateId(): string {
    return `${new Date().getTime()}${Math.floor(Math.random() * Math.floor(1000))}`;
  }
}
