<div class="flex flex-col flex-auto min-w-0">
  <div class="flex flex-auto flex-col overflow-hidden">
    <div class="flex flex-col">
      <div class="flex items-start text-theme-gray">
        <a mat-button (click)="back()"><mat-icon>keyboard_backspace</mat-icon> Back</a>
      </div>
      <div class="flex flex-row justify-between items-center px-6 py-4">
        <div class="flex flex-row gap-2">
          <div class="text-h5 text-bold">{{ cardName }}</div>

          <div class="status-box-warning flex items-center" *ngIf="loadingDownload && (loadingDownload['xlsx'] || loadingDownload['pdf'])">
            <mat-icon class="mr-1">info</mat-icon>
            <span> The report is downloading. Please do not refresh the page until the download is complete.</span>
          </div>
        </div>

        <div class="flex flex-row justify-end gap-5">
          <button *ngIf="isFilter" type="button" [matBadge]="countFilter" mat-raised-button color="primary" matBadgeColor="accent" (click)="drawer.toggle()">
            Filters
          </button>
          <div *ngFor="let file of fileSupported">
            <button class="relative" mat-icon-button [disabled]="loadingDownload[file.type]" (click)="download(file)">
              <div *ngIf="loadingDownload[file.type]" class="absolute">
                <div>
                  <mat-spinner [diameter]="25"> </mat-spinner>
                </div>
              </div>
              <img
                class="icon-size-8"
                [ngClass]="loadingDownload[file.type] ? 'opacity-30' : ''"
                [src]="'assets/images/icons/export-' + file.type + '.svg'"
                alt="icon-download"
              />
            </button>
          </div>
          <!-- <button mat-icon-button matTooltip="Export Excel" (click)="download()">
            <mat-icon class="text-theme-gray">file_download</mat-icon>
          </button> -->
        </div>
      </div>
      <!-- <div class="mx-4">
        <red-exceljs-parameter [card]="card" [parameters]="parameters"></red-exceljs-parameter>
      </div> -->
    </div>
    <div class="relative w-full flex flex-auto flex-col">
      <div class="absolute mx-4 border border-b-0 border-theme-gray-border border-solid inset-0 flex flex-col min-w-0 overflow-hidden">
        <mat-drawer-container class="w-full h-full">
          <mat-drawer #drawer class="example-sidenav" mode="side">
            <div class="w-full h-full flex flex-col overflow-hidden">
              <div class="flex flex-auto flex-col p-4 overflow-auto">
                <red-exceljs-parameter [card]="card" [parameters]="parameters"></red-exceljs-parameter>
              </div>
              <div class="flex flex-row px-4 py-2">
                <button type="button" mat-button (click)="drawer.close()">Close</button>
                <button type="button" [routerLink]="[]" mat-button color="primary">Reset</button>
              </div>
            </div>
          </mat-drawer>

          <div class="flex flex-col flex-auto w-full h-full overflow-hidden">
            <red-exceljs-workbook [workbook]="workbook" (worksheetSelectedChange)="subscribleToTabScroll($event)"></red-exceljs-workbook>
            <div [class.hidden]="!card?.configs?.paging">
              <mat-paginator [length]="total" [pageSize]="limit" [pageIndex]="page - 1"></mat-paginator>
            </div>

            <!-- <red-exceljs-table *ngFor="let worksheet of workbook?.worksheets" [worksheet]="worksheet"></red-exceljs-table> -->
          </div>
          <ng-template [redLoadingBox]="loading"></ng-template>
        </mat-drawer-container>
      </div>
    </div>
  </div>
</div>
