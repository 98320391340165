import { BaseModel, FormattedDateString } from '@red/data-access';
import { Expose } from 'class-transformer';

export class CurrencyRateCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  value!: number;

  @Expose()
  exchange!: number;

  @Expose()
  @FormattedDateString()
  effectiveDate!: string;

  @Expose()
  isActive!: boolean;
}

export class CurrencyRateUpdateDto extends BaseModel {
  @Expose()
  name!: string;
}
