import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { AccountSettingType } from '../enums';
import { IAccountSetup, IAccountSetupDisplay } from '../interfaces';

export class AccountSetupModel extends BaseModel implements IAccountSetup {
  @Expose()
  id!: number;

  @Expose()
  usage!: string;

  @Expose()
  type!: AccountSettingType;

  @Expose()
  accountId: number | null | undefined;

  @Expose()
  accountCode: string | null | undefined;

  @Expose()
  account: IAccountSetupDisplay | null | undefined;

  @Expose()
  currency: string | null | undefined;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  deletedAt!: string | null | undefined;

  @Expose()
  createdBy!: string | null | undefined;

  @Expose()
  updatedBy!: string | null | undefined;

  @Expose()
  deletedBy!: string | null | undefined;
}
