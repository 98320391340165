import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { IAddress, IAddressProperty } from '../interfaces';
import { CountryModel } from './country.model';
import { EResaleNoOfRoom, EResaleNoOfRoomHdb } from '../enums';
import { transform } from 'lodash';

export class AddressPropertyModel extends BaseModel implements IAddressProperty {
  @Expose()
  id!: number;

  @Expose()
  address!: string;

  // @Expose()
  // @Transform(({ obj }) => {
  //   return [obj.addressLine1, obj.addressLine2, obj.addressLine3].join(', ')
  // })
  // addressDisplay!: string;

  @Expose()
  @Transform(({ obj }) => {
    if (!obj.addressLine1 && !obj.addressLine2 && !obj.addressLine3) {
      const addressLine1 = `${obj.block ?? ''} ${obj.street ?? ''}`.trim();
      const addressLine2 = `${obj.floor && obj.unit ? '#' : ''}${obj.floor ? obj.floor : ''}${obj.floor && obj.unit ? '-' : ''}${obj.unit ? obj.unit : ''} ${obj.building ?? ''}`.trim();
      const addressLine3 = `${obj.country ?? ''} ${obj.postal ?? ''}`.trim();
      return [addressLine1, addressLine2, addressLine3].filter(str => !!str).join(', ')
    }
    return [obj.addressLine1, obj.addressLine2, obj.addressLine3].filter(str => !!str).join(', ')
  })
  addressDisplay!: string;

  @Expose()
  addressLine1!: string;

  @Expose()
  addressLine2!: string;

  @Expose()
  addressLine3!: string;

  @Expose()
  addressTo!: string;

  @Expose()
  addressType!: string;

  @Expose()
  countryId!: number;

  @Expose()
  country!: string;

  @Expose()
  postcode!: number;

  @Expose()
  isDefault!: boolean;

  @Expose()
  postal!: string;

  @Expose()
  unit!: string;

  @Expose()
  floor!: string;

  @Expose()
  building!: string;

  @Expose()
  noOfRooms!: EResaleNoOfRoom | EResaleNoOfRoomHdb | string;

  @Expose()
  block!: string;

  @Expose()
  street!: string;

  @Expose()
  district!: string;

  @Expose()
  state!: string;

  @Expose()
  city!: string;

  @Expose()
  updatedAt!: string;
}


