import { createFeature, createReducer, on } from '@ngrx/store';
import { BlankFormAction } from '../actions';
import { BlankFormModel } from '@shared/data-access/models/blank-form.model';
import {
  BlankFormBdModel,
  BlankFormBuyerInfoModel,
  BlankFormBuyerModel,
  BlankFormExternalCoBrokeModel,
  BlankFormIcModel,
  BlankFormInternalCoBrokeModel,
  BlankFormOtherFeeModel,
  BlankFormPdModel,
  BlankFormReferralModel,
  BlankFormRelatedAgentModel,
  BusinessDirectorModel,
  ProjectTransactionBonusModel,
  ProjectTransactionRelatedPartyModel,
  SplitMatrixModel,
  SplitPartModel,
} from '@shared/data-access/models';
import { IBlankFormBd, IBlankFormRelatedAgent, ISplitPart } from '@shared/data-access/interfaces';
import { EBlankFormStatus, EBusinessDirector } from '@shared/data-access/enums';
import { DateTimeConverter } from '@shared/utils/datetime-converter';

export const featureName = 'blankForm';

export interface BlankFormState {
  item: BlankFormModel | null;
  draftItem: BlankFormModel;
  draftType: 'create' | 'edit' | 'clone';
  leaders: BlankFormInternalCoBrokeModel[];
  bds: BlankFormBdModel[];
  bonuses: BlankFormIcModel[];
  overridings: BlankFormIcModel[];
  pools: BlankFormIcModel[];
  pds: BlankFormPdModel[];
  referrals: BlankFormReferralModel[];
  otherFees: BlankFormOtherFeeModel[];
  buyers: BlankFormBuyerModel[];
  buyer: BlankFormBuyerInfoModel | null;
  bdsFromProject: BusinessDirectorModel[];
  submittingStatus: {
    success: any;
    loading: boolean;
    error: any;
    errorOnSubmit: boolean;
    submitData: any;
  };
  optionDate: string | null;
  blankFormType: string | null;
  invalidAgents: ISplitPart[] | null;
}

export const initialState: BlankFormState = {
  item: null,
  draftItem: BlankFormModel.createEmpty(),
  draftType: 'create',
  leaders: [],
  bds: [],
  bonuses: [],
  overridings: [],
  pools: [],
  pds: [],
  referrals: [],
  otherFees: [],
  buyers: [],
  buyer: null,
  bdsFromProject: [],
  submittingStatus: {
    success: null,
    loading: false,
    error: null,
    errorOnSubmit: false,
    submitData: null,
  },
  optionDate: null,
  blankFormType: null,
  invalidAgents: null,
};

export const blankFormFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(BlankFormAction.setItemDetail, (state, { item }) => ({
      ...state,
      item,
    })),
    on(BlankFormAction.updateItemDetail, (state, { data }) => ({
      ...state,
      item: state.item ? BlankFormModel.merge(state.item, data) : null,
    })),
    on(BlankFormAction.setDraftItemAsCreate, state => ({
      ...state,
      draftItem: BlankFormModel.createEmpty(),
      draftType: 'create',
    })),
    on(BlankFormAction.setDraftItemAsEdit, state => ({
      ...state,
      draftItem: state.item ? BlankFormModel.clone(state.item) : BlankFormModel.createEmpty(),
      draftType: 'edit',
    })),
    on(BlankFormAction.setDraftItemAsClone, state => ({
      ...state,
      draftItem: state.item
        ? BlankFormModel.merge(state.item, {
          status: EBlankFormStatus.draft,
          bonuses: state.item.bonuses.map(i => ({ ...i, id: undefined })),
          overridings: state.item.overridings.map(i => ({ ...i, id: undefined })),
          pools: state.item.pools.map(i => ({ ...i, id: undefined })),
          pds: state.item.pds.map(i => ({ ...i, id: undefined })),
          referrals: state.item.referrals.map(i => ({ ...i, id: undefined })),
          otherFees: state.item.otherFees.map(i => ({ ...i, id: undefined })),
          bds: state.item.bds.map(i => ({ ...i, id: undefined })),
          buyers: state.item.buyers.map(i => ({ ...i, id: undefined })),
          buyer: state.item.buyer,
        })
        : BlankFormModel.createEmpty(),
      draftType: 'clone',
    })),
    on(BlankFormAction.updateDraftItem, (state, { data }) => {
      return ({
        ...state,
        draftItem: BlankFormModel.merge(state.draftItem, data),
      })
    }),
    on(BlankFormAction.setDefaultValueRelatedAgentFromReference, (state, { relatedAgent }) => {
      let relatedAgentPlain: IBlankFormRelatedAgent;
      if (relatedAgent instanceof ProjectTransactionRelatedPartyModel) {
        relatedAgentPlain = ProjectTransactionRelatedPartyModel.toJson(relatedAgent) as IBlankFormRelatedAgent;
      } else {
        relatedAgentPlain = BlankFormRelatedAgentModel.toJson(relatedAgent) as IBlankFormRelatedAgent;
      }
      const relatedAgentModel = BlankFormRelatedAgentModel.fromJson(relatedAgentPlain);
      const { mains, internals, externals, leaders } = relatedAgentModel;
      const [mainsDefault, internalsDefault, externalsDefault, leadersDefault] = [mains, internals, externals, leaders].map(agents =>
        agents.map(agent => {
          if (!(agent instanceof BlankFormExternalCoBrokeModel)) {
            const { tiers: tierAgent, parties: partiesAgent } = agent;
            const tiers = tierAgent.map(tier =>
              SplitPartModel.merge(tier, {
                defaultValue: tier.salespersonId || tier.salesperson?.id ? tier.value ?? null : null,
                value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
              })
            );
            const parties = partiesAgent.map(party =>
              SplitPartModel.merge(party, {
                defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
                value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
              })
            );
            return BlankFormInternalCoBrokeModel.merge(agent, { isDefault: true, tiers, parties, id: undefined });
          }
          return BlankFormExternalCoBrokeModel.merge(agent, { isDefault: true, receivedValue: 0, id: undefined });
        })
      );
      const relatedAgentDefault = BlankFormRelatedAgentModel.merge(BlankFormRelatedAgentModel.createEmpty(), {
        mains: mainsDefault,
        internals: internalsDefault,
        externals: externalsDefault,
        leaders: leadersDefault,
      });
      return {
        ...state,
        draftItem: BlankFormModel.merge(state.draftItem, {
          relatedAgent: relatedAgentDefault,
        }),
      };
    }),
    on(BlankFormAction.setDefaultValueBonusesFromReference, (state, { bonuses }) => {
      const defaultBonuses = bonuses.map(bonus => {
        const { salespersonId, salesperson } = bonus;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = bonus;
        const bonusPlain = bonuses instanceof ProjectTransactionBonusModel ? ProjectTransactionBonusModel.toJson(bonus) : BlankFormIcModel.toJson(bonus);
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId || tier.salesperson?.id ? tier.value ?? null : null,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue:
              party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormIcModel.fromJson({ ...bonusPlain, id: undefined, isDefault: true, tiers, parties });
      });
      return {
        ...state,
        bonuses: defaultBonuses,
      };
    }),
    on(BlankFormAction.setDefaultValueOverridingsFromReference, (state, { overridings }) => {
      const defaultOverridings = overridings.map(overriding => {
        const { salespersonId, salesperson, appointmentType, appointmentTypeId } = overriding;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = overriding;
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId || tier.salesperson?.id ? tier.value ?? null : null,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormIcModel.fromJson({
          id: undefined,
          isDefault: true,
          tiers,
          parties,
          salespersonId,
          salesperson,
          appointmentType,
          appointmentTypeId,
        });
      });
      return {
        ...state,
        overridings: defaultOverridings,
      };
    }),
    on(BlankFormAction.setDefaultValuePoolsFromReference, (state, { pools }) => {
      const defaultPools = pools.map(pool => {
        const { salespersonId, salesperson, appointmentType, appointmentTypeId } = pool;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = pool;
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId || tier.salesperson?.id ? tier.value ?? null : null,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormIcModel.fromJson({
          id: undefined,
          isDefault: true,
          tiers,
          parties,
          salespersonId,
          salesperson,
          appointmentType,
          appointmentTypeId,
        });
      });
      return {
        ...state,
        pools: defaultPools,
      };
    }),
    on(BlankFormAction.setDefaultValuePdsFromReference, (state, { pds }) => {
      const defaultPds = pds.map(pd => {
        const { additional, pdRole, salespersonId, salesperson, appointmentType, appointmentTypeId } = pd;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = pd;
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId || tier.salesperson?.id ? tier.value ?? null : null,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormPdModel.fromJson({
          id: undefined,
          isDefault: true,
          additional,
          pdRole,
          tiers,
          parties,
          salespersonId,
          salesperson,
          appointmentType,
          appointmentTypeId,
        });
      });
      return {
        ...state,
        pds: defaultPds,
      };
    }),
    on(BlankFormAction.setDefaultValueReferralsFromReference, (state, { referrals }) => {
      const defaultReferrals = referrals.map(referral => {
        const { salespersonId, salesperson, agent, agentId, type, absorbType, formula, receivedValue: defaultValue } = referral;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = referral;
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId || tier.salesperson?.id ? tier.value ?? null : null,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormReferralModel.fromJson({
          id: undefined,
          isDefault: true,
          tiers,
          parties,
          salespersonId,
          salesperson,
          agentId,
          agent,
          type,
          absorbType,
          formula,
          receivedValue: 0,
          defaultValue,
        });
      });
      return {
        ...state,
        referrals: defaultReferrals,
      };
    }),
    on(BlankFormAction.setDefaultValueBdsFromReference, (state, { bds }) => {
      const defaultBds = bds.map(bd => {
        const { salesperson, salespersonId, appointmentType, appointmentTypeId, type } = bd;
        const splitMatrixDefault = SplitMatrixModel.createEmpty();
        splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
          level: 1,
          salespersonId,
          salesperson,
          value: 0,
        });
        let { tiers, parties } = bd;
        tiers = (tiers ?? splitMatrixDefault.tiers).map(tier =>
          SplitPartModel.merge(tier, {
            defaultValue: tier.salespersonId || tier.salesperson?.id ? tier.value ?? null : null,
            value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
          })
        );
        parties = (parties ?? splitMatrixDefault.parties).map(party =>
          SplitPartModel.merge(party, {
            defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
            value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
          })
        );
        return BlankFormBdModel.fromJson({
          id: undefined,
          isDefault: true,
          salesperson,
          salespersonId,
          appointmentType,
          appointmentTypeId,
          type,
          tiers,
          parties,
        } as Partial<IBlankFormBd>);
      });
      return {
        ...state,
        bds: defaultBds.filter(_ => _?.type === EBusinessDirector.HEAD_HI || _?.type === EBusinessDirector.BUSINESS_DIRECTOR),
      };
    }),
    on(BlankFormAction.setDefaultValueOtherFeeFromReference, (state, { otherFees }) => {
      const defaultOtherFees = otherFees.map(otherFee => {
        const { code, name } = otherFee;
        return BlankFormOtherFeeModel.fromJson({
          id: undefined,
          isDefault: true,
          name,
          code,
          receivedValue: 0,
        });
      });
      return {
        ...state,
        otherFees: defaultOtherFees,
      };
    }),
    on(BlankFormAction.setDefaultValueBuyersFromReference, (state, { buyers }) => {
      return {
        ...state,
        buyers: buyers.map(buyer => BlankFormBuyerModel.fromJson({ ...buyer, id: undefined })),
      };
    }),
    // on(BlankFormAction.setDefaultValueBuyerFromReference, (state, { buyer }) => {
    //   return {
    //     ...state,
    //     buyer: buyer,
    //   };
    // }),
    on(BlankFormAction.setBdsFromProject, (state, { bdsFromProject }) => ({
      ...state,
      bdsFromProject: bdsFromProject.map(bd => BusinessDirectorModel.merge(bd, { id: undefined })),
    })),
    on(BlankFormAction.saveDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
        submitData: null,
        errorOnSubmit: false,
      },
    })),
    on(BlankFormAction.saveAndSubmitDraftItem, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: true,
        error: null,
        submitData: null,
        errorOnSubmit: false,
      },
    })),
    on(BlankFormAction.submitItemSuccess, (state, { res }) => ({
      ...state,
      submittingStatus: {
        success: res,
        loading: false,
        error: null,
        submitData: null,
        errorOnSubmit: false,
      },
    })),
    on(BlankFormAction.submitItemFail, (state, { error }) => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error,
        errorOnSubmit: error?.errorOnSubmit ?? false,
        submitData: error?.submitData ?? null,
      },
    })),
    on(BlankFormAction.resetDraftItem, state => ({
      ...state,
      item: null,
      draftItem: BlankFormModel.createEmpty(),

      draftType: 'create',
    })),
    on(BlankFormAction.resetSubmittingStatus, state => ({
      ...state,
      submittingStatus: {
        success: null,
        loading: false,
        error: null,
        errorOnSubmit: false,
        submitData: null,
      },
    })),
    on(BlankFormAction.setLoadingStatus, (state, { loading }) => ({
      ...state,
      submittingStatus: {
        success: null,
        loading,
        error: null,
        errorOnSubmit: false,
        submitData: null,
      },
    })),
    on(BlankFormAction.reset, () => initialState),
    on(BlankFormAction.updateOptionDate, (state, { date }) => ({
      ...state,
      optionDate: date,
    })),
    on(BlankFormAction.updateInvalidAgents, (state, { agents }) => ({
      ...state,
      invalidAgents: agents as ISplitPart[],
    })),
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = blankFormFeature;
