import { BaseModel, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { InvoicePostingType } from '../enums';
import { PaymentReversalType } from '../types';
import { LedgerAccountDto } from './ledger-account.dto';

export class PaymentReversalPostingDto extends BaseModel {
  @Expose()
  id?: number;

  @Expose()
  accountId!: number;

  @Expose()
  account?: LedgerAccountDto;

  @Expose()
  @Transform((object) => {
    if (object?.obj?.account) {
      return object?.obj?.account?.code
    }
    return object?.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  credit!: number;

  @Expose()
  debit!: number;

  @Expose()
  amount!: number;

  @Expose()
  sequence?: number;

  @Expose()
  type?: `${InvoicePostingType}`;
}

export class PaymentReversalCreateDto extends BaseModel {
  @Expose()
  type!: PaymentReversalType;

  @Expose()
  sourceId!: number;

  @Expose()
  remark!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  reversalDate!: string;

  @Expose()
  @Type(() => PaymentReversalPostingDto)
  postings!: PaymentReversalPostingDto[];
}

export class PaymentReversalUpdateDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  type!: PaymentReversalType;

  @Expose()
  sourceId!: number;

  @Expose()
  remark!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  reversalDate!: string;

  @Expose()
  @Type(() => PaymentReversalPostingDto)
  postings!: PaymentReversalPostingDto[];
}

export class PaymentReversalPostingReqDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  sourceId!: number;

  @Expose()
  type!: PaymentReversalType;
}
