import { IUpdatedBy } from './updated-by.interface';
import { IUserGroupAccessible } from './user-group.interface';

export interface IUserPermission {
  id: number;
  name: string;
  code: string;
  description: string;
  // status: string;
  createdAt: string;
  updatedAt: string;
  group: string;
  feature: string;
  isActive: boolean;
  isHidden: boolean;
  module: string;
  scope: string;
  slug: string;
  updatedBy: IUpdatedBy;
  accessible: IUserGroupAccessible[];
}
