import { ClassProvider, Injectable, InjectionToken, Type, inject } from '@angular/core';
import { IApiOption } from '@red/api';
import { Observable, throwError } from 'rxjs';
import { ExceljsCard, ExceljsFileSupportedConfig, ExceljsWorkBook } from './models';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ExceljsParameterService } from './exceljs-parameter/exceljs-parameter.service';
export const EXCELJS_HTTP_LOADER = new InjectionToken<ExceljsHttpLoader>('EXCELJS_HTTP_LOADER');
export const EXCELJS_CARD = new InjectionToken<ExceljsCard>('EXCELJS_CARD');
// export const EXCELJS_PARAMETER_SERVICE = new InjectionToken<ExceljsParameterService>('EXCELJS_PARAMETER_SERVICE');
export type ExceljsHttpJob = 'download'
export interface IExceljsHttpJobRes {
  jobId: string
}export interface IExceljsHttpJobStatusRes {
  status: 'active' | 'completed' | 'failed'; // Job đã chạy hay chưa
  success: boolean // Job chạy có thành công hay không
  errorMessage?: string;
}
@Injectable()
export abstract class ExceljsHttpLoader {
  /**
   * getCard
   *
   * @param code: string
   */
  abstract getCard(code: string): Observable<ExceljsCard>;

  /**
   * query
   *
   * @param code: string
   * @param option
   */
  abstract query(code: string, data: Record<string, any>, option?: IApiOption): Observable<ExceljsWorkBook>;

  /**
   * export
   *
   * @param code: string
   * @param option
   */
  abstract export(code: string, data: Record<string, any>, ext?: string | ExceljsFileSupportedConfig): Observable<any>;
  /**
   * get data by query
   * Using for template
   */
  get(method: 'get' | 'post', endpoint: string, query: HttpParams | any): Observable<any> {
    const httpClient = inject(HttpClient);
    const payload = this.normalizeOption(method, endpoint, query);
    return httpClient.request(payload.method, payload.url, payload.options);
  }
  getJob(code: string, jobName: ExceljsHttpJob = 'download', data: Record<string, any>): Observable<IExceljsHttpJobRes> {
    return throwError(() => 'getJob function is not implemented')
  }
  getJobStatus(jobId: string): Observable<IExceljsHttpJobStatusRes> {
    return throwError(() => 'getJobStatus function is not implemented')
  }
  getJobLink(jobId: string): Observable<string> {
    return throwError(() => 'getJobLink function is not implemented')
  }
  normalizeOption(method: string, endpoint: string, query: HttpParams) {
    const responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json';
    return {
      method,
      url: this.createAPIURL(endpoint, query),
      options: {
        params: query,
        responseType,
      },
    };
  }

  createAPIURL(url: string, params: HttpParams | any): string {
    const paths = url.split('/');
    paths.forEach((path, i) => {
      if (path.startsWith(':')) {
        const key = path.slice(1);
        if (typeof params[key] !== 'object') {
          paths[i] = String(params[key]);
          delete params[key];
        }
      }
    });

    return paths.join('/');
  }
}

export const useExceljsLoader = (instance: Type<ExceljsHttpLoader>): ClassProvider => ({
  provide: EXCELJS_HTTP_LOADER,
  useClass: instance,
  multi: false,
});
