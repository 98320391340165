import { IChequeCompact, ICreditNoteRecord, ISupplierInvoiceRecord, ITaxInvoiceRecord, ITemplates } from '.';
import { InvoicePostingType } from '../enums';
import { PaymentBatchType, PaymentMemoType, PaymentMode, PaymentStatus, PaymentType } from '../types';
import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { IDealValue } from './deal-value.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IProfitCentres } from './profit-centres.interface';

export interface IPaymentMemoContact {
  id: number;
  code: string;
  name: string;
  receiveAccountId: number;
  paidAccountId: number;
  paidAccount: Partial<ILedgerAccount>;
}

export interface IPaymentRecordMetadata {
  taxPercent: number;
  isEdit: boolean;
}

export interface IPaymentMemoPostingAccount {
  accountCode: string;
  accountId: number;
  accountName: string;
}

export interface IPaymentMemoInvoice {
  id: number;
  code: string;
  contactId: number;
  total: number;
  paidAmount: number;
  balanceDue: number;
  // createdAt?: Date;
  // updatedAt?: Date;
  // deletedAt?: Date;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  contact: IPaymentMemoContact;
}

export interface IPaymentMemo {
  id: number;
  itemCode: string;
  description: string;
  total: number;
  paidAmount: number;
  balanceDue: number;
  // updatedAt?: Date;
  updatedAt?: string;
  invoice: IPaymentMemoInvoice;
  invoiceDate: string;
  posting: IPaymentMemoPostingAccount;
}

export interface IPayment {
  id: number;
  businessUnitId: number;
  status: PaymentStatus;
  type: PaymentType;
  mode: PaymentMode;
  code: string;
  payerName: string;

  contactId?: number;
  contact?: IContactCustomerAndSupplier;

  accountId: number;
  account: ILedgerAccount;

  // paymentDate: Date;
  paymentBatchId?: number;
  paymentBatchCode?: string;
  paymentBatchType?: PaymentBatchType;
  paymentDate: string;

  currency: string;

  cheque?: IChequeCompact;
  chequeId?: number;

  reference?: string;

  templateId?: number;
  template?: ITemplates;

  totalAmount?: number;
  inClosedPeriod?: boolean;
  isBlocked?: boolean;
  isSentMail?: boolean;

  // createdAt: Date;
  // updatedAt: Date;
  createdAt: string;
  updatedAt: string;
}

export interface IPaymentRecord {
  id: number;
  type: string;

  payment: IPayment;
  paymentId: number;

  accountId: number;
  accountCode: string;
  account: ILedgerAccount;

  profitCenterId?: number;
  profitCenter?: IProfitCentres;

  paymentMemoType?: PaymentMemoType;
  paymentMemoDetailId?: number;
  supplierInvoiceDetail?: ISupplierInvoiceRecord;
  customerCreditNoteDetail?: ICreditNoteRecord;

  description: string;

  debit: IDealValue;
  credit: IDealValue;

  isAdvance?: boolean;
  isTaxable?: boolean;
  isDefault: boolean;

  invoiceDetail: ITaxInvoiceRecord;
  invoiceDetailId: number;
  invoiceDetailNumber?: string;
  invoiceDetailBalance?: number;
  invoiceDate?: string;

  invoiceRate: number;
  invoiceNumber?: string;
  invoiceBalance?: number;

  amount?: number;
  taxAccountId?: number;
  taxAccount?: ILedgerAccount;
  taxDescription?: string;

  gstCategory?: string;
  gstType?: 'gstExclusive' | 'gstInclusive';

  taxUnit?: 'percentage' | 'number';
  taxValue?: number;
  taxAmount?: number;

  metadata?: IPaymentRecordMetadata;

  sequence?: number;

  transactionNo?: string;
  remarks?: string;

  // createdAt: Date;
  // updatedAt: Date;
  createdAt: string;
  updatedAt: string;
}

export interface IPaymentPosting {
  id: number;

  paymentId: number;
  payment: IPayment;

  accountId: number;
  accountCode: string;
  account: ILedgerAccount;

  profitCenterId?: number;
  profitCenter?: IProfitCentres;

  description: string;

  credit: number;
  debit: number;

  amount: number;
  sequence?: number;
  type?: `${InvoicePostingType}`;

  taxAccountId?: number;
  taxAccount?: ILedgerAccount;
  taxDescription?: string;
  taxUnit?: 'percentage' | 'number';
  taxValue?: number;
  taxAmount?: number;
  isTaxable?: boolean;
}

export interface IPaymentPostingDataSource {
  postings: IPaymentPosting[];
  total: number;
}

export interface IPaymentOffset {
  type: string;
  id: number;
  ref: string;
  amount: number;
  // date: Date;
  date: string;
}
