import { map, forkJoin } from 'rxjs';
/* eslint-disable no-useless-escape */
import { Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, finalize, Observable, of, switchMap, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FileUploaderModel } from '../../data-access/models';
import { MediaStorageService } from '../media-storage';
import { CanUploadFn } from './upload.interface';

@Directive({
  selector: 'input[redUpload]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadDirective),
      multi: true,
    },
  ],
})
export class UploadDirective implements ControlValueAccessor, OnInit, OnChanges {
  static imageMineTypes = ['image/jpg', 'image/jpeg', 'image/png'];

  @Input() disabled = false;
  @Input() isUploadImmediately = false;
  @Input() isReplaced = false;
  @Input() allowedMimeType!: Array<string>;
  @Input() maxFiles = 15;
  @Input() maxFileSize = 15 * 1024 * 1024;
  @Input() uploadToFolder = 'asset';
  @Input() existFileKeys!: Array<string>;
  @Input() allowExtensions = ['png', 'jpeg', 'jpg'];
  @Input() isPublic = false;
  @Input() showFileError = false;
  @Input() validateFileName = true;
  @Input() apiGetUploadLink!: (data: { type: string; name: string; acl?: any }) => Observable<any>;
  @Output() all = new EventEmitter<FileUploaderModel[]>();
  @Output() added = new EventEmitter<FileUploaderModel[]>();
  @Output() removed = new EventEmitter<FileUploaderModel[]>();
  @Output() lastUploaded = new EventEmitter<FileUploaderModel[]>();
  @Output() begin = new EventEmitter<any>();
  @Output() finished = new EventEmitter<any>();
  @Output() beginFile = new EventEmitter<FileUploaderModel>();
  @Output() finishedFile = new EventEmitter<FileUploaderModel>();
  @Output() fullSlot = new EventEmitter<void>();
  @Input() canUploadFn?: CanUploadFn;
  files: any[] = [];
  fileId = 0;
  allFiles: FileUploaderModel[] = [];
  addedFiles: FileUploaderModel[] = [];
  lastUploadedFiles: FileUploaderModel[] = [];

  constructor(private elementRef: ElementRef, private _storageApiService: MediaStorageService) { }

  ngOnInit(): void {
    this.patchExistFiles();
  }

  ngOnChanges(): void {
    this.patchExistFiles();
  }

  writeValue(obj: any): void {
    throw new Error('Method not implemented.');
  }

  registerOnChange(fn: any): void {
    throw new Error('Method not implemented.');
  }

  registerOnTouched(fn: any): void {
    throw new Error('Method not implemented.');
  }

  @HostListener('change')
  onChange(files?: any) {
    if (this.disabled) {
      return;
    }
    if (files) {
      this.files = Array.from(files);
    } else {
      this.files = Array.from(this.elementRef.nativeElement.files);
    }
    this.elementRef.nativeElement.value = '';

    if (this.isReplaced) {
      this.allFiles = [];
    }

    this.validateFiles().subscribe(() => {
      this.loadFiles();
    });
    // this.loadFiles();

    // if (this.isUploadImmediately) {
    //   this.upload();
    // }
  }

  validateFiles(): Observable<boolean> {
    this.addedFiles = [];
    const numberRemainingSlots = this.countRemainingSlots();
    if (numberRemainingSlots < this.files.length) {
      this.fullSlot.emit();
      return of(true);
    }
    const n = this.files.length < numberRemainingSlots ? this.files.length : numberRemainingSlots;
    const stacks: Observable<unknown>[] = [];

    for (let i = 0; i < n; i++) {
      const currentFile = this.files[i];
      const fileUploader = new FileUploaderModel();
      fileUploader.id = this.fileId++;
      fileUploader.name = currentFile?.name;
      fileUploader.size = currentFile?.size;
      fileUploader.file = new File([currentFile], new Date().getTime() + Math.random().toString().substr(2, 10) + '.' + currentFile.name.split('.').pop(), {
        type: currentFile.type,
      });

      if (this.canUploadFn) {
        stacks.push(
          this.canUploadFn(currentFile).pipe(
            map(canUploadFn => {
              if (canUploadFn.canUpload) {
                return this._canLoadProgess(fileUploader);
              }

              fileUploader.error = {
                status: true,
                message: canUploadFn.errorMessage || 'File is not allowed to upload',
              };

              return fileUploader;
            }),
            map(fileUploader => {
              if (fileUploader.error.status && !this.showFileError) {
                return null;
              }
              return fileUploader;
            }),
            tap(fileUploader => {
              if (!fileUploader) return;

              const index = this.allFiles.findIndex(item => item.name === fileUploader.name);
              if (index !== -1) {
                this.allFiles.splice(index, 1);
              }

              this.addedFiles.push(fileUploader);
              this.allFiles.push(fileUploader);
            })
          )
        );
      } else {
        stacks.push(
          of(this._canLoadProgess(fileUploader)).pipe(
            tap(fileUploader => {
              if (fileUploader.error.status && !this.showFileError) {
                this.finishedFile.emit(fileUploader);
              }
            }),
            tap(fileUploader => {
              this.addedFiles.push(fileUploader);
              this.allFiles.push(fileUploader);
            })
          )
        );
      }
    }

    return forkJoin(stacks).pipe(
      tap(() => {
        this.all.emit(this.allFiles);
        this.added.emit(this.addedFiles);
      }),
      map(() => true)
    );
  }

  _canLoadProgess(fileUploader: FileUploaderModel) {
    let error = false;

    const nameRegex = /[`!@#$%^&*+=;':",.<>?~]/;
    const [name, ext] = fileUploader.name.split('.');
    if (nameRegex.test(name) && this.validateFileName) {
      fileUploader.error = {
        status: true,
        message: `File names can't contain the following characters: !@#$%^&*+=;':\",.<>?~`,
      };
      error = true;
    }
    const regex = new RegExp(`(.*?)\.(${this.allowExtensions.join('|')})$`);
    if (!regex.test(fileUploader.name.toLowerCase()) || (this.allowedMimeType && !this.allowedMimeType.includes(fileUploader.file.type))) {
      fileUploader.error = {
        status: true,
        // message: 'File format is invalid or unsupported.',
        message: 'Only files with the following extensions are allowed: ' + this.allowExtensions.map(x => '.' + x).join(', '),
      };
      error = true;
    }

    if (fileUploader.file.size > this.maxFileSize) {
      fileUploader.error = {
        status: true,
        message: 'File is too large',
      };
      error = true;
    }
    if (fileUploader.file.size === 0) {
      fileUploader.error = {
        status: true,
        message: 'File size is 0 byte',
      };
      error = true;
    }

    return fileUploader;
  }

  loadFiles() {
    this.allFiles
      .filter(f => !f.error.status && !f.isExistFile)
      .forEach(fileUploader => {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          (event: any) => {
            fileUploader.data = event.target.result;
          },
          false
        );
        reader.readAsDataURL(fileUploader.file);
      });
  }

  upload() {
    this.begin.emit();
    this.lastUploadedFiles = [];
    const requests: any[] = [];
    this.allFiles
      .filter(f => !f.completed && !f.error.status && !f.isExistFile)
      .forEach(fileUploader => {
        requests.push(this.uploadFile(fileUploader));
      });
    if (!requests.length) {
      this.finished.emit();
      return;
    }
    combineLatest(requests).subscribe(() => {
      this.lastUploaded.emit(this.lastUploadedFiles);
      this.all.emit(this.allFiles);
      this.finished.emit();
    });
  }

  uploadFile(fileUploader: any) {
    fileUploader.uploading = true;
    this.beginFile.emit(fileUploader);
    const payload: any = { type: this.uploadToFolder, name: fileUploader.file.name };
    if (this.isPublic) {
      payload['acl'] = 'public-read';
    }
    return this._storageApiService.getUploadLink(payload).pipe(
      switchMap((linkInfo: any) => {
        fileUploader.key = linkInfo?.fields?.key;
        const uploadData = linkInfo;
        if (this.isPublic) {
          uploadData.isPublic = this.isPublic;
          fileUploader.fullKeyName = linkInfo.fields?.fullKeyName;
        }
        uploadData.file = fileUploader.file;
        return this._storageApiService.uploadFile(uploadData);
      }),
      switchMap(() =>
        this._storageApiService.getPublicLink({
          keyName: fileUploader.key,
          folder: this.uploadToFolder,
          filename: fileUploader.name,
        })
      ),
      tap(url => (fileUploader.url = url)),
      catchError((error: any) => {
        fileUploader.error = {
          status: true,
          message: error?.message,
        };
        return of(null);
      }),
      finalize(() => {
        fileUploader.uploading = false;
        fileUploader.completed = true;
        this.lastUploadedFiles.push(fileUploader);
        this.finishedFile.emit(fileUploader);
      })
    );
  }

  countRemainingSlots() {
    return this.maxFiles - this.allFiles.length;
  }

  clear() {
    this.removed.emit(this.allFiles);
    this.allFiles = [];
    this.all.emit(this.allFiles);
  }

  deleteFile(id: number): void {
    const index = this.allFiles.findIndex(fileUploader => {
      return id === fileUploader.id;
    });
    if (index > -1) {
      this.removed.emit([this.allFiles[index]]);
      this.allFiles.splice(index, 1);
      this.all.emit(this.allFiles);
    }
  }

  patchExistFiles() {
    if (!this.existFileKeys || !this.existFileKeys.length) {
      return;
    }
    this.allFiles = [];
    this.existFileKeys.forEach(key => {
      const fileUploader = new FileUploaderModel();
      fileUploader.id = this.fileId++;
      fileUploader.key = key;
      fileUploader.isExistFile = true;
      fileUploader.completed = true;
      fileUploader.file = {
        name: key.split('/').slice(-1).pop(),
      };
      fileUploader.data = key;
      this.allFiles.push(fileUploader);

      // this.storageApiService.getPublicLink({name: key}).subscribe((info => {
      //     fileUploader.file.size = info.contentLength;
      //     fileUploader.file.type = info.contentType;
      //     fileUploader.data = info.objectUrl;
      //     this.allFiles.push(fileUploader);
      //     this.all.emit(this.allFiles);
      // }));
    });
    this.all.emit(this.allFiles);
  }
}
