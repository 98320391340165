import { APP_INITIALIZER, ErrorHandler, Inject, InjectionToken, ModuleWithProviders, NgModule, Optional, SkipSelf } from "@angular/core";
import { Router } from "@angular/router";

import * as Sentry from "@sentry/angular";
export const SENTRY_BROWSER_OPTIONS = new InjectionToken<Sentry.BrowserOptions>('SENTRY_BROWSER_OPTIONS')
export const SENTRY_USER_TRACING_FN = new InjectionToken<Sentry.BrowserOptions>('SENTRY_BROWSER_OPTIONS')
@NgModule()
export class SentryCoreModule {
    constructor(
        @Optional() @SkipSelf() parentModule: SentryCoreModule,
        @Inject(SENTRY_BROWSER_OPTIONS) private options: Sentry.BrowserOptions
    ) {
        if (parentModule) {
            throw new Error('SentryCoreModule is already loaded. Import it in the AppModule only!');
        }
        console.log('sentry enabled', options.enabled)
        Sentry.init({

            integrations: [
                // Registers and configures the Tracing integration,
                // which automatically instruments your application to monitor its
                // performance, including custom Angular routing instrumentation
                Sentry.browserTracingIntegration(),
                // Registers the Replay integration,
                // which automatically captures Session Replays
                // Sentry.replayIntegration(),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            // replaysSessionSampleRate: 0.1,
            // replaysOnErrorSampleRate: 1.0,
            ...this.options
        });
    }
    static setUser(user: { userId: string | number; email?: string, name: string } | null): void {
        Sentry.setUser(user)
    }
    static forRoot(options: Sentry.BrowserOptions): ModuleWithProviders<SentryCoreModule> {
        return {
            ngModule: SentryCoreModule,
            providers: [
                {
                    provide: SENTRY_BROWSER_OPTIONS,
                    useValue: options
                },
                {
                    provide: ErrorHandler,
                    useValue: Sentry.createErrorHandler(),
                },
                {
                    provide: Sentry.TraceService,
                    deps: [Router],
                },
                {
                    provide: APP_INITIALIZER,
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    useFactory: () => () => { },
                    deps: [Sentry.TraceService],
                    multi: true,
                },
            ]
        }
    }
}