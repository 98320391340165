import { TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { WhiteSpaceModule } from '../white-space/white-space.module';
import { WhiteSpacePipe } from '../white-space/white-space.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';

@NgModule({
  declarations: [EnumToArrayPipe],
  exports: [EnumToArrayPipe],
  providers: [TitleCasePipe, WhiteSpacePipe, EnumToArrayPipe],
  imports: [WhiteSpaceModule],
})
export class EnumToArrayModule {}
