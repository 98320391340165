import { TransferEmplacementAction } from '../actions';

export * as PhoneNumberReducer from './phone-number.reducers';
export * as SalespersonAdditionalInfoReducer from './salesperson-additional-information.reducers';
export * as EducationRecordReducer from './education-record.reducers';
export * as EmploymentHistoryReducer from './employment-history.reducers';
export * as CommSalespersonSchemeReducer from './comm-salesperson-scheme.reducers';
export * as CommLeadershipSchemeReducer from './comm-leadership-scheme.reducers';
export * as ProfitSharingReducer from './profit-sharing.reducers';
export * as DeveloperReducer from './developer.reducers';
export * as CommissionMatrixReducer from './comm-matrix.reducers';
export * as ProjectCommissionMatrixReducer from './project-comm-matrix.reducers';
export * as OverridingDistributionReducer from './overriding-distribution.reducers';
export * as BonusDistributionReducer from './bonus-distribution.reducers';
export * as AppointmentTypeReducer from './appointment-type.reducers';
export * as ProjectConsultantReducer from './project-consultant.reducers';
export * as ProjectTransactionReducer from './project-transaction.reducers';
export * as CareerProgressionReducer from './career-progression.reducer';
export * as ManagementCommissionReducer from './management-commission.reducer';
export * as BlankFormReducer from './blank-form.reducer';
export * as BatchingApprovalReducer from './batching-approval.reducers';
export * as ChartOfAccountsReducer from './chart-of-accounts.reducers';
export * as ProfitCenterReducer from './profit-center.reducers';
export * as JournalReducer from './journal.reducer';
export * as UserAccountReducer from './user-account.reducers';
export * as UserPermissionReducer from './user-permission.reducers';
export * as UserGroupReducer from './user-group.reducers';
export * as UserRoleReducer from './user-role.reducers';
export * as ContactReducer from './contact.reducers';
export * as ResaleTransactionReducer from './resale-transaction.reducer';
export * as MessageOutboxReducer from './message-outbox.reducers';
export * as ResaleInvoiceDeliveryReducer from './resale-invoice-delivery.reducers';
export * as TransferEmplacementReducer from './transfer-emplacement.reducers';
