import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { LookupTableDto } from '@shared/data-access/dto';
import { ILookupTable } from '@shared/data-access/interfaces';
import { LookupTableDetailModel, LookupTableModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LookupTableApiService {
  static ROOT_POINT = 'collection/ams/lookup';
  static LIST_POINT = 'collection/ams/lookup-setup';
  static LIST_COMMOM_POINT = 'collection/ams/lookup-setup/lookup-setup-common';


  constructor(private apiService: ApiService) { }

  create(data: LookupTableDto): Observable<LookupTableModel> {
    return this.apiService.post(`${LookupTableApiService.ROOT_POINT}`, data).pipe(map((res: ILookupTable) => LookupTableModel.fromJson(res) as LookupTableModel));
  }

  get(itemId: number, query = {}): Observable<LookupTableModel> {
    return this.apiService.get(LookupTableApiService.LIST_POINT + '/' + itemId, query).pipe(map(res => LookupTableModel.fromJson(res) as LookupTableModel));
  }

  getCommon(itemId: number, query = {}): Observable<LookupTableModel> {
    return this.apiService.get(LookupTableApiService.LIST_COMMOM_POINT + '/' + itemId, query).pipe(map(res => LookupTableModel.fromJson(res) as LookupTableModel));
  }

  update(id: number, data: LookupTableDto): Observable<any> {
    return this.apiService.patch(LookupTableApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<LookupTableModel>> {
    return this.apiService.get(LookupTableApiService.LIST_POINT, query, option).pipe(map(data => new PaginationAdapter(LookupTableModel, data)));
  }

  searchCommon(query = {}, option?: IApiOption): Observable<PaginationAdapter<LookupTableModel>> {
    return this.apiService.get(LookupTableApiService.LIST_COMMOM_POINT, query, option).pipe(map(data => new PaginationAdapter(LookupTableModel, data)));
  }

  searchDetail(query = {}, option?: IApiOption): Observable<PaginationAdapter<LookupTableDetailModel>> {
    return this.apiService.get(LookupTableApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(LookupTableDetailModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(LookupTableApiService.ROOT_POINT, { ids }, option);
  }
}
