import { OnInit } from '@angular/core';
import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, Renderer2, SimpleChanges } from '@angular/core';
import { IApiOption } from '../../api/interfaces';
import { MediaStorageService } from '../media-storage';

export interface ImageRender {
  element: ElementRef;
  renderer: Renderer2;
  attribute: string;
  render: (url: string) => void;
}

export abstract class ImageRenderModel implements ImageRender {
  element: ElementRef<any>;
  renderer: Renderer2;
  attribute!: string;

  constructor(el: ElementRef, renderer: Renderer2) {
    this.element = el;
    this.renderer = renderer;
  }

  abstract render(url: string): void;
}

export class HTMLImageRender extends ImageRenderModel {
  override attribute = 'src';

  override render(url: string): void {
    this.renderer.setAttribute(this.element.nativeElement, this.attribute, url);
  }
}

export class HTMLDivRender extends ImageRenderModel {
  override attribute = 'background-image';

  render(url: string): void {
    this.renderer.setStyle(this.element.nativeElement, this.attribute, `url(${url})`);
  }
}
@Directive({
  selector: '[redLazyImage]',
})
export class LazyImageDirective implements OnChanges, OnInit {
  @Input() redLazyImage: string | undefined;
  @Input() enableLoader = true;
  @Output() info: EventEmitter<any> = new EventEmitter<any>();
  @Input() urlDefault = 'assets/images/icons/picture.svg';
  private imageLoader: ImageRenderModel;

  @HostListener('error')
  onLoadImageError() {
    this.imageLoader.render(this.urlDefault);
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private storageApiService: MediaStorageService) {
    if (this.elementRef.nativeElement instanceof HTMLImageElement) {
      this.imageLoader = new HTMLImageRender(this.elementRef, this.renderer);
    } else {
      this.imageLoader = new HTMLDivRender(this.elementRef, this.renderer);
    }
  }
  ngOnInit(): void {
    this.imageLoader.render(this.urlDefault);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['redLazyImage'].currentValue && changes['redLazyImage'].currentValue !== changes['redLazyImage'].previousValue) {
      const requestOption: IApiOption = {
        loader: this.enableLoader,
      };
      // this.imageLoader.render(this.urlDefault);
      this.storageApiService.getPublicLink({ keyName: changes['redLazyImage'].currentValue }, requestOption).subscribe({
        next: (info: string) => {
          this.imageLoader.render(info);
          this.info.emit(info);
        },
        error: () => {
          this.imageLoader.render(this.urlDefault);
        },
      });
    }
  }
}
