import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { PAYMENT_REVERSAL_STATUS } from '../data';
import { EPaymentReversalStatus, InvoicePostingType } from '../enums';
import { IPaymentReversal, IPaymentReversalPosting, IPaymentReversalPostingDataSource, IPaymentReversalRecord, IPaymentReversalRecordMetadata } from '../interfaces';
import { PaymentReversalType, PaymentReversalRecordType } from '../types';
import { ChequeCompactModel } from './cheque-compact.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { LedgerAccountModel } from './ledger-account.model';
import { ProfitCentresModel } from './profit-centres.model';
import { DealValueModel } from './receipt.model';
import { UpdatedByModel } from './updated-by.model';

export class PaymentReversalModel extends BaseModel implements IPaymentReversal {
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  code!: string;

  @Expose()
  status!: EPaymentReversalStatus;

  @Expose()
  contactId!: number;

  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact?: ContactCustomerAndSupplierModel;

  @Expose()
  contactName!: string;

  @Expose()
  contactCode!: string;

  @Expose()
  reversalDate!: Date;

  @Expose()
  payerName!: string;

  @Expose()
  remark!: string;

  @Expose()
  total!: number;

  @Expose()
  bankAccountId?: number;

  @Expose()
  accountId?: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account?: LedgerAccountModel;

  @Expose()
  accountCode?: string;

  @Expose()
  chequeId?: number;

  @Expose()
  chequeNumber?: string;

  @Expose()
  @Type(() => ChequeCompactModel)
  cheque?: ChequeCompactModel;

  @Expose()
  paymentCode?: string;

  @Expose()
  receiptCode?: string;

  @Expose()
  sourceId!: number;

  @Expose()
  sourceDate?: Date;

  @Expose()
  type!: PaymentReversalType;

  @Expose()
  inClosedPeriod?: boolean;

  @Expose()
  createdAt!: Date;

  @Expose()
  updatedAt!: Date;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  @Transform(({ obj }) => {
    const invalidStatus = [EPaymentReversalStatus.Cancel].includes(obj.status);
    return !invalidStatus && !obj.inClosedPeriod;
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const invalidStatus = [EPaymentReversalStatus.Cancel].includes(obj.status);
    return !invalidStatus && !obj.inClosedPeriod;
  })
  canCancel!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const invalidStatus = false;
    return !invalidStatus && !obj.inClosedPeriod;
  })
  canDelete!: boolean;

  get displayStatus() {
    return PAYMENT_REVERSAL_STATUS[this.status];
  }
}

export class PaymentReversalPostingModel extends BaseModel implements IPaymentReversalPosting {
  @Expose()
  id!: number;

  @Expose()
  reversalId!: number;

  @Expose()
  accountId!: number;

  @Expose()
  accountCode!: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  paymentId?: number;

  @Expose()
  receiptId?: number;

  @Expose()
  description!: string;

  @Expose()
  credit!: number;

  @Expose()
  debit!: number;

  @Expose()
  amount!: number;

  @Expose()
  sequence?: number;

  @Expose()
  type?: `${InvoicePostingType}`;
}

export class PaymentReversalPostingDataSourceModel extends BaseModel implements IPaymentReversalPostingDataSource {
  @Expose()
  @Type(() => PaymentReversalPostingModel)
  postings!: PaymentReversalPostingModel[];

  @Expose()
  total!: number;
}

export class PaymentReversalRecordMetadata extends BaseModel implements IPaymentReversalRecordMetadata {
  @Expose()
  taxPercent!: number;

  @Expose()
  isEdit!: boolean;
}

export class PaymentReversalRecordModel extends BaseModel implements IPaymentReversalRecord {
  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  accountCode!: string;

  @Expose()
  amount!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  @Type(() => DealValueModel)
  debit!: DealValueModel;

  @Expose()
  @Type(() => DealValueModel)
  credit!: DealValueModel;

  @Expose()
  description!: string;

  @Expose()
  gstCategory!: string;

  @Expose()
  gstType!: 'gstExclusive' | 'gstInclusive';

  @Expose()
  id?: number;

  @Expose()
  index?: number;

  @Expose()
  isTaxable!: boolean;

  @Expose()
  @Type(() => PaymentReversalRecordMetadata)
  metadata!: PaymentReversalRecordMetadata;

  @Expose()
  remarks!: string;

  @Expose()
  sequence?: number;

  @Expose()
  taxAccountId?: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  taxAccount?: LedgerAccountModel;

  @Expose()
  taxDescription?: string;

  @Expose()
  taxAmount?: number;

  @Expose()
  taxUnit?: 'number' | 'percentage';

  @Expose()
  taxValue?: number;

  @Expose()
  profitCenterId?: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  @Transform(({ obj }) => {
    switch (obj?.type) {
      case 'VI':
        return obj?.supplierInvoiceDetail?.taxInvoiceId;
      case 'IV':
        return obj?.customerInvoiceId;
      case 'CN':
        return obj?.customerCreditNoteId ?? obj?.customerInvoiceId;
      case 'DN':
        return obj?.customerDebitNoteId;
      case 'SCN':
      case 'VCN':
        return obj?.supplierCreditNoteId ?? obj?.supplierInvoiceDetail?.taxInvoiceId;
      default:
        return null;
    }
  })
  sourceId!: number;

  @Expose()
  code?: string;

  @Expose()
  type!: string;

  @Expose()
  total!: number;

  @Expose()
  @Transform(({ obj }) => {
    const isTaxable = obj.isTaxable;
    const isGstValid = !['ZR', 'ES33', 'ESN33', 'OS'].includes(obj.gstCategory);
    const isGSTPercentValid = obj.metadata?.taxPercent && Number(obj.metadata?.taxPercent) !== 0;
    const isGSTValueValid =
      (obj.debit?.rateGroup?.tax && Number(obj.debit?.rateGroup?.tax) !== 0) || (obj.credit?.rateGroup?.tax && Number(obj.credit?.rateGroup?.tax) !== 0);
    return isTaxable && isGstValid && isGSTPercentValid && isGSTValueValid;
    // return obj.isTaxable;
  })
  canShowTaxRow!: boolean;
}

export class PaymentReversalDataSource extends BaseModel {
  @Expose()
  @Type(() => PaymentReversalRecordModel)
  items!: PaymentReversalRecordModel[];

  @Expose()
  @Type(() => PaymentReversalPostingModel)
  postings!: PaymentReversalPostingModel[];

  @Expose()
  total!: number;
}
