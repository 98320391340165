import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { RECEIPT_STATUS } from '../data';
import { EReceiptMemoType, EReceiptStatus } from '../enums';
import { IReceipt, IReceiptOffset, IReceiptPosting, IReceiptRecord } from '../interfaces';
import { IDealValue, IRateGroup } from '../interfaces/deal-value.interface';
import { ReceiptMode, ReceiptType } from '../types';
import {
  IReceiptMemo,
  IReceiptMemoContact,
  IReceiptMemoPostingAccount,
  IReceiptPostingDataSource,
  IReceiptRecordMetadata,
  IReceiptSupplierCreditNoteDetail,
} from './../interfaces/receipt.interface';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { DebitNoteModel } from './debit-note-enhanced.model';
import { LedgerAccountModel } from './ledger-account.model';
import { ProfitCentresModel } from './profit-centres.model';
import { TaxInvoiceModel } from './tax-invoice-enhanced.model';
import { UpdatedByModel } from './updated-by.model';
import { GstCategoryLookupModel } from './gst-category.model';
import { transform } from 'lodash';

export class ReceiptMemoContactModel extends BaseModel implements IReceiptMemoContact {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  receiveAccountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  receiveAccount!: Partial<LedgerAccountModel>;

  @Expose()
  paidAccountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  paidAccount!: Partial<LedgerAccountModel>;
}

export class ReceiptMemoPostingAccountModel extends BaseModel implements IReceiptMemoPostingAccount {
  @Expose()
  accountCode!: string;

  @Expose()
  accountId!: number;

  @Expose()
  accountName!: string;

  @Expose()
  @Transform(({ obj }) => {
    const normalized = `${obj.accountCode || ''} ${obj.accountName || ''}`;
    return normalized.trim();
  })
  normalizedAccountName!: string;
}

export class ReceiptMemoModel extends BaseModel implements IReceiptMemo {
  @Expose()
  id!: number;

  @Expose()
  code!: string;

  @Expose()
  invoiceDate!: string;

  @Expose()
  contactId!: number;

  @Expose()
  total!: number;

  @Expose()
  type!: 'IV' | 'DN';

  @Expose()
  paidAmount!: number;

  @Expose()
  balanceDue!: number;

  @Expose()
  // createdAt?: Date;
  createdAt?: string;

  @Expose()
  // updatedAt?: Date;
  updatedAt?: string;

  @Expose()
  // deletedAt?: Date;
  deletedAt?: string;

  @Expose()
  @Type(() => ReceiptMemoContactModel)
  contact!: ReceiptMemoContactModel;

  @Expose()
  @Type(() => ReceiptMemoPostingAccountModel)
  posting!: ReceiptMemoPostingAccountModel
}

export class ReceiptModel extends BaseModel implements IReceipt {
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  code!: string;

  @Expose()
  type!: ReceiptType;

  @Expose()
  mode!: ReceiptMode;

  @Expose()
  details?: any;

  @Expose()
  accountId!: number;

  @Expose()
  accountCode!: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  contactId!: number;

  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact!: ContactCustomerAndSupplierModel;

  @Expose()
  payerName!: string;

  @Expose()
  currency!: string;

  @Expose()
  reference!: string;

  @Expose()
  // receiptDate!: Date;
  receiptDate!: string;

  @Expose()
  totalAmount!: number;

  @Expose()
  templateId!: number;

  @Expose()
  inClosedPeriod!: boolean;

  // @Expose()
  // @Default(false)
  // inPaymentGenerator!: boolean;

  @Expose()
  @Default(false)
  inPaymentBilling!: boolean;

  @Expose()
  isBlocked?: boolean;

  @Expose()
  // @Type(() => Date)
  // createdAt!: Date;
  createdAt!: string;

  @Expose()
  // @Type(() => Date)
  // updatedAt!: Date;
  updatedAt!: string;

  @Expose()
  status!: EReceiptStatus;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  @Transform(({ obj }) => {
    const validStatus = [EReceiptStatus.confirmed].includes(obj.status);
    // return validStatus && !obj.inPaymentGenerator;
    return validStatus && !obj.inPaymentBilling && !obj.isBlocked;
  })
  canReverse!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const validStatus = [EReceiptStatus.confirmed].includes(obj.status);
    // return validStatus && !obj.inClosedPeriod && !obj.inPaymentBilling && !obj.isBlocked;
    return validStatus && !obj.inClosedPeriod;
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const validStatus = [EReceiptStatus.confirmed, EReceiptStatus.cancelled].includes(obj.status);
    // return validStatus && !obj.inClosedPeriod && !obj.inPaymentGenerator;
    return validStatus && !obj.inClosedPeriod && !obj.inPaymentBilling && !obj.isBlocked;
  })
  canDelete!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const validStatus = [EReceiptStatus.confirmed].includes(obj.status);
    // return validStatus && !obj.inClosedPeriod && !obj.inPaymentGenerator;
    return validStatus && !obj.inClosedPeriod && !obj.inPaymentBilling && !obj.isBlocked;
  })
  canCancel!: boolean;

  get displayStatus() {
    return RECEIPT_STATUS[this.status];
  }

  @Expose()
  @Transform(({ obj }) => {
    const invoiceIds = obj?.details ? obj?.details.map((_: any) => _?.customerInvoiceId) : []
    return invoiceIds;
  })
  invoiceIds!: number[];
}

export class RateGroupModel extends BaseModel implements IRateGroup {
  @Expose()
  tax!: number;
}

export class DealValueModel extends BaseModel implements IDealValue {
  @Expose()
  subAmount!: number;

  @Expose()
  amount!: number;

  @Expose()
  subTotalAmount!: number;

  @Expose()
  totalAmount!: number;

  @Expose()
  @Type(() => RateGroupModel)
  rateGroup!: RateGroupModel;
}

export class ReceiptRecordMetadataModel extends BaseModel implements IReceiptRecordMetadata {
  @Expose()
  taxPercent!: number;

  @Expose()
  isEdit!: boolean;
}

export class ReceiptRecordModel extends BaseModel implements IReceiptRecord {
  @Expose()
  id!: number;

  @Expose()
  type!: string;

  @Expose()
  postingDescription!: string;

  @Expose()
  receiptId?: number;

  @Expose()
  @Type(() => ReceiptModel)
  receipt?: ReceiptModel;

  @Expose()
  accountId!: number;

  @Expose()
  accountCode!: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  invoiceId!: number;

  @Expose()
  invoiceNumber?: string;

  @Expose()
  invoiceDate?: string;

  @Expose()
  @Type(() => TaxInvoiceModel)
  invoice!: TaxInvoiceModel;

  @Expose()
  @Default(1)
  invoiceRate!: number;

  @Expose()
  invoiceBalance!: number;

  @Expose()
  receiptMemoId?: number;

  @Expose()
  receiptMemoType?: `${EReceiptMemoType}`;

  @Expose()
  @Type(() => TaxInvoiceModel)
  customerInvoice?: TaxInvoiceModel;

  @Expose()
  @Type(() => DebitNoteModel)
  customerDebitNote?: DebitNoteModel;

  // @Expose()
  // @Type(() => CreditNoteModel)
  // supplierCreditNote?: CreditNoteModel;

  @Expose()
  supplierCreditNoteDetail?: IReceiptSupplierCreditNoteDetail;

  @Expose()
  supplierCreditNoteDetailId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => DealValueModel)
  debit!: DealValueModel;

  @Expose()
  @Type(() => DealValueModel)
  credit!: DealValueModel;

  @Expose()
  isAdvance?: boolean;

  @Expose()
  isTaxable?: boolean;

  @Expose()
  isDefault?: boolean; // For UI purpose

  @Expose()
  taxAccountId?: number;

  @Expose()
  taxAccountCode?: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  taxAccount?: LedgerAccountModel;

  @Expose()
  taxDescription?: string;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => GstCategoryLookupModel)
  gstCategoryLookup?: GstCategoryLookupModel;

  @Expose()
  @Transform(({ obj }) => obj.gstCharged ?? Number(obj.gstCategoryLookup?.gst_charged))
  @Type(() => Number)
  gstCharged?: number;

  @Expose()
  gstType!: 'gstExclusive' | 'gstInclusive';

  @Expose()
  taxUnit?: 'number' | 'percentage';

  @Expose()
  taxValue?: number;

  @Expose()
  amount!: number;

  @Expose()
  taxAmount?: number;

  @Expose()
  @Type(() => ReceiptRecordMetadataModel)
  metadata?: ReceiptRecordMetadataModel;

  @Expose()
  sequence!: number;

  @Expose()
  index?: number;

  @Expose()
  // @Type(() => Date)
  // createdAt!: Date;
  createdAt!: string;

  @Expose()
  // @Type(() => Date)
  // updatedAt!: Date;
  updatedAt!: string;

  @Expose()
  @Transform(({ obj }) => obj.supplierCreditNoteDetail?.taxInvoiceId ?? obj.receiptMemoId)
  sourceId?: number;

  @Expose()
  @Transform(({ obj }) => {
    const debitAmount = obj.debit?.subTotalAmount ?? 0;
    const creditAmount = obj.credit?.subTotalAmount ?? 0;

    if (obj.type === 'CN') {
      return -debitAmount;
    }
    return creditAmount > 0 ? creditAmount : -debitAmount;
  })
  displayAmount!: number;

  @Expose()
  @Transform(({ obj }) => {
    if (obj.recordMode) {
      return obj.recordMode;
    }

    return obj.type === 'CN' ? 'debit' : 'credit';
  })
  recordMode!: 'credit' | 'debit';

  @Expose()
  @Transform(({ obj }) => {
    const isTaxable = obj.isTaxable;
    const isGstValid = !['ZR', 'ES33', 'ESN33', 'OS'].includes(obj.gstCategory);
    const isGSTPercentValid = obj.metadata?.taxPercent && Number(obj.metadata?.taxPercent) !== 0;
    const isGSTValueValid =
      (obj.debit?.rateGroup?.tax && Number(obj.debit?.rateGroup?.tax) !== 0) || (obj.credit?.rateGroup?.tax && Number(obj.credit?.rateGroup?.tax) !== 0);
    return isTaxable && isGstValid && isGSTPercentValid && isGSTValueValid;
    // return obj.isTaxable;
  })
  canShowTaxRow!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return ['SAL', 'IV', 'DN', 'VCN', '', 'CN'].includes(obj.type);
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return ['SAL', 'IV', 'DN', 'VCN', '', 'CN'].includes(obj.type);
  })
  canDelete!: boolean;
}

export class ReceiptPostingModel extends BaseModel implements IReceiptPosting {
  @Expose()
  id!: number;

  @Expose()
  accountId!: number;

  @Expose()
  @Transform((oject) => {
    const accountCode = oject?.obj?.account?.code
    return accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  amount!: number;

  @Expose()
  credit!: number;

  @Expose()
  debit!: number;

  @Expose()
  description!: string;

  @Expose()
  profitCenterId!: number;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter!: ProfitCentresModel;

  @Expose()
  sequence!: number;

  @Expose()
  taxUnit?: 'number' | 'percentage';

  @Expose()
  taxValue?: number;

  @Expose()
  taxDescription!: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  taxAccount?: LedgerAccountModel;

  @Expose()
  taxAccountId?: number;

  @Expose()
  isTaxable?: boolean;
}

export class ReceiptPostingDataSourceModel extends BaseModel implements IReceiptPostingDataSource {
  @Expose()
  @Type(() => ReceiptPostingModel)
  postings!: ReceiptPostingModel[];

  @Expose()
  total!: number;
}

export class ReceiptDataSource extends BaseModel {
  @Expose()
  @Type(() => ReceiptRecordModel)
  items!: ReceiptRecordModel[];

  @Expose()
  @Type(() => ReceiptPostingModel)
  postings!: ReceiptPostingModel[];

  @Expose()
  total!: number;
}

export class ReceiptOffsetModel extends BaseModel implements IReceiptOffset {
  @Expose()
  type!: string;

  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  ref!: string;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  // date!: Date;
  date!: string;
}
