import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TelephoneNumberPipe } from './telephone-number.pipe';



@NgModule({
  declarations: [
    TelephoneNumberPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TelephoneNumberPipe
  ]
})
export class TelephoneNumberModule { }
