import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';

export class UserPermissionCreateDto extends BaseModel {
  @Expose()
  group!: string;

  @Expose()
  feature!: string;

  @Expose()
  module!: string;

  @Expose()
  sequence!: number;

  @Expose()
  scope!: string;

  @Expose()
  description!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  isHidden!: boolean;
}

export class UserPermissionUpdateDto extends BaseModel {
  @Expose()
  group!: string;

  @Expose()
  feature!: string;

  @Expose()
  module!: string;

  @Expose()
  sequence!: number;

  @Expose()
  scope!: string;

  @Expose()
  description!: string;

  @Expose()
  isActive!: boolean;

  @Expose()
  isHidden!: boolean;
}
