export type IExceljsVerticalAligmentStyle = 'top' | 'middle' | 'bottom';
export type IExceljsHorizontalAligmentStyle = 'left' | 'center' | 'right';
export enum ExceljsValueType {
  NULL = 0,
  MERGE = 1,
  NUMBER = 2,
  STRING = 3,
  DATE = 4,
  HYPERLINK = 5,
  FORMULA = 6,
  SHARED_STRING = 7,
  RICHTEXT = 8,
  BOOLEAN = 9,
  ERROR = 10,
}
export interface IExceljsHorizontalColorConfig {
  argb: string; // hex color ex: fdf2ea
}
export interface IExceljsStyle {
  alignment?: {
    vertical: IExceljsVerticalAligmentStyle;
    horizontal: IExceljsHorizontalAligmentStyle;
    wrapText: boolean;
  };
  fill?: {
    type: string;
    pattern: 'solid';
    fgColor: IExceljsHorizontalColorConfig;
  };
  border?: {
    top?: {
      style: 'thin';
      color?: IExceljsHorizontalColorConfig;
    };
    left?: {
      style: 'thin';
      color?: IExceljsHorizontalColorConfig;
    };
    bottom?: {
      style: 'thin';
      color?: IExceljsHorizontalColorConfig;
    };
    right?: {
      style: 'thin';
      color?: IExceljsHorizontalColorConfig;
    };
  };
  numFmt: string;
}
export interface IExceljsCellAttrs {
  colspan: number;
  rowspan: number;
}
export interface IExceljsCell {
  address: string; // ex: B1
  type: ExceljsValueType;
  value?: string;
  master?: string; // ex: A1
  style: IExceljsStyle;
  formula?: string;
  result?: any;
  text?: string;
  hyperlink?: string;
}

export interface IExceljsNormalizedCell extends IExceljsCell {
  attrs: IExceljsCellAttrs;
}
export interface IExceljsRow {
  cells: IExceljsNormalizedCell[];
  /**
   * row index
   */
  number: number;
  /**
   * cell index start
   */
  min: number;
  /**
   * cell index end
   */
  max: number;
  style: IExceljsStyle;
  hidden: boolean;
  collapsed: boolean;
}
export interface IExceljsCol {
  width: number;
  /**
   * column index start
   */
  min: number;
  /**
   * column index end
   */
  max: number;
  style: IExceljsStyle;
  hidden: boolean;
  collapsed: boolean;
}
export interface IExceljsWorkSheet {
  id: number;
  name: string;
  properties: {
    defaultRowHeight: number;
    outlineLevelCol: number;
    outlineLevelRow: number;
    defaultColWidth: number;
  };
  rows: IExceljsRow[];
  cols?: IExceljsCol[];
  merges: string[]; // ex: ["A1:E1", "A5:B5"]
}
export interface IExceljsWorkBookMeta {
  limit: number;
  page: number;
  pageCount: number;
  total: number;
  pageSizeOptions: number[];
}
export interface IExceljsWorkBook {
  name: string;
  worksheets: IExceljsWorkSheet[];
}
export type ExecljsParameterValueType = 'string' | 'number' | 'boolean' | 'arrayString' | 'arrayNumber' | 'arrayBoolean' | 'object';
export type IExceljsParameterControlType =
  | 'text'
  | 'selection'
  | 'selection/dialog'
  | 'external-selection'
  | 'date/single'
  | 'date/range'
  | 'month/single'
  | 'checkbox'
  | '';
export interface IExceljsParameter {
  type: IExceljsParameterControlType;
  name: string;
  slug: string;
  options: {
    value: string | number | boolean;
    label: string;
  }[];
  properties: Record<string, IExceljsParameter> | undefined;
  default: unknown;
  valueType: ExecljsParameterValueType;
  templateRef: string;
}
// sales/detail/:id
// sales?key=asc
export interface ExceljsParameterVisualizationMatrixItem {
  slug: string;
  hide?: boolean;
}
export type ExceljsParameterTemplateRefOnChangeObject = { value: string; label: string };
export type ExceljsParameterTemplateRefOnChange = string | ExceljsParameterTemplateRefOnChangeObject;
export interface ExceljsParameterVisualizationMatrixItemDataInput {
  slug: string;
  hide?: string;
}
export interface ExceljsParameterApiTemplateRef {
  method: 'get' | 'post'; // get | post | delete | put
  endpoint: string;
  query: IExceljsParameter[];
}
export interface ExceljsParameterTemplateRef {
  slug: string;
  api: string | ExceljsParameterApiTemplateRef;
  on: {
    change: ExceljsParameterTemplateRefOnChange;
    // using '#' to listen event from another, suchas #agentDialog.on.submit
    // or return template String, such as '{{bussinesName}} ({{nricName}})'
    load: string; // api to load data
  };
  columns: {
    columnRef: string;
    header: string;
    cell: string; // {{ceaRedNo}}
  }[];
}
export type ExceljsParameterVisualizationMatrixItemInput = string | ExceljsParameterVisualizationMatrixItemDataInput;
export type ExceljsParameterVisualizationMatrixInput = Array<ExceljsParameterVisualizationMatrixItemInput | Array<ExceljsParameterVisualizationMatrixItemInput>>;
export type ExceljsParameterVisualizationMatrix = Array<Array<ExceljsParameterVisualizationMatrixItem>>;
export interface IExceljsParameterVisualization {
  matrices: ExceljsParameterVisualizationMatrixInput;
  templates: {
    [x in string]: ExceljsParameterTemplateRef;
    /**
     * agent: {
     *  slug: 'agent',
     *  api: 'sales/detail/:value'
     *  on: {
     *    change: '#agentDialog.on.change'
     *  }
     * },
     * agentDialog:{
     *  slug: 'agentDialog',
     *  api:'sales' => {id:basb,code:ABBC, name:'Dat}[]
     *  columRef:{ { columnDef: string; header: string; cell: string }[]
     *  on:{
     *    change: {value:'{{code}}',label:'ABC {{name}} ({{code}})'}
     *  }
     * }
     */
  };
  hasPdfFile?: boolean;
}
export interface IExceljsParameterLayoutConditionData {
  const: string | number | symbol;
  pattern: string;
}
export interface IExceljsParameterLayoutCondition {
  properties: {
    [x in string]: IExceljsParameterLayoutConditionData;
  };
}
export interface IExceljsParameterLayout {
  name: string;
  slug: string;
  index: number;
  properties: {
    [x in string]: IExceljsParameterLayout;
  };
  if: IExceljsParameterLayoutCondition;
  then: IExceljsParameterLayoutCondition;
  else: IExceljsParameterLayoutCondition;
}
export interface IExceljsCardConfig {
  paging: boolean;
  openPanel: boolean;
  parameters?: {
    page: string;
    limit: string;
  };
}
export interface IExceljsFileSupportedConfig {
  type: string;
  stream: boolean
}
export interface IExceljsCard {
  name: string;
  code: string;
  parameters: IExceljsParameter[];
  parameterVisualization: IExceljsParameterVisualization;
  fileSupported: IExceljsFileSupportedConfig[];
  configs: IExceljsCardConfig;
  version: string
}
