<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img class="w-10 h-10 object-cover rounded-full" *ngIf="showAvatar && user?.avatar" [redLazyImage]="user?.avatar" alt="Contact avatar" />
    <mat-icon class="text-theme-gray" *ngIf="!showAvatar || !user?.avatar" svgIcon="user_circle"></mat-icon>
    <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full bg-green-500" [ngClass]="{ 'mr-px mb-px': !showAvatar || !user?.avatar }"></span>
  </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item>
    <span class="flex flex-col leading-none">
      <span>Signed in as</span>
      <span class="mt-1.5 text-md font-medium truncate" [matTooltip]="user?.name ?? ''">{{ user?.name }}</span>
    </span>
  </button>
  <!-- <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
        <mat-icon svgIcon="user_circle"></mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item>
        <mat-icon svgIcon="cog"></mat-icon>
        <span>Settings</span>
    </button> -->
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon svgIcon="logout"></mat-icon>
    <span>Sign out</span>
  </button>
</mat-menu>
