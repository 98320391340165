import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedAppVersionComponent } from './app-version.component';



@NgModule({
  declarations: [
    RedAppVersionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    RedAppVersionComponent
  ]
})
export class RedAppVersionModule { }
