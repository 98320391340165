import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeSourceHtmlPipe } from './safe-source-html.pipe';

@NgModule({
  declarations: [SafeSourceHtmlPipe],
  imports: [CommonModule],
  exports: [SafeSourceHtmlPipe],
})
export class SafeSourceHtmlModule {}
