<div class="app-upload-file">
  <div
    class="upload-file-container"
    [style.min-height]="containerHeight"
    redFileDrop
    (fileOver)="fileOver($event)"
    (fileDrop)="fileDrop($event)"
    [ngClass]="{ 'file-over': isFileOver, hidden: !isShowUploadBox }"
  >
    <div class="relative flex flex-col justify-center items-center text-theme-green">
      <div class="icon-wrapper">
        <mat-icon class="text-theme-gray icon-size-12">cloud_upload</mat-icon>
      </div>
      <div class="title">Drag & Drop</div>
      <div class="description">
        {{ message }} <span *ngIf="isMultiple">Maximum {{ maxFiles }} file(s)</span>
      </div>
      <div class="description">or <a class="browse-btn" (click)="openSelectBox()"> Browse... </a></div>
    </div>
    <input
      class="input-file"
      type="file"
      #input
      [accept]="allowedMimeType"
      [multiple]="isMultiple"
      redUpload
      [uploadToFolder]="uploadToFolder"
      [canUploadFn]="canUploadFn"
      [disabled]="disabled"
      [isUploadImmediately]="isUploadImmediately"
      [maxFiles]="maxFiles"
      [maxFileSize]="maxFileSize"
      [allowedMimeType]="allowedMimeType"
      [isReplaced]="isReplaced"
      [existFileKeys]="existFileKeys"
      (all)="onAllFiles($event)"
      (added)="onAdded($event)"
      (removed)="onRemoved($event)"
      (lastUploaded)="onLastUpdated($event)"
      (begin)="onBegin()"
      (finished)="onFinished()"
      (fullSlot)="onFullSlot()"
      (beginFile)="onBeginFile($event)"
      (finishedFile)="onFinishedFile($event)"
      [allowExtensions]="allowExtensions"
      [showFileError]="showFileError"
      [validateFileName]="validateFileName"
    />
  </div>
  <ng-container *ngIf="preview && allFiles.length">
    <div class="remove-all-file">
      <button class="upload-btn" *ngIf="!isUploadImmediately" [disabled]="!isAvailableUploadBtn" mat-flat-button color="primary" (click)="upload()">
        Upload all
      </button>
      <button mat-button (click)="clear()" *ngIf="!isHiddenButtonClearAll">Clear all</button>
    </div>
    <div class="preview-container">
      <div class="img-preview-container" *ngFor="let fileUploader of allFiles">
        <div class="preview">
          <div class="info-preview">
            <div class="img-preview">
              <!--              <img *ngIf="fileUploader.isExistFile" cmsLoadImage [key]="fileUploader.key" (info)="loadImgInfo(fileUploader, $event)" />-->
              <img *ngIf="!fileUploader.isExistFile && fileUploader.isPreview && fileUploader.data" [src]="fileUploader.data" />
              <mat-icon *ngIf="!fileUploader.isExistFile && !fileUploader.isPreview">insert_drive_file</mat-icon>
            </div>
            <div class="img-info">
              <div class="img-info__item img-name">{{ fileUploader.name }}</div>
              <mat-progress-bar class="img-info__item pending" *ngIf="fileUploader.uploading" mode="indeterminate"></mat-progress-bar>
              <mat-progress-bar class="img-info__item danger" *ngIf="fileUploader.error.status" mode="determinate" value="5"></mat-progress-bar>
              <mat-progress-bar class="img-info__item success" *ngIf="fileUploader.completed" mode="determinate" value="100"></mat-progress-bar>
              <div class="img-info__item img-info__group">
                <span class="img-size"> {{ fileUploader.file.size | fileSize }} </span>
                <span class="img-status" [ngClass]="{ success: fileUploader.completed, pending: fileUploader.uploading, danger: fileUploader.error.status }">
                  {{ fileUploader.completed ? 'Done' : '' }} {{ fileUploader.uploading ? 'Uploading' : '' }} {{ fileUploader.error.status ? 'Error' : '' }}
                </span>
              </div>
            </div>
          </div>
          <div class="remove-img-btn" (click)="deleteFile(fileUploader.id)">
            <mat-icon>delete_forever</mat-icon>
          </div>
        </div>
        <div *ngIf="fileUploader.error">
          <span class="error-message">{{ fileUploader.error.message }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
