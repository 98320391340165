import { BaseModel, Default, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { ETaxInvoiceTemplate } from '../enums';

export class PreviewTaxInvoiceTemplateDetailDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  itemCode?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  contractDate?: string;

  @Expose()
  clientType?: string;

  @Expose()
  propertyType?: string;

  @Expose()
  externalCobroke?: string;

  @Expose()
  internalCobroke?: string;

  @Expose()
  unitPriceStr?: string;

  @Expose()
  leaseTermEnd?: string;

  @Expose()
  leaseTermStart?: string;

  @Expose()
  @Type(() => Number)
  gstValue?: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  discount?: number;

  @Expose()
  @Type(() => Number)
  amount?: number;

  @Expose()
  @Type(() => Boolean)
  gstInclusive?: boolean;
}

export class PreviewTaxInvoiceTemplateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  templateId!: number;

  @Expose()
  templateCode!: string;

  @Expose()
  type!: string;

  @Expose()
  templateType!: string;

  @Expose()
  @Type(() => Number)
  invoiceId!: number;

  @Expose()
  @Type(() => Number)
  sourceId!: number;

  @Expose()
  customerName?: string;

  @Expose()
  billingAddress?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDate?: string;

  @Expose()
  invoiceNumber?: string;

  @Expose()
  invoiceCode?: string;

  @Expose()
  country?: string;

  @Expose()
  @Type(() => Number)
  creditTerm?: number;

  @Expose()
  reference?: string;

  @Expose()
  @Type(() => Number)
  gstPercent?: number;

  @Expose()
  prepareBy?: string;

  @Expose()
  currency?: string;

  @Expose()
  contactPerson?: string;

  @Expose()
  contactCode?: string;

  @Expose()
  contactZipCode?: string;

  @Expose()
  transId?: string;

  @Expose()
  @Type(() => Number)
  @Type(() => PreviewTaxInvoiceTemplateDetailDto)
  details?: PreviewTaxInvoiceTemplateDetailDto[];

  @Expose()
  @Type(() => Number)
  companyId?: number;

  @Expose()
  @Type(() => Number)
  businessUnitId?: number;
}

export class PreviewProformaTemplateDto extends BaseModel {
  @Expose()
  businessUnitId!: number;

  @Expose()
  address!: string;

  @Expose()
  addressLine1!: string;

  @Expose()
  addressLine2!: string;

  @Expose()
  addressLine3!: string;

  @Expose()
  templateCode!: ETaxInvoiceTemplate;

  @Expose()
  customerName!: string;

  @Expose()
  batchName!: string;

  @Expose()
  batchComment!: string;

  @Expose()
  proformaCode!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  invoiceDate!: string;

  @Expose()
  country!: string;

  @Expose()
  house!: string;

  @Expose()
  street!: string;

  @Expose()
  floor!: string;

  @Expose()
  unit!: string;

  @Expose()
  buildingName!: string;

  @Expose()
  postal!: string;

  @Expose()
  symbol!: string;

  @Expose()
  currency!: string;

  @Expose()
  @Type(() => Number)
  totalAmount!: number;
}
