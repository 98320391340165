// core
import { Expose, Type } from 'class-transformer';

// share
import { PersonalParticularModel } from './personal-particular.model';
import { BaseModel } from '@red/data-access';
import { EIr8aAmountStatus, EPaymentType } from '../enums';
import { IIr8aAmount } from '../interfaces';

export class Ir8aAmountModel extends BaseModel implements IIr8aAmount {
  @Expose()
  id!: number;

  @Expose()
  amount!: number;

  @Expose()
  totalAmount!: number;

  @Expose()
  description!: string;

  @Expose()
  effectiveDate!: string;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson!: PersonalParticularModel;

  @Expose()
  status!: EIr8aAmountStatus;

  @Expose()
  paymentType!: EPaymentType;

  @Expose()
  companyCode!: string;

  @Expose()
  companyId!: number;

  get canDelete(): boolean {
    return this.paymentType === EPaymentType.manualFee;
  }

  get canEdit(): boolean {
    return this.paymentType === EPaymentType.manualFee;
  }

  //#region timestamps
  @Expose()
  createdAt!: string;
  @Expose()
  updatedAt!: string;
  @Expose()
  deletedAt?: string;
  @Expose()
  createdBy?: string;
  @Expose()
  updatedBy?: string;
  @Expose()
  deletedBy?: string;
  //#endregion
}

export class Ir8aAmountModelWithHover extends Ir8aAmountModel {
  @Expose()
  isHover?: boolean;
}
