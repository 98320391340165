import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, iif, mapTo, of, switchMap } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ResaleTransactionAction } from '../actions';
import { ResaleTransactionSelector } from '../selectors';
import { ResaleTransactionApiService } from '@cms/apis';
import { ResaleBillingModel, ResaleCoBrokeModel, ResaleExternalCoBrokeModel, ResaleRelatedAgentModel, ResaleTransactionModel } from '@shared/data-access/models';
import { IResaleTransaction } from '@shared/data-access/interfaces';
import { EBlankFormStatus, ECommType, EGstType, EResaleStatus } from '@shared/data-access/enums';
import { ResaleTransactionPreviewDto } from '@shared/data-access/dto';
import { omit } from 'lodash-es';
import * as moment from 'moment';
import { RESALE_TYPE_OPTIONS } from '@shared/data-access/data';

@Injectable()
export class ResaleTransactionEffect {
  loadItemDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.loadItemDetail),
      mergeMap(({ id }) =>
        this.apiService.get(id).pipe(
          switchMap(item => {
            const { status } = item;
            // const isHaveRejectReason = [EResaleStatus.rework, EResaleStatus.rework2, EResaleStatus.rejected].includes(status);
            const isHaveRejectReason = [EResaleStatus.sendBack, EResaleStatus.rework, EResaleStatus.rejected].includes(status);
            return forkJoin([
              of(item),
              // this.apiService.getBillingsByResaleId(id),
              this.apiService.searchDocuments({ resaleIds: id }),
              isHaveRejectReason
                ? this.apiService.searchStatusHistories({
                  orderBy: 'createdAt',
                  resaleIds: id,
                })
                : of(null),
            ]);
          }),
          switchMap(([item, documentsPaginated, statusHistoriesPaginated]) => {
            item = ResaleTransactionModel.merge(item, {
              rejectReason: statusHistoriesPaginated?.results[0]?.data?.reason ?? null,
              billing: item.billing,
              documents: documentsPaginated.results,
              additionals: documentsPaginated.data,
            } as Partial<IResaleTransaction>);
            return of(ResaleTransactionAction.setItemDetail({ item }));
          })
        )
      )
    )
  );

  saveDratItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.saveDraftItem),
      concatLatestFrom(() => this.store.select(ResaleTransactionSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType }]) =>
        iif(
          () => draftType === 'create' || draftType === 'clone',
          this.apiService.createFullData(draftItem, draftType === 'clone'),
          this.apiService.updateFullData(draftItem)
        ).pipe(
          switchMap(res => of(ResaleTransactionAction.submitItemSuccess({ res }))),
          catchError(error => of(ResaleTransactionAction.submitItemFail({ error })))
        )
      )
    )
  );

  saveAndSubmitDraftItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.saveAndSubmitDraftItem),
      concatLatestFrom(() => this.store.select(ResaleTransactionSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType }]) =>
        iif(
          () => draftType === 'create' || draftType === 'clone',
          this.apiService.createFullData(draftItem, draftType === 'clone'),
          this.apiService.updateSubmitFullData(draftItem)
        ).pipe(
          switchMap(id => {
            return this.apiService.submit({ id, data: {}, metadata: { submitLateReason: draftItem.metadata?.submitLateReason } ?? {} }).pipe(
              mapTo({
                id,
                status: EBlankFormStatus.submitted,
              })
            );
          }),
          switchMap(res => of(ResaleTransactionAction.submitItemSuccess({ res }))),
          catchError(error => of(ResaleTransactionAction.submitItemFail({ error })))
        )
      )
    )
  );

  saveSpecialFields$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.saveSpecialFields),
      concatLatestFrom(() => this.store.select(ResaleTransactionSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType }]) =>
        this.apiService.updateSaveSpecialFields(draftItem).pipe(
          switchMap(res => of(ResaleTransactionAction.submitItemSuccess({ res }))),
          catchError(error => of(ResaleTransactionAction.submitItemFail({ error })))
        )
      )
    )
  );

  loadGrossCommmPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.loadGrossCommPreview),
      concatLatestFrom(() => this.store.select(ResaleTransactionSelector.selectDraftItem)),
      mergeMap(([_, draftItem]) => {
        const dto = ResaleTransactionPreviewDto.fromJson(draftItem);
        return this.apiService.preview(dto).pipe(
          switchMap(res => {
            let { externals, mains, internals } = res.relatedAgent;
            mains = mains.map((item, index) => ResaleCoBrokeModel.merge(draftItem.relatedAgent.mains[index], omit(item, ['agentId', 'agent'])));
            internals = internals.map((item, index) => ResaleCoBrokeModel.merge(draftItem.relatedAgent.internals[index], omit(item, ['agentId', 'agent'])));
            externals = externals.map((item, index) =>
              ResaleExternalCoBrokeModel.merge(
                draftItem.relatedAgent.externals[index],
                omit(
                  {
                    ...item,
                    receivedValue: item.commType === ECommType.value && item.gstType === EGstType.gstInclusive ? item.grossComm?.totalAmount : item.receivedValue,
                  },
                  ['agentId', 'agent']
                )
              )
            );

            return of(
              ResaleTransactionAction.updateBillingDraftItem({ data: res.billing }),
              ResaleTransactionAction.updateDraftItem({
                data: {
                  relatedAgent: ResaleRelatedAgentModel.fromJson({
                    mains,
                    internals,
                    externals,
                  }),
                },
              })
            );
          })
        );
      })
    )
  );

  submitDratItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.submitDraftItem),
      concatLatestFrom(() => this.store.select(ResaleTransactionSelector.selectDraftState)),
      mergeMap(([_, { draftItem }]) => {
        const relatedAgent = { mains: draftItem?.relatedAgent?.mains ?? [], internals: draftItem?.relatedAgent?.internals ?? [] };
        return this.apiService.submit({ id: draftItem.id, data: {}, metadata: { submitLateReason: draftItem.metadata?.submitLateReason } ?? {}, relatedAgent }).pipe(
          switchMap(res => of(ResaleTransactionAction.submitItemSuccess({ res }))),
          catchError(error => of(ResaleTransactionAction.submitItemFail({ error })))
        );
      })
    )
  );

  // saveRemark$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ResaleTransactionAction.saveRemark),
  //     mergeMap(({ id, remark }) => {
  //       return this.apiService.updateRemark(id, remark).pipe()
  //     })
  //   )
  // )

  loadMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResaleTransactionAction.loadMetadata),
      mergeMap(({ id }) =>
        this.apiService.get(id).pipe(
          switchMap(item => {
            return of(ResaleTransactionAction.setMetadata({ metadata: item?.metadata ?? {} }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store, private apiService: ResaleTransactionApiService) { }
}
