import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { UserPermissionCreateDto, UserPermissionUpdateDto } from '@shared/data-access/dto';
import { IUserRole } from '@shared/data-access/interfaces';
import { PermissionModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';
import { IPermission, Permission } from '@shared/permission';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionApiService {
  static ROOT_POINT = 'settings/permissions';
  static PERMISSION_POINT = 'settings/permissions';

  constructor(private apiService: ApiService) { }

  create(data: UserPermissionCreateDto): Observable<PermissionModel> {
    return this.apiService.post(`${UserPermissionApiService.ROOT_POINT}`, data).pipe(map((res: IUserRole) => PermissionModel.fromJson(res) as PermissionModel));
  }
  get(itemId: number): Observable<PermissionModel> {
    return this.apiService.get(UserPermissionApiService.ROOT_POINT + '/' + itemId).pipe(map(res => PermissionModel.fromJson(res) as PermissionModel));
  }
  update(id: number, data: UserPermissionUpdateDto): Observable<any> {
    return this.apiService.patch(UserPermissionApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<PermissionModel>> {
    return this.apiService.get(UserPermissionApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(PermissionModel, data)));
  }

  delete(id: number, option?: IApiOption): Observable<any> {
    return this.apiService.delete(UserPermissionApiService.ROOT_POINT + '/' + id, option);
  }

  getAllPermission(query = {}, option?: IApiOption): Observable<PaginationAdapter<Permission>> {
    return this.apiService.get(UserPermissionApiService.PERMISSION_POINT, query, option).pipe(map(data => new PaginationAdapter(Permission, data)));
  }

  getPermissionsByRoleId(id: number, query = {}, option?: IApiOption): Observable<Permission[]> {
    return this.apiService
      .get(UserPermissionApiService.ROOT_POINT + '/' + id + '/permissions', query, option)
      .pipe(map((data: IPermission[]) => data.map(item => Permission.fromJson(item))));
  }

  assignPermissions(id: number, permissionIds: number[]): Observable<any> {
    return this.apiService.patch(UserPermissionApiService.ROOT_POINT + '/' + id + '/permissions', { permissionIds }, { excludeFields: [''] });
  }
}
