import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select'
import { NgxPaginationModule, PaginatePipe } from 'ngx-pagination';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';



@NgModule({
  declarations: [
    PaginationComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  exports: [
    PaginationComponent,
    PaginatePipe
  ]
})
export class RedPaginationModule { }
