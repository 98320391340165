import { Default } from '@red/data-access';
import { IAddressSplitter } from '@shared/components/address-splitter';
import { Expose, Transform, Type } from 'class-transformer';
import { ContactAddressType } from '../enums';
import { IContactAddress } from '../interfaces';
import { BaseFixedModel } from './base.model';

export class ContactAddressModel extends BaseFixedModel implements IContactAddress {
  @Expose()
  @Transform(({ obj }) => {
    const { postcode, country, house, street, buildingName, floor, unit } = obj;
    if (floor && unit) {
      return (
        [`${house ? house : ''}${house && street ? ' ' : ''}${street ? street : ''}`, `#${floor}-${unit}${buildingName ? ' ' + buildingName : ''}`, `${country ? country : ''}${country && postcode ? ' ' : ''}${postcode ? postcode : ''}`]
          .filter(str => !!str)
          .join(', ') || ''
      );
    }
    return [`${house ? house : ''}${house && street ? ' ' : ''}${street ? street : ''}`, `${buildingName ? buildingName : ''}`, `${country ? country : ''}${country && postcode ? ' ' : ''}${postcode ? postcode : ''}`].filter(str => !!str).join(', ') || '';

  })
  address?: string;

  @Expose()
  buildingName?: string;

  @Expose()
  country!: string;

  @Expose()
  default?: boolean;

  @Expose()
  district?: string;

  @Expose()
  floor?: string;

  @Expose()
  house?: string;

  @Expose()
  postcode!: string;

  @Expose()
  @Default(ContactAddressType.BillingAddress)
  type!: ContactAddressType.BillingAddress;

  @Expose()
  street?: string;

  @Expose()
  unit?: string;

  @Expose()
  @Type(() => Number)
  contactId!: number;

  @Expose()
  @Type(() => Number)
  index?: number;

  @Expose()
  @Transform(({ obj }) => {
    const { postcode, country, house } = obj;
    if (postcode && country?.toUpperCase() === 'SINGAPORE' && house?.length > 0) {
      return true;
    }

    return false;
  })
  addressCombined!: boolean;

  get getBillingAddressDetail(): string {
    if (this.floor && this.unit) {
      return (
        [`${this.house ? this.house : ''}${this.house && this.street ? ' ' : ''}${this.street ? this.street : ''}`, `#${this.floor}-${this.unit}${this.buildingName ? ' ' + this.buildingName : ''}`, `${this.country ? this.country : ''}${this.country && this.postcode ? ' ' : ''}${this.postcode ? this.postcode : ''}`]
          .filter(str => !!str)
          .join(', ') || ''
      );
    }
    return [this.address, `${this.country ? this.country : ''}${this.country && this.postcode ? ' ' : ''}${this.postcode ? this.postcode : ''}`].filter(str => !!str).join(', ') || '';
  }

  get addressSplitterObject(): IAddressSplitter {
    if (this.country?.toUpperCase() !== 'SINGAPORE') {
      return {
        streetName: this.address,
        postalCode: this.postcode,
        country: this.country,
      };
    }

    return {
      blockNo: this.house,
      streetName: this.street,

      floor: this.floor,
      unitNo: this.unit,
      buildingName: this.buildingName,

      country: this.country,
      postalCode: this.postcode,

      district: this.district,
    };
  }
}
