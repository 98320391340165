import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUploaderModel } from '../../data-access/models';
import { MediaStorageService } from '../media-storage';
import { UploadDirective } from './upload.directive';
import { CanUploadFn } from './upload.interface';

@Component({
  selector: 'red-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent {
  files = [];
  allFiles: FileUploaderModel[] = [];
  isFileOver = false;
  isAvailableUploadBtn = false;
  previewMimeTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp', 'image/gif'];
  lastUploadedFiles: FileUploaderModel[] = [];
  @Input() existFileKeys!: Array<string>;
  @Input() canUploadFn?: CanUploadFn;
  @Input() uploadToFolder = 'asset';
  @Input() disabled = false;
  @Input() isShowUploadBox = true;
  @Input() isUploadImmediately = false;
  @Input() message = '* Multiple files format under 15MB each';
  @Input() maxFiles = 15;
  @Input() maxFileSize = 15 * 1024 * 1024;
  @Input() allowedMimeType!: Array<string>;
  @Input() allowExtensions!: Array<string>;
  @Input() preview = true;
  @Input() isMultiple = true;
  @Input() isReplaced = false;
  @Input() isHiddenButtonClearAll = false;
  @Input() isPublic = false;
  @Input() containerHeight = '300px';
  @Input() apiGetUploadLink!: (data: { type: string; name: string; acl?: any }) => Observable<any>;
  @Input() showFileError = false;
  @Input() validateFileName = true;
  @Output() all = new EventEmitter<FileUploaderModel[]>();
  @Output() added = new EventEmitter<FileUploaderModel[]>();
  @Output() removed = new EventEmitter<FileUploaderModel[]>();
  @Output() lastUploaded = new EventEmitter<FileUploaderModel[]>();
  @Output() begin = new EventEmitter<any>();
  @Output() finished = new EventEmitter<any>();
  @Output() beginFile = new EventEmitter<FileUploaderModel>();
  @Output() finishedFile = new EventEmitter<FileUploaderModel>();
  @Output() fullSlot = new EventEmitter<void>();
  @ViewChild(UploadDirective, { static: true }) uploadDirective!: UploadDirective;
  @ViewChild('input', { static: true }) private inputElement!: ElementRef;

  constructor(private _storageApiService: MediaStorageService) {}

  onAllFiles(files: FileUploaderModel[]) {
    this.allFiles = files;
    this.checkPreview();
    this.all.emit(this.allFiles);
  }

  onAdded(files: FileUploaderModel[]) {
    this.isAvailableUploadBtn = true;
    this.added.emit(files);
    if (this.isUploadImmediately) {
      this.upload();
    }
  }

  onRemoved(files: FileUploaderModel[]) {
    this.removed.emit(files);
  }

  onLastUpdated(files: FileUploaderModel[]) {
    this.lastUploaded.emit(files);
  }

  onBegin() {
    this.begin.emit();
  }

  onFinished() {
    this.finished.emit();
  }

  onBeginFile(file: FileUploaderModel) {
    this.beginFile.emit(file);
  }

  onFinishedFile(file: FileUploaderModel) {
    this.finishedFile.emit(file);
  }

  checkPreview() {
    this.allFiles.forEach(file => {
      file.isPreview = this.previewMimeTypes.includes(file.file.type);
    });
  }

  fileDrop(event: any) {
    this.uploadDirective.onChange(event);
  }

  openSelectBox() {
    this.inputElement.nativeElement.click();
  }

  upload() {
    this.uploadDirective.upload();
  }

  clear() {
    this.uploadDirective.clear();
  }

  deleteFile(id: number): void {
    this.uploadDirective.deleteFile(id);
  }

  fileOver(isOver: any) {
    this.isFileOver = isOver;
  }

  loadImgInfo(fileUploader: any, info: any) {
    fileUploader.file.size = info.contentLength;
    fileUploader.file.type = info.contentType;
    fileUploader.data = info.objectUrl;
    fileUploader.isPreview = this.previewMimeTypes.includes(fileUploader.file.type);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  error() {}

  onFullSlot() {
    this.fullSlot.emit();
  }
}
