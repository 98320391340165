import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbsorbByPipe } from './absorb-by.pipe';

@NgModule({
  declarations: [AbsorbByPipe],
  imports: [CommonModule],
  exports: [AbsorbByPipe],
})
export class AbsorbByModule {}
