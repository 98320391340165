import {
  ExceljsValueType,
  ExecljsParameterValueType,
  IExceljsCard,
  IExceljsCardConfig,
  IExceljsCell,
  IExceljsCellAttrs,
  IExceljsCol,
  IExceljsHorizontalAligmentStyle,
  IExceljsHorizontalColorConfig,
  IExceljsNormalizedCell,
  IExceljsParameter,
  IExceljsParameterControlType,
  IExceljsParameterVisualization,
  IExceljsRow,
  IExceljsStyle,
  IExceljsVerticalAligmentStyle,
  IExceljsWorkBook,
  IExceljsWorkBookMeta,
  IExceljsWorkSheet,
} from './exceljs.interface';
import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';

export class ExceljsFontStyle extends BaseModel {
  @Expose()
  bold?: boolean;

  @Expose()
  @Default(11)
  size!: number;

  @Expose()
  color?: IExceljsHorizontalColorConfig;
}
export class ExceljsCellStyle extends BaseModel implements IExceljsStyle {
  @Expose()
  alignment?: {
    vertical: IExceljsVerticalAligmentStyle;
    horizontal: IExceljsHorizontalAligmentStyle;
    wrapText: boolean;
  };

  @Expose()
  fill?: { type: string; pattern: 'solid'; fgColor: IExceljsHorizontalColorConfig };

  @Expose()
  border?: {
    top?: {
      style: 'thin';
      color?: IExceljsHorizontalColorConfig;
    };
    left?: {
      style: 'thin';
      color?: IExceljsHorizontalColorConfig;
    };
    bottom?: {
      style: 'thin';
      color?: IExceljsHorizontalColorConfig;
    };
    right?: {
      style: 'thin';
      color?: IExceljsHorizontalColorConfig;
    };
  };

  @Expose()
  @Default({})
  @Type(() => ExceljsFontStyle)
  font!: ExceljsFontStyle;

  @Expose()
  numFmt!: string;
}
export class ExceljsCell extends BaseModel implements IExceljsCell {
  @Expose()
  address!: string;

  @Expose()
  type!: ExceljsValueType;

  @Expose()
  value!: string;

  @Expose()
  master!: string;

  @Expose()
  @Default({})
  @Type(() => ExceljsCellStyle)
  style!: ExceljsCellStyle;
}
export class ExceljsNormalizedCell extends BaseModel implements IExceljsNormalizedCell {
  @Expose()
  address!: string;
  @Expose()
  type!: number;
  @Expose()
  value!: string;
  @Expose()
  master!: string;
  @Expose()
  @Default({})
  @Type(() => ExceljsCellStyle)
  style!: ExceljsCellStyle;
  @Expose()
  attrs!: IExceljsCellAttrs;
  @Expose()
  formula?: string;
  @Expose()
  text?: string;
  @Expose()
  hyperlink?: string;

  @Expose()
  result?: any;

  @Expose()
  @Transform(({ obj }) => {
    // If cell value is created from hyperlink, let get data from text instead of value
    if (obj.type === ExceljsValueType.HYPERLINK) {
      return obj.text;
    }
    // If cell value is created from formula like 'A11 + B11 - C11', let get data from result instead of value
    if (obj.type === ExceljsValueType.FORMULA) {
      return obj.result;
    }
    return obj.value;
  })
  normalizedValue: any;
}
export class ExceljsRow extends BaseModel implements IExceljsRow {
  @Expose()
  @Type(() => ExceljsNormalizedCell)
  cells!: ExceljsNormalizedCell[];

  @Expose()
  number!: number;

  @Expose()
  min!: number;

  @Expose()
  max!: number;

  @Expose()
  style!: IExceljsStyle;

  @Expose()
  hidden!: boolean;

  @Expose()
  collapsed!: boolean;
}

export class ExceljsCol extends BaseModel implements IExceljsCol {
  @Expose()
  width!: number;

  @Expose()
  min!: number;

  @Expose()
  max!: number;

  @Expose()
  style!: IExceljsStyle;

  @Expose()
  hidden!: boolean;

  @Expose()
  collapsed!: boolean;
}
export class ExceljsWorkSheet extends BaseModel implements IExceljsWorkSheet {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  properties!: { defaultRowHeight: number; outlineLevelCol: number; outlineLevelRow: number; defaultColWidth: number };

  @Expose()
  @Default([])
  @Type(() => ExceljsCol)
  cols!: ExceljsCol[];

  @Expose()
  @Type(() => ExceljsRow)
  rows!: ExceljsRow[];

  @Expose()
  merges!: string[];

  @Expose()
  views!: {
    state: string;
    xSplit: number;
    ySplit: number;
  }[];
}
export class ExceljsWorkBookMeta extends BaseModel implements IExceljsWorkBookMeta {
  @Expose({ name: 'itemsPerPage' })
  @Default(10)
  limit!: number;

  @Expose({ name: 'currentPage' })
  @Default(1)
  page!: number;

  @Expose({ name: 'totalPages' })
  pageCount!: number;

  @Expose({ name: 'totalItems' })
  total!: number;

  @Expose()
  @Default([10, 20, 30, 40])
  pageSizeOptions!: number[];
}
export class ExceljsWorkBook extends BaseModel implements IExceljsWorkBook {
  @Expose()
  name!: string;

  @Expose()
  @Type(() => ExceljsWorkSheet)
  worksheets!: ExceljsWorkSheet[];

  @Expose()
  @Transform(({ obj, value }: { obj: IExceljsWorkBook; value: any }) => {
    // lazy loading only support with doc has only 1 worksheet
    if (obj.worksheets.length > 1) {
      return null;
    }
    return value;
  })
  @Type(() => ExceljsWorkBookMeta)
  meta!: ExceljsWorkBookMeta | null;
}
export class ExceljsParameter extends BaseModel implements IExceljsParameter {
  @Expose()
  type!: IExceljsParameterControlType;

  @Expose()
  name!: string;

  @Expose()
  slug!: string;

  @Expose()
  options!: { value: string | number | boolean; label: string }[];

  @Expose()
  default!: unknown;

  @Expose()
  value?: any;

  @Expose()
  @Transform(({ obj, value }: { obj: IExceljsParameter; value: ExecljsParameterValueType }) => {
    if (value) {
      return value;
    }
    if (obj.type === 'date/range') {
      return 'object';
    }

    return 'string';
  })
  valueType!: ExecljsParameterValueType;

  @Expose()
  @Transform(({ obj, value }: { obj: IExceljsParameter; value: ExecljsParameterValueType }) => {
    if (value) {
      return value;
    }
    if (obj.type === 'date/range') {
      return {
        start: ExceljsParameter.fromJson({
          type: 'date/single',
          slug: 'start',
          valueType: 'string',
        }),
        end: ExceljsParameter.fromJson({
          type: 'date/single',
          slug: 'start',
          valueType: 'string',
        }),
      };
    }

    return {};
  })
  properties!: Record<string, ExceljsParameter> | undefined;

  @Expose()
  templateRef!: string;
}
export class ExceljsCardConfig extends BaseModel implements Required<IExceljsCardConfig> {
  @Expose()
  @Default(false)
  paging!: boolean;

  @Expose()
  @Default({
    page: 'page',
    limt: 'limit',
  })
  parameters!: { page: string; limit: string };

  @Expose()
  @Default(false)
  openPanel!: boolean;
}
export class ExceljsFileSupportedConfig extends BaseModel {
  @Expose()
  type!: 'xlsx' | 'pdf';

  @Expose()
  stream!: boolean;
}
export class ExceljsCard extends BaseModel implements IExceljsCard {
  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  @Default([])
  @Type(() => ExceljsParameter)
  parameters!: ExceljsParameter[];

  @Expose()
  parameterVisualization!: IExceljsParameterVisualization;

  @Expose()
  @Transform((params) => {
    const val = params.value
    if (!val || !Array.isArray(val)) {
      return [{
        type: 'xlsx',
        stream: false
      }]
    }
    return val.map(item => {
      if (!item) {
        return [{
          type: 'xlsx',
          stream: false
        }]
      }
      if (typeof item === 'string') {
        return {
          type: item,
          stream: false
        }
      }
      return item
    })
  })

  fileSupported!: ExceljsFileSupportedConfig[];

  @Expose()
  @Default({})
  @Type(() => ExceljsCardConfig)
  configs!: ExceljsCardConfig;
  @Expose()
  @Default('1.0.0')
  version!: string;
}
