import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { isMoment } from 'moment';
@Pipe({
  name: 'datetime'
})
export class DatetimePipe implements PipeTransform {

  transform(value:unknown , formatString: string = 'DD/MM/YYYY • LT'): unknown {
    if (isMoment(value)) {
      return value.format(formatString);
    }
    if(typeof value === 'string'){
      const momentValue = moment(value);
      if (momentValue.isValid()) {
          return momentValue.format(formatString);
      }
    }

    return value;
  }

}
