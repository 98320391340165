<pagination-template
  #p="paginationApi"
  [id]="id"
  [maxSize]="maxSize"
  (pageChange)="pageChange.emit($event)"
  (pageBoundsCorrection)="pageBoundsCorrection.emit($event)"
>
  <div class="flex flex-row justify-between items-center bg-theme-line px-4 py-4 h-12">
    <div *ngIf="!hidePageSize" class="flex flex-row items-center">
      <span>Show item</span>
      <mat-form-field class="mat-paginator-page-size-select" appearance="outline">
        <mat-select [value]="itemsPerPage" (selectionChange)="itemsPerPageChange.emit($event.value)">
          <mat-option *ngFor="let sizeOption of pageSizeOptions" [value]="sizeOption">{{ sizeOption }}</mat-option>
        </mat-select>
      </mat-form-field>
      of {{ p.getTotalItems() }}
    </div>
    <nav role="navigation" [attr.aria-label]="screenReaderPaginationLabel">
      <ul class="ngx-pagination" [class.responsive]="responsive" *ngIf="!(autoHide && p.pages.length <= 1)">
        <li class="pagination-previous" [class.disabled]="p.isFirstPage()" *ngIf="directionLinks">
          <a tabindex="0" *ngIf="1 < p.getCurrent()" (keyup.enter)="p.previous()" (click)="p.previous()">
            {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
          </a>
          <span *ngIf="p.isFirstPage()" aria-disabled="true">
            {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
          </span>
        </li>
        <li class="small-screen">{{ p.getCurrent() }} / {{ p.getLastPage() }}</li>
        <li [class.current]="p.getCurrent() === page.value" [class.ellipsis]="page.label === '...'" *ngFor="let page of p.pages; trackBy: trackByIndex">
          <a tabindex="0" (keyup.enter)="p.setCurrent(page.value)" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
            <span class="show-for-sr">{{ screenReaderPageLabel }} </span>
            <span>{{ page.label === '...' ? page.label : (page.label | number: '') }}</span>
          </a>
          <ng-container *ngIf="p.getCurrent() === page.value">
            <span aria-live="polite">
              <span class="show-for-sr">{{ screenReaderCurrentLabel }} </span>
              <span>{{ page.label === '...' ? page.label : (page.label | number: '') }}</span>
            </span>
          </ng-container>
        </li>
        <li class="pagination-next" [class.disabled]="p.isLastPage()" *ngIf="directionLinks">
          <a tabindex="0" *ngIf="!p.isLastPage()" (keyup.enter)="p.next()" (click)="p.next()">
            {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
          </a>
          <span *ngIf="p.isLastPage()" aria-disabled="true">
            {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
          </span>
        </li>
      </ul>
    </nav>
  </div>
</pagination-template>
