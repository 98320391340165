import { Component, inject } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'red-copyright',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss'],
  providers: [DatePipe],
})
export class CopyrightComponent {
  datePipe = inject(DatePipe);
  now = this.datePipe.transform(new Date(), 'yyyy');
}
