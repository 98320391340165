import { Directive } from '@angular/core';
import { ApiService } from '@red/api';
import { ExceljsCard, ExceljsFileSupportedConfig, ExceljsHttpJob, ExceljsWorkBook, IExceljsHttpJobRes, IExceljsHttpJobStatusRes } from '@shared/components/exceljs-viewer';
import { isObject } from 'lodash-es';
import { Observable, map, of } from 'rxjs';

export enum ExceljsCardCollection {
  ProjectReport = 'transaction/project-reports',
  FinanceReport = 'finance/reports',
  SupplierReport = 'finance/suppliers/reports',
  CustomerReport = 'finance/customers/reports',
  BankReport = 'finance/banking/reports',
  AgentReport = 'hrm/hr/reports',
  ResaleReport = 'transaction/resale-reports',
  TransactionReport = 'transaction/common-reports',
  AwardsReport = 'hrm/hr/reports-awards',

}
@Directive()
export abstract class ExceljsApiService {
  abstract collectionEndpoint: ExceljsCardCollection;

  constructor(protected apiService: ApiService) { }

  getExceljsCard(code: string, query = {}): Observable<ExceljsCard> {
    return this.apiService.get(this.collectionEndpoint + '/' + code, query).pipe(map(res => ExceljsCard.fromJson(res) as ExceljsCard));
  }

  queryExceljsCard(code: string, data: Record<string, any>): Observable<ExceljsWorkBook> {
    return this.apiService.post(this.collectionEndpoint + '/' + code, data).pipe(map(res => ExceljsWorkBook.fromJson(res) as ExceljsWorkBook));
  }

  getExceljsCardXlsxFile(code: string, data: Record<string, any> = {}, extConfig?: string | ExceljsFileSupportedConfig): Observable<any> {
    const ext = isObject(extConfig) ? extConfig.type : extConfig;
    const isStream = isObject(extConfig) ? extConfig.stream : false;
    return this.apiService.download('post', this.collectionEndpoint + '/' + code + '/download',
      { ...data, type: ext ?? 'xlsx', tream: isStream }, isStream)
    // return this.apiService
    //   .post(
    //     this.collectionEndpoint + '/' + code + '/download',
    //     { ...data, type: ext ?? 'xlsx' },
    //     {
    //       pretreatmentResponse: false,
    //       requestOptions: {
    //         responseType: 'arraybuffer',
    //       },
    //     }
    //   )
    //   .pipe(
    //     map(res => {
    //       return res;
    //     })
    //   );
  }
  getJob(code: string, jobName: ExceljsHttpJob = 'download', data: Record<string, any>): Observable<IExceljsHttpJobRes> {
    const req = { ...data, type: data['ext'] || 'xlsx' }
    return this.apiService.post(this.collectionEndpoint + '/' + code + `/job/${jobName}`, req)
  }
  getJobStatus(jobId: string): Observable<IExceljsHttpJobStatusRes> {
    return this.apiService.get(this.collectionEndpoint + `/job/status/${jobId}`)
  }
  getJobLink(jobId: string): Observable<string> {
    const url = this.apiService.createAPIURL(`${this.collectionEndpoint}/job/download/${jobId}`, {})
    return of(url)
  }
}
