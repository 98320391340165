import {
  EFurnished,
  EResaleEmailStatus,
  EResalePaymentMethod,
  EResalePropertyType,
  EResaleStatus,
  EResaleSubmitLateReason,
  EResaleType,
  EResaleUserRole,
} from '../enums/resale.enum';
import { IAddressProperty } from './address-property.interface';
import { IAddress } from './address.interface';
import { ICompany } from './company.interface';
import { ICountry } from './country.interface';
import { IResaleBilling } from './resale-billing.interface';
import { IResaleDocument, IResaleDocumentHip } from './resale-document.interface';
import { IResaleRelatedAgent } from './resale-related-agent.interface';
import { IUpdatedBy } from './updated-by.interface';
export interface IResaleTransaction {
  id: number;
  createdAt: string;
  createdBy: number;
  submittedAt: string;
  submittedBy: number;
  updatedAt: string;
  updatedBy: IUpdatedBy;
  reviewedAt: string;
  reviewedBy: IUpdatedBy;
  status: EResaleStatus;
  code: string;
  name: string;
  resaleType: EResaleType;
  rejectReason?: string;
  propertyType: EResalePropertyType;
  companyId: number;
  company?: ICompany;
  submissionDate: string;
  reSubmissionDate: string;
  agreementDate: string;
  commencementDate: string;
  optionDate: string;
  expireDate: string;
  completionDate: string;
  remarksInternal: string;
  remarks: string;
  countryId: number;
  country?: ICountry;
  furnished?: EFurnished;
  invoiceId: number;
  invoice?: any;
  relatedAgent: IResaleRelatedAgent;
  billing: IResaleBilling;
  isInternal: boolean;
  documents: IResaleDocument[];
  invoiceAt?: string;
  userAgentId?: number;
  salespersonId?: number;
  paymentMethod?: EResalePaymentMethod;
  role?: EResaleUserRole;
  propertyAddress?: IAddressProperty;
  metadata?: IResaleMetadata;
  payment?: {
    total: number;
    paymentCodes: string[];
  };
  payments?: {
    totalAmount: number;
    itemCode: string
    code: string;
    paymentDate: string;
  }[];
  totalPaymentAmount?: number;
  system?: 'hms' | 'agent';
  area?: {
    areaSqft?: number;
    areaSqm?: number;
    landSqft?: number;
    landSqm?: number;
  };
  migrationId?: string;
  additionals?: IResaleDocumentHip[];
  isPC?: boolean;
}
export interface IResaleMetadata {
  agent?: string | null;
  admin?: string | null;
  ecbGreaterPercentReason?: string;
  submitLateReason?: {
    type: EResaleSubmitLateReason;
    reason: string;
  };
  isPC?: boolean;
  skipEmail?: boolean;
  emailStatus?: EResaleEmailStatus;
  isAbleSubmitted?: boolean;
  isPropertyAddress?: boolean;
  isContactAddress?: boolean;
  isCobroke?: boolean;
  isWithPayment?: boolean;
  isBillToAll?: boolean;
  agreeToTerms?: boolean;
  isFirstTimeSubmitted?: boolean;
}
