import { BaseModel, Default, EndOfDate, FormattedDateString, PhoneNumber, StartOfDate } from '@red/data-access';
import {
  EBlankFormInternalAgentType,
  EBlankFormOtherFeeType,
  EBLankFormReferralFormula,
  EBlankFormReferralSourceType,
  EBlankFormReferralType,
  EBusinessDirector,
  EBuyerPropertyType,
  EManagementCommissionPdRole,
} from '../enums';
import { GrossCommissionEarnedModel } from '../models';
import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { IGrossCommissionEarned, IPersonalParticular } from '../interfaces';
import { AbstractListFilters } from '@shared/core';
import { BlankFormBuyerDto } from './blank-form-buyer.dto';

export class BLankFormBdDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => BlankFormCommissionTierDto)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Commission',
          },
          {
            ...options,
          }
        ),
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Tier 1',
            level: 2,
          },
          { ...options }
        ),
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Tier 2',
            level: 3,
          },
          { ...options }
        ),
      ];
    }
    return value.map((item: any) => BlankFormCommissionTierDto.fromJson(item, { ...options }));
  })
  tiers!: BlankFormCommissionTierDto[];

  @Expose()
  @Type(() => BlankFormCommissionTierDto)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Recruiter',
            level: 4,
          },
          { ...options }
        ),
      ];
    }
    return value.map((item: any) => BlankFormCommissionTierDto.fromJson(item, options));
  })
  parties!: BlankFormCommissionTierDto[];

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => Boolean)
  isDefault!: boolean;

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  type!: EBusinessDirector;
}

export class BLankFormBdsSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  blankFormId!: number;

  @Expose()
  @Default([])
  @Type(() => BLankFormBdDto)
  data!: BLankFormBdDto[];

  @Expose()
  @Type(() => Number)
  hiProfitAmount!: number;
}

export class BlankFormOtherFeeDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  code!: EBlankFormOtherFeeType;

  @Expose()
  receivedValue!: number;

  @Expose()
  attrs!: any;

  @Expose()
  @Type(() => Boolean)
  isDefault!: boolean;
}

export class BLankFormOtherFeeSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  blankFormId!: number;

  @Expose()
  @Default([])
  @Type(() => BlankFormOtherFeeDto)
  data!: BlankFormOtherFeeDto[];
}

export class BLankFormPdDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  additional!: number;

  @Expose()
  salespersonId!: number;

  @Expose()
  isActive!: boolean;

  @Expose()
  @Type(() => BlankFormCommissionTierDto)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Commission',
          },
          {
            ...options,
          }
        ),
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Tier 1',
            level: 2,
          },
          { ...options }
        ),
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Tier 2',
            level: 3,
          },
          { ...options }
        ),
      ];
    }
    return value.map((item: any) => BlankFormCommissionTierDto.fromJson(item, { ...options }));
  })
  tiers!: BlankFormCommissionTierDto[];

  @Expose()
  @Type(() => BlankFormCommissionTierDto)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Recruiter',
            level: 4,
          },
          { ...options }
        ),
      ];
    }
    return value.map((item: any) => BlankFormCommissionTierDto.fromJson(item, options));
  })
  parties!: BlankFormCommissionTierDto[];

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => Boolean)
  isDefault!: boolean;

  @Expose()
  pdRole!: EManagementCommissionPdRole;

  @Expose()
  appointmentTypeId!: number;

  @Expose()
  attrs!: object;
}

export class BLankFormPdsPreviewDto extends BaseModel {
  @Expose()
  blankFormId!: number;

  @Expose()
  projectId!: number;

  @Expose()
  managementCommId!: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  optionDate!: string;
}

export class BLankFormPdSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  blankFormId!: number;

  @Expose()
  @Default([])
  @Type(() => BLankFormPdDto)
  data!: BLankFormPdDto[];
}

export class BLankFormReferralDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  blankFormReferralType!: EBlankFormReferralType;

  @Expose()
  formula!: EBLankFormReferralFormula;

  @Expose()
  @Default({
    agent: true,
    hr: true,
  })
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  type!: EBlankFormReferralSourceType;

  @Expose()
  salespersonId!: number;

  @Expose()
  agentId!: number;

  @Expose()
  blankFormId!: number;

  @Expose()
  isActive!: boolean;

  @Expose()
  @Type(() => BlankFormCommissionTierDto)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Commission',
          },
          { ...options }
        ),
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Tier 1',
            level: 2,
          },
          { ...options }
        ),
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Tier 2',
            level: 3,
          },
          { ...options }
        ),
      ];
    }
    return value.map((item: any) => BlankFormCommissionTierDto.fromJson(item, { ...options }));
  })
  tiers!: BlankFormCommissionTierDto[];

  @Expose()
  @Type(() => BlankFormCommissionTierDto)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Recruiter',
            level: 4,
          },
          { ...options }
        ),
      ];
    }
    return value.map((item: any) => BlankFormCommissionTierDto.fromJson(item, { ...options }));
  })
  parties!: BlankFormCommissionTierDto[];

  @Expose()
  receivedValue!: number;

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => Boolean)
  isDefault!: boolean;

  @Expose()
  note: any;

  @Expose()
  attrs!: any;

  @Expose()
  name!: string;
}

export class BLankFormReferralSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  blankFormId!: number;

  @Expose()
  @Default([])
  @Type(() => BLankFormReferralDto)
  data!: BLankFormReferralDto[];
}

export class BlankFormBuyerPayload extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  blankFormId!: number | null;

  @Expose()
  data!: BlankFormBuyerDto[];
}

export class BlankFormBuyerInfoPayload extends BaseModel {
  @Expose()
  remark!: string;
}

export class BlankFormIcDto extends BaseModel {
  @Expose()
  appointmentTypeId!: number;

  @Expose()
  appTypeCode!: string;

  @Expose()
  blankFormId!: number;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  createdAt!: string;

  @Expose()
  id!: number;

  @Expose()
  isActive!: boolean;

  @Expose()
  isDefault!: boolean;

  @Expose()
  name!: string;

  @Expose()
  note!: any;

  @Expose()
  attrs!: any;

  @Expose()
  @Type(() => BlankFormCommissionTierDto)
  @Transform(({ value }) => {
    if (!value || value.length === 0) {
      return [
        {
          name: 'Recruiter',
          level: 4,
        },
      ];
    }
    return value;
  })
  parties!: BlankFormCommissionTierDto[];

  @Expose()
  salespersonId!: number;

  @Expose()
  splitMatrixId!: number;

  @Expose()
  @Type(() => BlankFormCommissionTierDto)
  @Transform(({ value }) => {
    if (!value || value.length === 0) {
      return [
        {
          name: 'Commission',
          value: 0,
        },
        {
          name: 'Tier 1',
          level: 2,
          value: 0,
        },
        {
          name: 'Tier 2',
          level: 3,
          value: 0,
        },
      ];
    }
    return value;
  })
  tiers!: BlankFormCommissionTierDto[];
}

export class BlankFormIcSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  blankFormId!: number;

  @Expose()
  @Default([])
  @Type(() => BlankFormIcDto)
  data!: BlankFormIcDto[];
}

export class BlankForBuyerInfoDto extends BaseModel {
  @Expose()
  remark!: string;
}

export class BlankFormCoBrokeAgentCommissionShareDto extends BaseModel {
  @Expose()
  currency!: string;

  @Expose()
  @Default('number')
  type!: 'percentage' | 'absolute' | 'number';

  @Expose()
  @Default(0)
  value!: number;
}

export class BlankFormCoBrokeAgentAttrDto extends BaseModel {
  @Expose()
  @Type(() => BlankFormCoBrokeAgentCommissionShareDto)
  commission!: BlankFormCoBrokeAgentCommissionShareDto;

  @Expose()
  sequence!: number;

  @Expose()
  order = 0;
}

export class BlankFormCommissionTierDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  @Transform(params => (params.obj.salespersonId ? +(params.value || 0) : undefined))
  @Type(() => Number)
  percentage!: number;

  @Expose()
  @Type(() => Number)
  level!: number;

  @Expose()
  @Transform(params => (params.obj.salespersonId ? +(params.value || 0) : undefined))
  @Type(() => Number)
  value!: number;

  @Expose()
  @Type(() => Number)
  @Default(null)
  salespersonId!: number;
}

export class BlankFormInternalCoBrokeDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  type!: EBlankFormInternalAgentType;

  @Expose()
  @Type(() => Number)
  splitMatrixId!: number;

  @Expose()
  @Type(() => BlankFormCommissionTierDto)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Commission',
          },
          options
        ),
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Tier 1',
            level: 2,
          },
          options
        ),
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Tier 2',
            level: 3,
          },
          options
        ),
      ];
    }
    return value.map((item: any) => BlankFormCommissionTierDto.fromJson(item, options));
  })
  tiers!: BlankFormCommissionTierDto[];

  @Expose()
  @Type(() => BlankFormCommissionTierDto)
  @Transform(({ value, options }) => {
    if (!value || value.length === 0) {
      return [
        BlankFormCommissionTierDto.fromJson(
          {
            name: 'Recruiter',
            level: 4,
          },
          options
        ),
      ];
    }
    return value.map((item: any) => BlankFormCommissionTierDto.fromJson(item, options));
  })
  parties!: BlankFormCommissionTierDto[];

  @Expose()
  @Type(() => BlankFormCoBrokeAgentAttrDto)
  attrs!: BlankFormCoBrokeAgentAttrDto;

  @Expose()
  note!: string;

  @Expose()
  @Type(() => Boolean)
  isDefault!: boolean;

  @Exclude()
  agent!: IPersonalParticular;

  @Exclude()
  blankFormId!: number;

  @Exclude()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  createdAt!: string;

  @Exclude()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  updatedAt!: string;

  @Exclude()
  name!: string;

  @Exclude()
  isActive!: boolean;

  @Expose()
  @Type(() => Number)
  categoryId!: number;

  @Expose()
  @Type(() => String)
  grouping!: string;
}

export class BlankFormExternalCoBrokeDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id!: number;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  brokerName!: string;

  @Expose()
  @Type(() => Number)
  receivedValue!: number;

  @Expose()
  @Type(() => Boolean)
  isDefault!: boolean;

  @Expose()
  @Type(() => BlankFormCoBrokeAgentAttrDto)
  attrs!: BlankFormCoBrokeAgentAttrDto;

  @Expose()
  note!: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone!: PhoneNumber;
}

export class BlankFormRelatedAgentDto extends BaseModel {
  @Expose()
  @Type(() => BlankFormInternalCoBrokeDto)
  @Transform(
    ({ obj, value, options }) =>
      (obj?.main ? [BlankFormInternalCoBrokeDto.fromJson(obj.main)] : value?.map((item: any) => BlankFormInternalCoBrokeDto.fromJson(item, options))) ?? []
  )
  mains!: BlankFormInternalCoBrokeDto[];

  @Expose()
  @Type(() => BlankFormInternalCoBrokeDto)
  @Default([])
  internals!: BlankFormInternalCoBrokeDto[];

  @Expose()
  @Type(() => BlankFormExternalCoBrokeDto)
  @Default([])
  externals!: BlankFormExternalCoBrokeDto[];

  @Expose()
  @Default([])
  @Type(() => BlankFormInternalCoBrokeDto)
  @Transform(
    ({ obj, value, options }) =>
      (obj?.leader ? [BlankFormInternalCoBrokeDto.fromJson(obj.leader, options)] : value?.map((item: any) => BlankFormInternalCoBrokeDto.fromJson(item, options))) ??
      []
  )
  leaders!: BlankFormInternalCoBrokeDto[];
}

export class BlankFormCreateDto extends BaseModel {
  @Expose()
  address!: string;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  @Transform(({ value, options }) => (value ? GrossCommissionEarnedModel.fromJson(value, options) : null))
  grossComm!: GrossCommissionEarnedModel;

  @Expose()
  @Transform(
    ({ value, options }) => {
      return value ? GrossCommissionEarnedModel.fromJson(value, options) : null;
    },
    { toClassOnly: true }
  )
  devGrossComm!: IGrossCommissionEarned;

  @Expose()
  @Transform(({ value, options }) => (value ? GrossCommissionEarnedModel.fromJson(value, options) : null), { toClassOnly: true })
  hiGrossComm!: IGrossCommissionEarned;

  @Expose()
  @Transform(({ value, options }) => (value ? GrossCommissionEarnedModel.fromJson(value, options) : null), { toClassOnly: true })
  profomaGrossComm!: IGrossCommissionEarned;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  optionDate!: string;

  @Expose()
  optionNo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) return null;
    return value;
  })
  eotDate!: string;

  @Expose()
  projectId!: number;

  @Expose()
  invoiceId!: number;

  @Expose()
  @Default(BlankFormRelatedAgentDto.createEmpty())
  @Type(() => BlankFormRelatedAgentDto)
  relatedAgent!: BlankFormRelatedAgentDto;

  @Expose()
  remarks!: string;

  @Expose()
  @Type(() => Number)
  transactedPrice!: number;

  @Expose()
  @Type(() => Number)
  originalTransactedPrice!: number;

  @Expose()
  @Type(() => Number)
  originalCurrencyId!: number;

  @Expose()
  originalCurrencyCode?: string;

  @Expose()
  @Default('SGD')
  currencyCode?: string;

  @Expose()
  @Type(() => Number)
  transactionId!: number;

  @Expose()
  @Type(() => Number)
  blankFormId!: number;

  @Expose()
  @Type(() => Number)
  unitId!: number;

  @Expose()
  @Type(() => Number)
  landAreaSqft!: number;

  @Expose()
  @Type(() => Number)
  landAreaSqm!: number;

  @Expose({ groups: ['full'] })
  @Type(() => BLankFormBdsSetUpDto || null)
  bds!: BLankFormBdsSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormIcSetUpDto || null)
  bonuses!: BlankFormIcSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormIcSetUpDto || null)
  overridings!: BlankFormIcSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormIcSetUpDto || null)
  pools!: BlankFormIcSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BLankFormPdSetUpDto || null)
  pds!: BLankFormPdSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BLankFormReferralSetUpDto || null)
  referrals!: BLankFormReferralSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BLankFormOtherFeeSetUpDto || null)
  otherFees!: BLankFormOtherFeeSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormBuyerPayload || null)
  buyers!: BlankFormBuyerPayload | null;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormBuyerInfoPayload || null)
  buyer!: BlankFormBuyerInfoPayload | null;

  @Expose()
  @Type(() => Number)
  haTierProfit!: number;

  @Expose()
  @Type(() => Number)
  agentTierProfit!: number;

  @Expose()
  @Type(() => Number)
  companyTierProfit!: number;

  @Expose()
  propertyType!: EBuyerPropertyType;

  // @Expose({ groups: ['full', 'general'] })
  // @Type(() => Date)
  // @StartOfDate()
  // @FormattedDateString()
  // reissueDate?: string;
}

export class BlankFormDataUpdateDto extends BaseModel {
  @Expose({ groups: ['full', 'general'] })
  address!: string;

  @Expose()
  @Type(() => GrossCommissionEarnedModel)
  grossComm!: GrossCommissionEarnedModel;

  // @Expose()
  // @Type(() => GrossCommissionEarnedModel)
  // devGrossComm!: GrossCommissionEarnedModel;
  //
  // @Expose()
  // @Type(() => GrossCommissionEarnedModel)
  // hiGrossComm!: GrossCommissionEarnedModel;

  @Expose({ groups: ['full'] })
  @Transform(
    ({ value, options }) => {
      return value ? GrossCommissionEarnedModel.fromJson(value, options) : null;
    },
    { toClassOnly: true }
  )
  devGrossComm!: IGrossCommissionEarned;

  @Expose({ groups: ['full'] })
  @Transform(({ value, options }) => (value ? GrossCommissionEarnedModel.fromJson(value, options) : null), { toClassOnly: true })
  hiGrossComm!: IGrossCommissionEarned;

  @Expose({ groups: ['full'] })
  @Transform(({ value, options }) => (value ? GrossCommissionEarnedModel.fromJson(value, options) : null), { toClassOnly: true })
  profomaGrossComm!: IGrossCommissionEarned;

  @Expose({ groups: ['full'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  optionDate!: string;

  @Expose({ groups: ['full'] })
  optionNo!: string;

  @Expose({ groups: ['full'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) return null;
    return value;
  })
  eotDate!: string;

  @Expose({ groups: ['full'] })
  projectId!: number;

  @Expose()
  invoiceId!: number;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormRelatedAgentDto)
  relatedAgent!: BlankFormRelatedAgentDto;

  @Expose({ groups: ['full', 'remark'] })
  remarks!: string;

  @Expose({ groups: ['full'] })
  @Type(() => Number)
  transactedPrice!: number;

  @Expose({ groups: ['full'] })
  @Type(() => Number)
  transactionId!: number;

  @Expose()
  @Type(() => Number)
  originalTransactedPrice!: number;

  @Expose()
  @Type(() => Number)
  originalCurrencyId!: number;

  @Expose()
  originalCurrencyCode?: string;

  @Expose()
  @Default('SGD')
  currencyCode?: string;

  @Expose({ groups: ['full'] })
  @Type(() => Number)
  blankFormId!: number;

  @Expose({ groups: ['full'] })
  @Type(() => Number)
  unitId!: number;

  @Expose({ groups: ['full'] })
  @Type(() => Number)
  landAreaSqft!: number;

  @Expose({ groups: ['full'] })
  @Type(() => Number)
  landAreaSqm!: number;

  @Expose({ groups: ['full'] })
  @Type(() => BLankFormBdsSetUpDto || null)
  // @Transform(({ value }) => {
  //   return value?.length > 0 ? value : null;
  // })
  bds!: BLankFormBdsSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormIcSetUpDto || null)
  bonuses!: BlankFormIcSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormIcSetUpDto || null)
  overridings!: BlankFormIcSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormIcSetUpDto || null)
  pools!: BlankFormIcSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BLankFormPdSetUpDto || null)
  pds!: BLankFormPdSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BLankFormReferralSetUpDto || null)
  referrals!: BLankFormReferralSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BLankFormOtherFeeSetUpDto || null)
  otherFees!: BLankFormOtherFeeSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormBuyerPayload || null)
  buyers!: BlankFormBuyerPayload | null;

  @Expose({ groups: ['full'] })
  @Type(() => BlankFormBuyerInfoPayload || null)
  buyer!: BlankFormBuyerInfoPayload | null;

  @Expose()
  @Type(() => Number)
  haTierProfit!: number;

  @Expose()
  @Type(() => Number)
  agentTierProfit!: number;

  @Expose()
  @Type(() => Number)
  companyTierProfit!: number;

  @Expose()
  propertyType!: EBuyerPropertyType;

  @Expose({ groups: ['full', 'general'] })
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) return null;
    return value;
  })
  reissueDate?: string;

  @Expose({ groups: ['full', 'general'] })
  @Default(false)
  @Type(() => Boolean)
  isReissue?: boolean;
}

export class BlankFormUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'full' | 'remark';

  @Expose()
  @Transform(params => {
    return BlankFormDataUpdateDto.fromJson(params.value, { groups: [params.obj.group] });
  })

  data!: BlankFormDataUpdateDto;
}

export class BlankFormFilters extends AbstractListFilters {
  @Expose()
  type!: string;

  @Expose()
  searchType!: string;

  @Expose()
  code!: string;

  @Expose()
  projectName!: string;

  @Expose()
  unitNo!: string;

  @Expose()
  adjustmentCode!: string;

  @Expose()
  remarks!: string;

  @Expose()
  optionNo!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  optionDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  optionDateEnd!: string;

  @Expose()
  submittedBy!: string;

  @Expose()
  @StartOfDate()
  @FormattedDateString()
  submittedDateStart!: string;

  @Expose()
  @EndOfDate()
  @FormattedDateString()
  submittedDateEnd!: string;

  @Expose()
  status!: string | string[];

  @Expose()
  agent!: string;
}
