import { Injectable } from '@angular/core';
import { PaymentGeneratorApiService } from '@cms/apis';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';
import { PaymentGeneratorAction } from '../actions';
import { FnWithCompany, WithCompany } from '@cms/backgrounds/switch-company';

@Injectable()
export class PaymentGeneratorEffect {
  loadItem$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(PaymentGeneratorAction.loadItem),
      exhaustMap(action => {
        return this.fetch(action.id).pipe(map(item => PaymentGeneratorAction.loadItemSuccess({ item })));
      })
    );
  });

  @FnWithCompany()
  fetch(id: number, @WithCompany() option: Record<string, any> = {}) {
    return this._apiService.get(id, option);
  }

  constructor(private _apiService: PaymentGeneratorApiService, private _actions$: Actions, private _store: Store) { }
}
