import { IRedIcon } from '@red/components/icon';

export const CUSTOME_ICON_DATA: IRedIcon[] = [
  { key: 'hr', path: 'assets/images/icons/hr.svg', isSvg: true },
  { key: 'transaction', path: 'assets/images/icons/transaction.svg', isSvg: true },
  { key: 'slider', path: 'assets/images/icons/slider.svg', isSvg: true },
  { key: 'confirm_success', path: 'assets/images/icons/confirm-dialog/success.svg', isSvg: true },
  { key: 'confirm_warning', path: 'assets/images/icons/confirm-dialog/warning.svg', isSvg: true },
  { key: 'confirm_danger', path: 'assets/images/icons/confirm-dialog/danger.svg', isSvg: true },
  'settings',
  { key: 'shortcut', path: 'assets/images/icons/shortcut.svg', isSvg: true },
  { key: 'finance', path: 'assets/images/icons/finance.svg', isSvg: true },
  { key: 'upload', path: 'assets/images/icons/upload.svg', isSvg: true },
  { key: 'import_csv', path: 'assets/images/icons/import-csv.svg', isSvg: true },
  { key: 'export_csv', path: 'assets/images/icons/export-csv.svg', isSvg: true },
  { key: 'minus', path: 'assets/images/icons/minus.svg', isSvg: true },
  { key: 'ecdd', path: 'assets/images/icons/ecdd.svg', isSvg: true },
];
