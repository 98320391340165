import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { DEBIT_NOTE_PAID_STATUS } from '../data';
import { ECreditNoteCreateMode, EDebitNoteDetailStatus, EDebitNoteStatus } from '../enums';
import { EBalanceDocumentPostingType, ECustomerOrSupplier } from '../enums/tax-invoice-enhanced.enum';
import { IDebitNote, IDebitNotePosting, IDebitNoteRecord, IDebitNoteSummary, IStatusDescription } from '../interfaces';
import { ContactAddressModel } from './contact-address.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { ContactPersonModel } from './contact-person.model';
import { CurrencyRateModel } from './currency-rate.model';
import { GstCategoryLookupModel } from './gst-category.model';
import { LedgerAccountModel } from './ledger-account.model';
import { PersonalParticularModel } from './personal-particular.model';
import { ProfitCentresModel } from './profit-centres.model';
import { TemplateModel } from './template.model';
import { UpdatedByModel } from './updated-by.model';

export class DebitNoteRecordModel extends BaseModel implements IDebitNoteRecord {
  @Expose()
  id!: number;

  @Expose()
  itemCode?: string;

  @Expose()
  description!: string;

  @Expose()
  amount!: number;

  @Expose()
  quantity!: number;

  @Expose()
  unitPrice!: number;

  @Expose()
  discount!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  @Type(() => GstCategoryLookupModel)
  gstCategoryLookup?: GstCategoryLookupModel;

  @Expose()
  gstValue?: number;

  @Expose()
  @Transform(({ obj }) => obj.gstPercent ?? Number(obj.gstCategoryLookup?.gst_rate))
  @Type(() => Number)
  gstPercent?: number;

  @Expose()
  @Transform(({ obj }) => obj.gstCharged ?? Number(obj.gstCategoryLookup?.gst_charged))
  @Type(() => Number)
  gstCharged?: number;

  @Expose()
  uom?: string;

  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Transform((object) => {
    if (object.obj?.account) {
      return object.obj?.account?.code
    }
    return object.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter!: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  // @Expose()
  // debitNote!: DebitNoteModel;

  @Expose()
  debitNoteId!: number;
}

export class DebitNotePostingModel extends BaseModel implements IDebitNotePosting {
  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Transform((object) => {
    if (object.obj?.account) {
      return object.obj?.account?.code
    }
    return object.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  description?: string;

  @Expose()
  debit!: number;

  @Expose()
  credit!: number;

  @Expose()
  amount!: number;

  @Expose()
  currency?: string;

  // @Expose()
  // debitNoteId!: number;

  // @Expose()
  // debitNoteDetailId?: number;

  // @Expose()
  // DebitNoteDetail?: IDebitNoteDetailEnhanced;

  @Expose()
  type?: EBalanceDocumentPostingType;
}

export class DebitNoteModel extends BaseModel implements IDebitNote {
  // General
  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact!: ContactCustomerAndSupplierModel;

  @Expose()
  @Type(() => ContactAddressModel)
  billingAddress!: ContactAddressModel;

  @Expose()
  contactId?: number;

  @Expose()
  contactAddressId!: number;

  @Expose()
  debitNoteNumber?: string;

  @Expose()
  debitNoteDate!: string;

  @Expose()
  @Type(() => ContactPersonModel)
  contactPerson?: ContactPersonModel;

  @Expose()
  contactPersonId?: number;

  // Summary
  @Expose()
  gstEdited!: boolean;

  @Expose()
  amount!: number;

  @Expose()
  discount!: number;

  @Expose()
  subTotal!: number;

  @Expose()
  gstPercent!: number;

  @Expose()
  gstValue!: number;

  @Expose()
  total!: number;

  @Expose()
  gst?: number;

  // Other Details
  @Expose()
  currencyId!: number;

  @Expose()
  currencyCode?: string;

  @Expose()
  @Type(() => CurrencyRateModel)
  currency!: CurrencyRateModel;

  @Expose()
  billingAddressCustom?: string;

  @Expose()
  contactPersonCustom?: string;

  @Expose()
  reference?: string;

  @Expose()
  creditTerm?: number;

  @Expose()
  paymentTerm?: string;

  @Expose()
  salePersonId?: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salesperson?: PersonalParticularModel;

  @Expose()
  remarks?: string;

  @Expose()
  templateId?: number;

  @Expose()
  @Type(() => TemplateModel)
  template?: TemplateModel;

  // General
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  balanceDue?: number;

  @Expose()
  type?: ECustomerOrSupplier.CUSTOMER;

  @Expose()
  inClosedPeriod?: boolean;

  @Expose()
  status?: EDebitNoteStatus; // cancel, abort, partial

  @Expose()
  @Transform(({ value }) => {
    if (!value) return undefined;
    if (typeof value === 'string' && !isNaN(+value)) return undefined;

    return DEBIT_NOTE_PAID_STATUS[value as EDebitNoteDetailStatus];
  })
  paidStatus?: IStatusDescription;

  @Expose()
  createdMode?: ECreditNoteCreateMode;

  @Expose()
  createdAt?: string;

  @Expose()
  updatedAt?: string;

  @Expose()
  createdBy?: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [EDebitNoteDetailStatus.PartPaid, EDebitNoteDetailStatus.FullyPaid, EDebitNoteDetailStatus.Cancelled].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod;
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [EDebitNoteDetailStatus.PartPaid, EDebitNoteDetailStatus.FullyPaid].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod;
  })
  canDelete!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [EDebitNoteDetailStatus.PartPaid, EDebitNoteDetailStatus.FullyPaid, EDebitNoteDetailStatus.Cancelled].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod;
  })
  canCancel!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [EDebitNoteDetailStatus.PartPaid, EDebitNoteDetailStatus.FullyPaid, EDebitNoteDetailStatus.Cancelled].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.paidAmount > 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid;
  })
  isLock!: boolean;
}

export class DebitNoteSummaryModel extends BaseModel implements IDebitNoteSummary {
  @Expose()
  amount!: number;

  @Expose()
  discount!: number;

  @Expose()
  subTotal!: number;

  @Expose()
  gstPercent!: number;

  @Expose()
  gstValue!: number;

  @Expose()
  total!: number;

  @Expose()
  gstEdited!: boolean;
}
