import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { CurrencyRateCreateDto, CurrencyRateUpdateDto } from '@shared/data-access/dto';
import { ICurrencyRate } from '@shared/data-access/interfaces';
import { CurrencyRateHistoryModel, CurrencyRateModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CurrencyRateApiService {
  static ROOT_POINT = 'collection/currencies';
  static HISTORY_POINT = 'collection/currencies/:id/histories';
  static COMMON_POINT = 'collection/currencies/currencies-common';

  constructor(private apiService: ApiService) { }

  create(data: CurrencyRateCreateDto): Observable<CurrencyRateModel> {
    return this.apiService.post(`${CurrencyRateApiService.ROOT_POINT}`, data).pipe(map((res: ICurrencyRate) => CurrencyRateModel.fromJson(res) as CurrencyRateModel));
  }

  get(itemId: number): Observable<CurrencyRateModel> {
    return this.apiService.get(CurrencyRateApiService.ROOT_POINT + '/' + itemId).pipe(map(res => CurrencyRateModel.fromJson(res) as CurrencyRateModel));
  }

  update(id: number, data: CurrencyRateUpdateDto): Observable<any> {
    return this.apiService.patch(CurrencyRateApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<CurrencyRateModel>> {
    return this.apiService.get(CurrencyRateApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(CurrencyRateModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<any> {
    return this.apiService.delete(CurrencyRateApiService.ROOT_POINT, { ids }, option);
  }

  getHistories(itemId: number, query = {}): Observable<PaginationAdapter<CurrencyRateHistoryModel>> {
    return this.apiService
      .get(CurrencyRateApiService.HISTORY_POINT, { ...query, id: itemId })
      .pipe(map(data => new PaginationAdapter(CurrencyRateHistoryModel, data)));
  }

  searchCommon(query = {}, option?: IApiOption): Observable<PaginationAdapter<CurrencyRateModel>> {
    return this.apiService.get(CurrencyRateApiService.COMMON_POINT, query, { ...option, removeEmpty: { enable: true } }).pipe(map(data => new PaginationAdapter(CurrencyRateModel, data)));
  }
} 
