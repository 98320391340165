import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absorbBy',
})
export class AbsorbByPipe implements PipeTransform {
  transform(value?: Record<string, unknown> | string): unknown {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return this.deserialize(value);
    }

    return this.serialize(value);
  }
  serialize(obj: Record<string, unknown>): string {
    if (obj['agent']) {
      if (obj['hr']) {
        return 'Agent + HR';
      }
      return 'Agent';
    }
    if (obj['full']) {
      return 'Agent (Full)';
    }
    return '';
  }
  deserialize(value: string): Record<string, unknown> {
    if (value === 'Agent') {
      return {
        agent: true,
        hr: false,
      };
    }
    if (value === 'Agent + HR') {
      return {
        agent: true,
        hr: true,
      };
    }
    if (value === 'Agent (Full)') {
      return {
        agent: false,
        hr: false,
        full: true,
      };
    }
    return {
      agent: false,
      hr: false,
    };
  }
}
