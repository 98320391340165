import { BaseModel, Default, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ECreditNoteStatus, EFinanceCalculatingOrigin } from '../enums';
import { EBalanceDocumentPostingType, ECustomerOrSupplier } from '../enums/tax-invoice-enhanced.enum';
import { LedgerAccountModel } from '../models';

export class SupplierCreditNoteRecordDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  itemCode?: string;

  @Expose()
  @Type(() => Number)
  @Default(1)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  balanceDue!: number;

  @Expose()
  @Type(() => Number)
  paidAmount!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  discount?: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  @Expose()
  @Default(false)
  gstInclusive!: boolean;

  // @Expose()
  // @Type(() => Number)
  // gstValue!: number;

  @Expose()
  @Type(() => Number)
  accountId?: number;

  @Expose()
  @Transform((object) => {
    console.log("🚀 ~ SupplierInvoiceRecordModel ~ @Transform ~ oject:", object)
    if (object.obj?.account) {
      return object.obj?.account?.code
    }
    return object.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  @Default('')
  remarks?: string;

  // @Expose()
  // bankReferralFee?: boolean;

  @Expose()
  description?: string;

  @Expose()
  @Type(() => Number)
  supplierInvoiceDetailId?: number;


  // @Expose()
  // uom?: string;

  // @Expose()
  // @Type(() => Number)
  // creditNoteId?: number;

  // @Expose()
  // @Type(() => Number)
  // taxInvoiceId?: number;

  // @Expose()
  // @Type(() => Number)
  // agentId?: number;
}

export class SupplierCreditNotePostingDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  id?: number;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  @Transform((object) => {
    if (object.obj?.account) {
      return object.obj?.account?.code
    }
    return object.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => Number)
  debit?: number;

  @Expose()
  @Type(() => Number)
  credit?: number;

  // @Expose()
  // @Type(() => Number)
  // creditNoteId?: number;

  // @Expose()
  // @Type(() => Number)
  // creditNoteDetailId?: number;

  @Expose()
  type?: EBalanceDocumentPostingType;
}

export class SupplierCreditNoteDto extends BaseModel {
  //  ====== General ======
  @Expose()
  @Default(ECustomerOrSupplier.SUPPLIER)
  type!: ECustomerOrSupplier;

  @Expose()
  @Type(() => Number)
  contactId?: number;

  @Expose()
  @Type(() => Number)
  contactPersonId?: number;

  @Expose()
  @Type(() => Number)
  contactAddressId?: number;

  @Expose()
  creditNoteNumber?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  creditNoteDate!: string;

  @Expose()
  @Type(() => Number)
  taxInvoiceId?: number;

  // @Expose()
  // @Default('SGD')
  // @Transform(({ value }) => 'SGD')
  // currency!: string;

  @Expose()
  @Type(() => Number)
  currencyId!: number;

  @Expose()
  status!: ECreditNoteStatus;

  // @Expose()
  // @Type(() => Number)
  // taxInvoiceId?: number;

  @Expose()
  @Type(() => Boolean)
  isWithHold?: boolean;

  //  ====== Other detail ======
  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => Number)
  @Default(7)
  creditTerm?: number;

  // @Expose()
  // @Type(() => Number)
  // @Default(0)
  // paymentTerm?: number;

  // @Expose()
  // printFormat?: string;

  @Expose()
  @Type(() => Number)
  salePersonId?: number;

  @Expose()
  billingAddressCustom?: string;

  @Expose()
  contactPersonCustom?: string;

  @Expose()
  reference?: string;

  @Expose()
  @Type(() => Number)
  templateId?: number;

  //  ====== Calculate data =====
  @Expose()
  @Type(() => Number)
  gstPercent?: number;

  // @Expose()
  // @Type(() => Number)
  // taxValue?: number;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  gstEdited?: boolean;

  @Expose()
  @Type(() => SupplierCreditNoteRecordDto)
  details!: SupplierCreditNoteRecordDto[];

  @Expose()
  @Type(() => SupplierCreditNotePostingDto)
  postings!: SupplierCreditNotePostingDto[];
}

export class SupplierCreditNotePostingReqItemDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  @Transform((object) => {
    console.log("🚀 ~ SupplierInvoiceRecordModel ~ @Transform ~ oject:", object)
    if (object.obj?.account) {
      return object.obj?.account?.code
    }
    return object.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => Number)
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Number)
  @Default(1)
  quantity!: number;

  @Expose()
  @Type(() => Number)
  unitPrice!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  discount!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Type(() => Number)
  gstPercent!: number;

  // @Expose()
  // @Type(() => Number)
  // amount?: number;

  @Expose()
  @Type(() => Number)
  balanceDue!: number;
}

export class SupplierCreditNotePostingReqDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  @Default(8)
  gstPercent!: number;

  @Expose()
  @Type(() => Number)
  gstValue!: number;

  @Expose()
  @Type(() => Boolean)
  @Default(false)
  gstEdited!: boolean;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Default(ECustomerOrSupplier.SUPPLIER)
  type!: ECustomerOrSupplier;

  @Expose()
  @Type(() => Number)
  contactId!: number | null;

  @Expose()
  @Type(() => Number)
  taxInvoiceId!: number | null;

  @Expose()
  @Type(() => SupplierCreditNotePostingReqItemDto)
  items!: SupplierCreditNotePostingReqItemDto[];
}

export class SupplierCreditNoteCalculateSummaryReqDto extends BaseModel {
  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  @Default(8)
  gstPercent!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Boolean)
  @Default(false)
  gstEdited!: boolean;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  gstValue!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => Number)
  amount!: number;

  @Expose({ groups: [EFinanceCalculatingOrigin.PROGRAMMATICALLY, EFinanceCalculatingOrigin.USER_INPUT] })
  @Type(() => SupplierCreditNoteRecordDto)
  details!: SupplierCreditNoteRecordDto[];
}
