import { BaseModel, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IIr8aAmountDto } from '../interfaces';

export class Ir8aAmountCreateDto extends BaseModel implements IIr8aAmountDto {
  @Expose()
  amount!: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  effectiveDate!: string;

  @Expose()
  salespersonId!: number;

  @Expose()
  companyId!: number;
}

export class Ir8aAmountUpdateDto extends BaseModel implements Partial<IIr8aAmountDto> {
  @Expose()
  amount?: number;

  @Expose()
  description?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  effectiveDate?: string;

  @Expose()
  companyId!: number;
}
