import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'itemInArray',
})
export class ItemInArrayPipe implements PipeTransform {
  transform(id: unknown, arrayOfId: string[] | number[]): boolean {
    if (this._isNumbers(arrayOfId) && typeof id === 'number') {
      return this._isNumberInNumberArray(id, arrayOfId);
    }

    if (this._isStrings(arrayOfId) && typeof id === 'string') {
      return this._isStringInStringArray(id, arrayOfId);
    }
    return false;
  }

  _isNumberInNumberArray(id: number, arrayOfId: number[]): boolean {
    return arrayOfId.includes(id);
  }

  _isStringInStringArray(id: string, arrayOfId: string[]): boolean {
    return arrayOfId.includes(id);
  }

  _isNumbers(array: number[] | string[]): array is number[] {
    return typeof array[0] === 'number';
  }

  _isStrings(array: number[] | string[]): array is string[] {
    return typeof array[0] === 'string';
  }
}
