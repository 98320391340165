import { createFeature, createReducer, on } from '@ngrx/store';
import { PermissionModel } from '@shared/data-access/models';
import { UserPermissionAction } from '../actions';

export const featureName = 'userPermission';

export interface DataState {
  detail: PermissionModel | null;
}
export const initialState: DataState = {
  detail: null,
};
export const userPermissionFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(UserPermissionAction.getDetail, (state, prop: { item: PermissionModel }) => ({ ...state, detail: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = userPermissionFeature;
