import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IBatchingApprovalHistory } from '../interfaces';

export class BatchingApprovalHistoryModel extends BaseModel implements IBatchingApprovalHistory {
  @Expose()
  id!: 4;

  @Expose()
  actualComm!: number;

  @Expose()
  batchId!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy!: number;

  @Expose()
  deletedAt!: string;

  @Expose()
  deletedBy!: number;

  @Expose()
  itemCodes!: string[];

  @Expose()
  rate!: number;

  @Expose()
  totalComm!: number;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy!: number;

  @Expose()
  transactions!: { transactionCode: string; unitNo: string }[];

  @Expose()
  chosenTransaction!: string;
}
