import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IOneMapResponse } from '../../data-access/interfaces';
import { PaginationAdapter } from '../../data-access/adapters';
import { OneMapResultModel } from '../../data-access/models';

@Injectable()
export class OneMapApiService {
  // static URL = 'https://developers.onemap.sg/commonapi';
  static URL = 'https://www.onemap.gov.sg/api/common/elastic';
  static SEARCH_POINT = 'search';

  constructor(private _http: HttpClient) {}

  search(query: { searchVal: string; returnGeom: 'Y' | 'N'; getAddrDetails?: 'Y' | 'N'; pageNum?: number }) {
    return this._http.get<IOneMapResponse>(OneMapApiService.URL + '/' + OneMapApiService.SEARCH_POINT, { params: query }).pipe(
      map(
        (res: IOneMapResponse) =>
          new PaginationAdapter(OneMapResultModel, {
            items: res.results,
            meta: {
              page: res.pageNum,
              pageCount: res.totalNumPages,
            },
          })
      )
    );
  }
}
