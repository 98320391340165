import { EPaymentGeneratorStatus } from '../enums';
import { PaymentGeneratorPaymentMode } from '../types/payment-generator.type';
import { IBankAccount } from './bank-account.interface';
import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { IInvoicePayable } from './invoice-payable.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IMemoPayable } from './memo-payable.interface';
import { IProjectTransaction } from './project-transaction.interface';
import { ISupplierInvoice } from './supplier-invoice-enhanced.interface';
import { ITaxInvoice } from './tax-invoice-enhanced.interface';
export type PaymentGeneratorStatus = 'Selected' | 'EmailSent' | 'Assigned' | 'Posted';
export interface IPaymentGenerator {
  id: number;
  code: string;
  bankReturnFile: string;
  paymentMethodData: IPaymentMethodData;
  // receiptDateFrom: Date;
  // receiptDateTo: Date;
  // paymentDate: Date;
  receiptDateFrom: string;
  receiptDateTo: string;
  paymentDate: string;
  includeAdjustment: boolean;
  includeMiscCreditNote: boolean;
  payProfitSharingonly: boolean;
  payNormalCommissionOnly: boolean;
  excludePartialReceipt: boolean;
  includeMiscLinkAr: boolean;
  autoRunDataCompute: boolean;
  profitSharingCommmission: boolean;

  excludeSupplierInvoiceIds: number[];
  excludeSupplierInvoices: ISupplierInvoice[];

  excludePayeeIds: number[];
  excludePayees: IContactCustomerAndSupplier[];

  excludeTransactionCodes: string[];
  excludeTransactions: IProjectTransaction[];

  excludeTaxInvoiceIds: number[];
  excludeTaxInvoices: ITaxInvoice[];

  includeTransactionCodes: number[];
  includeTransactions: IProjectTransaction[];

  includeTaxInvoiceIds: number[];
  includeTaxInvoices: ITaxInvoice[];

  // selectedDate: Date;
  // computatedDate: Date;
  // createdDate: Date;
  // updatedDate: Date;
  selectedDate: string;
  computatedDate: string;
  createdDate: string;
  updatedDate: string;
  // status: PaymentGeneratorStatus;
  status: EPaymentGeneratorStatus;
}

export interface IPaymentGeneratorFilterRecord {
  paymentGenerator: IPaymentGenerator;
  supplierInvoice: IMemoPayable;
  taxInvoice: IInvoicePayable;
  transaction: IProjectTransaction;
}

export interface IPaymentGeneratorSummary {
  totalSelected: number;
}

export interface IPaymentMethodData {
  paymentMode: PaymentGeneratorPaymentMode;
  bankAccountId: number;
  bankAccount: IBankAccount;
  // submissionDate: Date;
  // valueDate: Date;
  submissionDate: string;
  valueDate: string;
  account: ILedgerAccount;
  accountId: number;
  accountCode: string;
  description: string;
  chequeNumberFrom: string;
}
