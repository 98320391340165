import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { ITransactedType } from '../interfaces';
import { ERebateBy } from '@shared/data-access/enums';

export class TransactedTypeModel extends BaseModel implements ITransactedType {
  @Expose()
  type!: string;

  @Expose()
  specifyAmount!: number;

  @Expose()
  absorbBy!: string;

  @Expose()
  fromAgent!: number;

  @Expose()
  fromCompany!: number;

  @Expose()
  rebateBy!: ERebateBy;

  @Expose()
  currency!: string;

  @Expose()
  foreignAmount!: number;

  @Expose()
  foreignCurrency!: string;

  @Expose()
  special?: boolean;
}
