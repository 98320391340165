import { Component } from '@angular/core';

@Component({
  selector: 'red-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss']
})
export class RedAppVersionComponent  {


}
