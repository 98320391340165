import { IPhoneNumber } from '@red/data-access';
import { ILedgerAccount } from './ledger-account.interface';
import { IUserAccount } from './user-account.interface';
import { ContactType, SyncEmailName } from '../enums';

export type IContactStatusBase = 'active' | 'inactive';

export interface IContactCategoryBase {
  id: number;
  code: string;
  name: string;
  isActive: boolean;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IContactEmailBase {
  id: number;
  email: string;
  syncName?: SyncEmailName;
}

export interface IContactAddressBase {
  id: number;
  address: string;
  country: string;
  default: boolean;
  postcode: number;
}

export interface IContactBase {
  id: number;
  type: ContactType;
  code: string;
  name: string;
  gstRegNo: string;
  businessRegNo: string;
  ceaRegNo: string;
  categoryId: number;
  category: IContactCategoryBase;
  email: string;
  emails: IContactEmailBase[];
  phone: IPhoneNumber;
  fax: IPhoneNumber;
  paidAccountId: number;
  accountPayableCode: string;
  paidAccount: ILedgerAccount;
  receiveAccountId: number;
  accountReceivableCode: string;
  receiveAccount: ILedgerAccount;
  creditTerm: number;
  creditLimit: number;
  gstRegistered: boolean;
  remark: string;
  status: IContactStatusBase;
  // commerceDate: Date;
  commerceDate: string;
  updatedBy: IUserAccount;
  // updatedAt: Date;
  updatedAt: string;
}
