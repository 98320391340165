import { BaseModel, Default, FormattedDateString, IsoString, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { PaymentMemoType, PaymentMode, PaymentType } from '../types';
import { LedgerAccountDto } from './ledger-account.dto';
export class PaymentDealValueDto extends BaseModel {
  @Expose()
  @Default(0)
  @Type(() => Number)
  subAmount!: number;
}

export class RateGroupDealValueDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  tax!: number;
}

export class PaymentRecordMetadataDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  taxPercent!: number;

  @Expose()
  @Default(false)
  isEdit!: boolean;
}

export class PaymentRecordDto extends BaseModel {
  @Expose()
  id?: number;

  @Expose()
  type!: string;

  @Expose()
  paymentId?: number;

  @Expose()
  accountId!: number;

  @Expose()
  account?: LedgerAccountDto;

  @Expose()
  @Transform((object) => {
    if (object?.obj?.account) {
      return object?.obj?.account?.code
    }
    return object?.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  profitCenterId?: number;

  @Expose()
  @Transform(({ value }) => value || null)
  paymentMemoType?: PaymentMemoType;

  @Expose()
  paymentMemoDetailId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => PaymentDealValueDto)
  debit!: PaymentDealValueDto;

  @Expose()
  @Default({ subAmount: 0 })
  @Type(() => PaymentDealValueDto)
  credit!: PaymentDealValueDto;

  @Expose()
  @Default(false)
  isAdvance!: boolean;

  @Expose()
  @Default(false)
  isTaxable!: boolean;

  @Expose()
  invoiceId!: number;

  @Expose()
  @Default(1)
  @Type(() => Number)
  invoiceRate!: number;

  @Expose()
  taxDescription!: string;

  @Expose()
  taxAccountCode!: string;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  gstCharged!: number;

  @Expose()
  @Default('gstExclusive')
  gstType!: string;

  @Expose()
  @Default('percentage')
  taxUnit!: string;

  @Expose()
  @Type(() => Number)
  taxValue!: number;

  @Expose()
  @Type(() => PaymentRecordMetadataDto)
  metadata!: PaymentRecordMetadataDto;

  @Expose()
  @Type(() => Number)
  sequence?: number;

  @Expose()
  transactionNo?: string;

  @Expose()
  remarks?: string;
}

export class PaymentPostingDto extends BaseModel {
  @Expose()
  id?: number;

  @Expose()
  paymentId?: number;

  @Expose()
  accountId!: number;

  @Expose()
  account?: LedgerAccountDto;

  @Expose()
  @Transform((object) => {
    if (object?.obj?.account) {
      return object?.obj?.account?.code
    }
    return object?.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  profitCenterId?: number;

  @Expose()
  description!: string;

  @Expose()
  @Default(0)
  @Type(() => Number)
  debit!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  credit!: number;

  @Expose()
  amount!: number;

  @Expose()
  @Default(1)
  sequence?: number;
}

export class PaymentCreateDto extends BaseModel {
  @Expose()
  type!: PaymentType;

  @Expose()
  mode!: PaymentMode;

  @Expose()
  payerName!: string;

  @Expose()
  contactId?: number;

  @Expose()
  account?: LedgerAccountDto;

  @Expose()
  accountId?: number;

  @Expose()
  @Transform((object) => {
    if (object?.obj?.account) {
      return object?.obj?.account?.code
    }
    return object?.obj?.accountCode
  })
  accountCode?: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  paymentDate!: string;

  @Expose()
  currency!: string;

  @Expose()
  chequeId?: number;

  @Expose()
  reference?: string;

  @Expose()
  templateId?: number;

  @Expose()
  @Type(() => PaymentRecordDto)
  details!: PaymentRecordDto[];

  @Expose()
  @Type(() => PaymentPostingDto)
  postings!: PaymentPostingDto[];
}

export class PaymentUpdateDto extends BaseModel {
  @Expose()
  type!: PaymentType;

  @Expose()
  mode!: PaymentMode;

  @Expose()
  payerName!: string;

  @Expose()
  contactId?: number;

  @Expose()
  accountId?: number;

  @Expose()
  account?: LedgerAccountDto;

  @Expose()
  @Transform((object) => {
    if (object?.obj?.account) {
      return object?.obj?.account?.code
    }
    return object?.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  paymentDate!: string;

  @Expose()
  currency!: string;

  @Expose()
  chequeId?: number;

  @Expose()
  reference?: string;

  @Expose()
  templateId?: number;

  @Expose()
  @Type(() => PaymentRecordDto)
  details!: PaymentRecordDto[];

  @Expose()
  @Type(() => PaymentPostingDto)
  postings!: PaymentPostingDto[];
}

export class PaymentDealValueReqDto extends BaseModel {
  @Expose()
  @Default(0)
  @Type(() => Number)
  subAmount!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  subTotalAmount!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  totalAmount!: number;

  @Expose()
  @Type(() => RateGroupDealValueDto)
  rateGroup!: RateGroupDealValueDto;
}

export class PaymentRecordPostingReqDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  account?: LedgerAccountDto;

  @Expose()
  @Transform((object) => {
    if (object?.obj?.account) {
      return object?.obj?.account?.code
    }
    return object?.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => Number)
  profitCenterId!: number;

  @Expose()
  postingDescription!: string;

  @Expose()
  @Default({ amount: 0, rateGroup: {}, subAmount: 0, subTotalAmount: 0, totalAmount: 0 })
  @Type(() => PaymentDealValueReqDto)
  debit!: PaymentDealValueReqDto;

  @Expose()
  @Default({ amount: 0, rateGroup: {}, subAmount: 0, subTotalAmount: 0, totalAmount: 0 })
  @Type(() => PaymentDealValueReqDto)
  credit!: PaymentDealValueReqDto;

  @Expose()
  isTaxable!: boolean;

  @Expose()
  @Type(() => Number)
  taxAmount!: number;

  @Expose()
  @Type(() => Number)
  taxAccountId!: number;

  @Expose()
  taxAccountCode!: string;

  @Expose()
  postingTaxDescription!: string;
}

export class PaymentPostingReqDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  accountId!: number | null;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => PaymentRecordPostingReqDto)
  details!: PaymentRecordPostingReqDto[];
}
