// core
import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// share
import { RecruitmentApplicationCreateDto, RecruitmentCreateDto, RecruitmentUpdateDto, RecruitmentActionDto } from '@shared/data-access/dto';
import { IRecruitment } from '@shared/data-access/interfaces';
import { RecruitmentModel } from '@shared/data-access/models';
import { PaginationAdapter } from '@red/data-access';
import { ApiService, IApiOption } from '@red/api';

@Injectable({
  providedIn: 'root',
})
export class RecruitmentApiService {
  static ROOT_POINT = 'hrm/recruitments';
  static PUBLIC_POINT = 'hrm/recruitments/public';
  static WITH_DRAW_POINT = 'hrm/recruitments/portal/withdraw';

  constructor(private readonly _apiService: ApiService) { }

  create(data: RecruitmentCreateDto): Observable<RecruitmentModel> {
    return this._apiService.post(RecruitmentApiService.ROOT_POINT, data).pipe(map((res: IRecruitment) => RecruitmentModel.fromJson(res)));
  }

  get(id: number): Observable<RecruitmentModel> {
    return this._apiService.get([RecruitmentApiService.ROOT_POINT, id].join('/')).pipe(map(res => RecruitmentModel.fromJson(res)));
  }

  update(id: number, data: RecruitmentUpdateDto): Observable<unknown> {
    return this._apiService.patch([RecruitmentApiService.ROOT_POINT, id].join('/'), data, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<RecruitmentModel>> {
    return this._apiService.get(RecruitmentApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(RecruitmentModel, data)));
  }

  delete(ids: number[], option?: IApiOption): Observable<unknown> {
    return this._apiService.delete(RecruitmentApiService.ROOT_POINT, { ids }, option);
  }

  // Means Approved
  action(dto: RecruitmentActionDto[], option?: IApiOption): Observable<unknown> {
    return this._apiService.post([RecruitmentApiService.ROOT_POINT, 'action'].join('/'), { recruitments: dto }, option);
  }

  reject(dto: RecruitmentActionDto[], option?: IApiOption): Observable<unknown> {
    return this._apiService.post([RecruitmentApiService.ROOT_POINT, 'reject'].join('/'), { recruitments: dto }, option);
  }

  rework(dto: RecruitmentActionDto[], option?: IApiOption): Observable<unknown> {
    return this._apiService.post([RecruitmentApiService.ROOT_POINT, 'rework'].join('/'), { recruitments: dto }, option);
  }

  register(dto: RecruitmentActionDto[], option?: IApiOption): Observable<unknown> {
    return this._apiService.post([RecruitmentApiService.ROOT_POINT, 'register'].join('/'), { recruitments: dto }, option);
  }

  withdraw(dto: RecruitmentActionDto[], option?: IApiOption): Observable<unknown> {
    return this._apiService.post([RecruitmentApiService.ROOT_POINT, 'withdraw'].join('/'), { recruitments: dto }, option);
  }

  applicantSubmit(uuid: string, dto: RecruitmentApplicationCreateDto): Observable<unknown> {
    return this._apiService.post([RecruitmentApiService.PUBLIC_POINT, uuid, 'submit'].join('/'), dto, { excludeFields: [''] });
  }

  downloadAgreementPdf(id: number): Observable<unknown> {
    return this._apiService.get([RecruitmentApiService.ROOT_POINT, id, 'download-pdf'].join('/'), null, {
      requestOptions: { responseType: 'arraybuffer' },
      pretreatmentResponse: false,
    });
  }

  searchMyRecruitment(query = {}, option?: IApiOption): Observable<PaginationAdapter<RecruitmentModel>> {
    return this._apiService
      .get([RecruitmentApiService.ROOT_POINT, 'my-recruitment'].join('/'), query, option)
      .pipe(map(data => new PaginationAdapter(RecruitmentModel, data)));
  }

  getMyRecruitment(id: number): Observable<RecruitmentModel> {
    return this._apiService.get([RecruitmentApiService.ROOT_POINT, 'my-recruitment', id].join('/')).pipe(map(res => RecruitmentModel.fromJson(res)));
  }

  withdrawMyRecruitment(dto: RecruitmentActionDto[], option?: IApiOption): Observable<unknown> {
    return this._apiService.post([RecruitmentApiService.WITH_DRAW_POINT].join('/'), { recruitments: dto }, option);
  }

  searchTeamRecruitment(query = {}, option?: IApiOption): Observable<PaginationAdapter<RecruitmentModel>> {
    return this._apiService
      .get([RecruitmentApiService.ROOT_POINT, 'team-recruitment'].join('/'), query, option)
      .pipe(map(data => new PaginationAdapter(RecruitmentModel, data)));
  }

  getTeamRecruitment(id: number): Observable<RecruitmentModel> {
    return this._apiService.get([RecruitmentApiService.ROOT_POINT, 'team-recruitment', id].join('/')).pipe(map(res => RecruitmentModel.fromJson(res)));
  }
}
