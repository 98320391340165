import { Pipe, PipeTransform } from '@angular/core';
import { isPossiblePhoneNumber, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';
import { IPhoneNumber } from '../../data-access/interfaces';
import { formatTelephoneNumber } from './util';

@Pipe({
  name: 'telephoneNumber',
})
export class TelephoneNumberPipe implements PipeTransform {
  transform(val?: IPhoneNumber): string {
    if (!val || !val.phoneNumber || !val.countryCode) return '';
    return formatTelephoneNumber(val);
  }
}

