import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { WhiteSpacePipe } from '../white-space/white-space.pipe';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  constructor(private _titleCasePipe: TitleCasePipe, private _whiteSpacePipe: WhiteSpacePipe) {}
  transform(data: any, originalValue?: boolean) {
    return Object.keys(data).map(item => {
      return {
        key: item,
        name: originalValue ? data[item] : this._titleCasePipe.transform(this._whiteSpacePipe.transform(data[item])),
      };
    });
  }
}
