import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ILookupTable, ILookupTableCell, ILookupTableColumn } from '../interfaces';
import { LookupTableDetailedFormFieldType } from '../types';

export class LookupTableColumnModel extends BaseModel implements ILookupTableColumn {
  @Expose()
  displayName!: string;

  @Expose()
  fieldName!: string;

  @Expose()
  @Transform(({ value }) => (typeof value === 'string' ? value.toLowerCase() : value))
  fieldType!: LookupTableDetailedFormFieldType;

  @Expose()
  fieldValidate!: any;

  @Expose()
  sequence?: number;
}

export class LookupTableCellModel extends BaseModel implements ILookupTableCell {
  @Expose()
  fieldName!: string;

  @Expose()
  value!: unknown;

  // @Expose()
  // fmt!: string;

  // type!: ValueTypeEnum;
}

export class LookupTableModel extends BaseModel implements ILookupTable {
  @Expose()
  id!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  companyAccess!: boolean;

  @Expose()
  category!: string;

  @Expose()
  type!: string;

  @Expose()
  key!: string;

  @Expose()
  description!: string;

  @Expose()
  displayName!: string;

  @Expose()
  @Type(() => LookupTableColumnModel)
  cols!: LookupTableColumnModel[];
}

export class LookupTableDetailModel extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  lookupSetupId!: number;

  @Expose()
  @Type(() => LookupTableCellModel)
  cells!: LookupTableCellModel[];
}
