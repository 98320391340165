import { BaseModel, FormattedDateString, IPhoneNumber, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ContactType, Status } from '../enums';
import { ContactEmailBaseDto } from './contact-base.dto';
import { LedgerAccountModel } from '../models';

export class SupplierCreateDto extends BaseModel {
  @Expose()
  type!: ContactType;

  @Expose()
  name!: string;

  // @Expose()
  // code!: string;

  // @Expose()
  // groupName!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  commerceDate!: string;

  // @Expose()
  // category!: string;

  @Expose()
  @Type(() => Number)
  categoryId!: number;

  @Expose()
  @Transform(({ value }) => value.filter((item: ContactEmailBaseDto) => item.email))
  @Type(() => ContactEmailBaseDto)
  emails!: ContactEmailBaseDto[];

  @Expose()
  phone!: IPhoneNumber;

  @Expose()
  fax!: string;

  @Expose()
  status!: Status;

  @Expose()
  @Type(() => Number)
  creditTerm!: number;

  @Expose()
  @Type(() => Number)
  creditLimit!: number;

  @Expose()
  @Type(() => Number)
  receiveAccountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  receiveAccount!: LedgerAccountModel;

  @Expose()
  @Transform((oject) => {
    if (oject.obj?.receiveAccount) {
      return oject.obj?.receiveAccount?.code
    }
    return oject.obj?.accountReceivableCode
  })
  accountReceivableCode!: string;

  @Expose()
  @Type(() => Number)
  paidAccountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  paidAccount!: LedgerAccountModel;

  @Expose()
  @Transform((oject) => {
    if (oject.obj?.paidAccount) {
      return oject.obj?.paidAccount?.code
    }
    return oject.obj?.accountPayableCode
  })
  accountPayableCode!: string;

  @Expose()
  businessRegNo!: string;

  @Expose()
  gstRegistered!: boolean;

  @Expose()
  gstRegNo!: string;

  @Expose()
  remark!: string;

  @Expose()
  isWithHold!: boolean;
}

export class SupplierUpdateDto extends BaseModel {
  @Expose()
  type!: ContactType;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  category!: string;

  @Expose()
  @Type(() => Number)
  categoryId!: number;

  @Expose()
  @Transform(({ value }) => value.filter((item: ContactEmailBaseDto) => item.email))
  @Type(() => ContactEmailBaseDto)
  emails!: ContactEmailBaseDto[];

  @Expose()
  phone!: IPhoneNumber;

  @Expose()
  fax!: string;

  @Expose()
  status!: string;

  @Expose()
  @Type(() => Number)
  creditTerm!: number;

  @Expose()
  @Type(() => Number)
  creditLimit!: number;

  @Expose()
  @Type(() => Number)
  receiveAccountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  receiveAccount!: LedgerAccountModel;

  @Expose()
  @Transform((oject) => {
    if (oject.obj?.paidAccount) {
      return oject.obj?.receiveAccount?.code
    }
    return oject.obj?.accountReceivableCode
  })
  accountReceivableCode!: string;

  @Expose()
  @Type(() => Number)
  paidAccountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  paidAccount!: LedgerAccountModel;

  @Expose()
  @Transform((oject) => {
    if (oject.obj?.paidAccount) {
      return oject.obj?.paidAccount?.code
    }
    return oject.obj?.accountPayableCode
  })
  accountPayableCode!: string;

  @Expose()
  businessRegNo!: string;

  @Expose()
  gstRegNo!: string;

  @Expose()
  remark!: string;

  @Expose()
  groupName!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => value ?? null)
  commerceDate!: string | null;

  @Expose()
  gstRegistered!: boolean;

  @Expose()
  isWithHold!: boolean;
}
