import { BaseModel } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import {
  IProjectSearchEngine,
  IProjectSearchEngineBlankFormAdjExternal,
  IProjectSearchEngineExternal,
  IProjectSearchEngineHIECBExternal,
  IProjectSearchEngineInternal,
  IStatusDescription,
} from '../interfaces';
import { PROJECT_TRANSACTION_VERIFY_STATUS } from '../data';
import { EProjectTransactionStatus } from '../enums';
import { EAgentType } from '../enums/agent-type.enum';

export class ProjectSearchEngineExternalModel extends BaseModel implements IProjectSearchEngineExternal {
  @Expose()
  @Type(() => Number)
  businessUnitId?: number;

  @Expose()
  @Type(() => Number)
  contactId!: number;

  @Expose()
  contactCode!: string;

  @Expose()
  contactName!: string;

  @Expose()
  @Type(() => Number)
  invoiceId?: number;

  @Expose()
  invoiceDeletedAt!: Date | null;

  @Expose()
  role!: string;

  @Expose()
  subTotalAmount!: number;

  @Expose()
  totalAmount!: number;

  @Expose()
  taxAmount!: number;

  @Expose()
  get displayName() {
    return `${this.contactName} (${this.contactCode})`;
  }

  @Expose()
  get displayType() {
    return this.role === 'AgentCobrokeExternalEntity' ? EAgentType.ECB : '';
  }
}
export class ProjectSearchEngineInternalModel extends BaseModel implements IProjectSearchEngineInternal {
  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  businessName!: string;

  @Expose()
  ceaRegNo!: string;

  @Expose()
  nricName!: string;

  @Expose()
  role!: string;

  @Expose()
  get displayName() {
    return `${this.businessName} (${this.ceaRegNo})`;
  }

  @Expose()
  get displayType() {
    return this.role === 'AgentCobrokeInternalEntity' ? EAgentType.ICB : '';
  }
}
export class ProjectSearchEngineBlankFormAdjExternalModel extends BaseModel implements IProjectSearchEngineBlankFormAdjExternal {
  @Expose()
  @Type(() => Number)
  blankFormId!: number;

  @Expose()
  @Type(() => Number)
  contactId!: number;

  @Expose()
  name!: string;

  @Expose()
  receivedValue!: number;

  @Expose()
  @Type(() => Number)
  invoiceId!: number | null;

  @Expose()
  blankFormCode!: string;
}

export class ProjectSearchEngineModel extends BaseModel implements IProjectSearchEngine {
  @Expose()
  id!: number;

  @Expose()
  batchCode!: string;

  @Expose()
  batchId!: number;

  @Expose()
  batchName!: string;

  @Expose()
  batchStatus!: string;

  @Expose()
  @Type(() => ProjectSearchEngineBlankFormAdjExternalModel)
  blankFormAdjExternals!: ProjectSearchEngineBlankFormAdjExternalModel[];

  @Expose()
  code!: string;

  @Expose()
  createdAt!: string;

  @Expose()
  deletedAt!: string;

  @Expose()
  entity!: string;

  @Expose()
  @Type(() => ProjectSearchEngineInternalModel)
  internals!: ProjectSearchEngineInternalModel[];

  @Expose()
  // @Transform(({ value }) => {
  //   return value.filter((x: any) => !x.businessUnitId);
  // })
  @Type(() => ProjectSearchEngineExternalModel)
  externals!: ProjectSearchEngineExternalModel[];

  @Expose()
  @Transform(({ obj }) => {
    let hiExternal = null;

    const isTransactionValid = !!obj.id && ['approved'].includes(obj.batchStatus);
    const isExternalsValid = !!obj.externals && obj.externals.length > 0;
    const hiExternalInvoice = obj.externals.filter((x: any) => !!x.businessUnitId);

    if (isTransactionValid && isExternalsValid && hiExternalInvoice.length > 0) {
      hiExternal = hiExternalInvoice[0];
    }

    return hiExternal;
  })
  hiExternal!: IProjectSearchEngineHIECBExternal | null;

  @Expose()
  @Transform(({ obj }) => {
    let externalBds = null;
    const isTransactionValid = !!obj.id && ['approved'].includes(obj.batchStatus);
    const isExternalsValid = !!obj.externals && obj.externals.length > 0;
    const externalBdsInvoice = obj.externals;

    if (isTransactionValid && isExternalsValid && externalBdsInvoice.length > 0) {
      externalBds = externalBdsInvoice[0];
    }
    return externalBds;
  })
  externalBds!: IProjectSearchEngineHIECBExternal;

  @Expose()
  hiInvoiceCode!: string;

  @Expose()
  hiInvoiceDate!: string;

  @Expose()
  invoiceCode!: string;

  @Expose()
  invoiceDate!: string;

  @Expose()
  optionDate!: string;

  @Expose()
  optionNo!: string;

  @Expose()
  projectCode!: string;

  @Expose()
  projectCommInternationalCode!: string;

  @Expose()
  projectCommInternationalId!: number;

  @Expose()
  projectCommInternationalName!: string;

  @Expose()
  projectCommissionCode!: string;

  @Expose()
  projectCommissionId!: number;

  @Expose()
  projectCommissionName!: string;

  @Expose()
  projectId!: number;

  @Expose()
  projectName!: string;

  @Expose()
  salespersonBusinessName!: string;

  @Expose()
  salespersonCeaRegNo!: string;

  @Expose()
  salespersonId!: number;

  @Expose()
  salespersonNricName!: string;

  @Expose()
  salespersonRole!: string;

  @Expose()
  unitId!: number;

  @Expose()
  unitNo!: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  subTotalAmount!: number;

  @Expose()
  totalAmount!: number;

  @Expose()
  get displaySalesperson() {
    return `${this.salespersonBusinessName} (${this.salespersonCeaRegNo})`;
  }

  get canCreateECBInvoice(): boolean {
    return !!this.id && this.externals && this.externals.length > 0 && ['approved'].includes(this.batchStatus);
  }

  get canCreateECBBdsInvoice(): boolean {
    return !!this.id && this.externalBds && ['approved'].includes(this.batchStatus);
  }

  @Expose()
  @Transform(({ obj }) => {
    return !!obj.id && obj.blankFormAdjExternals && obj.blankFormAdjExternals.length > 0 && ['approved'].includes(obj.batchStatus);
  })
  canCreateECBAdjInvoice!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return !!obj.id && ['approved'].includes(obj.batchStatus);
  })
  canCreateHIECBInvoice!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    return PROJECT_TRANSACTION_VERIFY_STATUS[obj.status as EProjectTransactionStatus];
  })
  statusDescription!: IStatusDescription;
}
