<div class="box-search" [ngClass]="{focused: isFocus}" >
    <div class="box-search__icon">
        <mat-icon>search</mat-icon>
    </div>
    <input
        class="box-search__input"
        [placeholder]="placeholder"
        [formControl]="inputControl"
        (focus)="forcusInput()"
        (blur)="blur()"
        type="text"
    />
    <div *ngIf="data" class="box-search__icon clear" (click)="clear()">
        <mat-icon>clear</mat-icon>
    </div>
</div>
