import { BaseModel, Default, FormattedDateString, PhoneNumber, StartOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EBusinessDirector, EProjectPropertyType, EProjectTransactionPropertyType, ERebateBy } from '../enums';
import { ProjectTransactionBuyerDto } from './project-transaction.dto';

export class ProjectTransactionTypeClonedDto extends BaseModel {
  @Expose()
  type!: string;

  @Expose()
  @Type(() => Number)
  specifyAmount!: number;

  @Expose()
  absorbBy!: string;

  @Expose()
  @Type(() => Number)
  fromAgent!: number;

  @Expose()
  @Type(() => Number)
  fromCompany!: number;

  @Expose()
  rebateBy!: ERebateBy;

  @Expose()
  currency!: string;

  @Expose()
  @Type(() => Number)
  foreignAmount!: number;

  @Expose()
  foreignCurrency!: string;
}

export class TransactedPercentItemClonedDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  value!: number;

  @Expose()
  @Type(() => Number)
  percent!: number;

  @Expose()
  @Default({
    agent: true,
    hr: true,
  })
  absorbType!: { agent: boolean; hr: boolean };
}

export class TransactedPercentClonedDto extends BaseModel {
  @Expose()
  @Type(() => TransactedPercentItemClonedDto)
  cashAdvance!: TransactedPercentItemClonedDto;

  @Expose()
  @Type(() => TransactedPercentItemClonedDto)
  discount!: TransactedPercentItemClonedDto;

  @Expose()
  @Type(() => TransactedPercentItemClonedDto)
  powerDiscount!: TransactedPercentItemClonedDto;

  @Expose()
  @Type(() => TransactedPercentItemClonedDto)
  incentive!: TransactedPercentItemClonedDto;

  @Expose()
  @Type(() => TransactedPercentItemClonedDto)
  buyerRebate!: TransactedPercentItemClonedDto;
}

export class ProjectTransactionRelatedAgentClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id?: null;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  teamType!: string;

  @Expose()
  @Type(() => Number)
  teamId!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedValue!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedPercent!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Default({
    agent: true,
    hr: true,
  })
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  @Default({})
  @Type(() => TransactedPercentClonedDto)
  transacted!: TransactedPercentClonedDto;

  @Expose()
  @Type(() => Boolean)
  isCobroke!: boolean;

  @Expose()
  @Type(() => Number)
  categoryId!: number;

  @Expose()
  grouping!: string;

  @Expose()
  appTypeCode!: string;

  @Expose()
  attrs!: {
    [key: string]: any;
  };

}

export class ProjectTransactionLeadersClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id?: null;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  @Type(() => Number)
  teamId!: number;

  // @Expose()
  // @Type(() => Number)
  // teamType!: number;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;
}

export class ProjectTransactionRelatedInternalCobrokeClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id?: null;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedValue!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedPercent!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Default({
    agent: true,
    hr: true,
  })
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  @Default({})
  @Type(() => TransactedPercentClonedDto)
  transacted!: TransactedPercentClonedDto;

  @Expose()
  type!: string;

  @Expose()
  teamId?: number;

  @Expose()
  teamType?: string;

  @Expose()
  @Type(() => Number)
  categoryId!: number;

  @Expose()
  grouping!: string;

  @Expose()
  attrs!: {
    [key: string]: any;
  };

  @Expose()
  appTypeCode!: string;
}

export class ProjectTransactionRelatedExternalCobrokeClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id?: null;

  @Expose()
  @Type(() => Number)
  agentId!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedValue!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  transactedPercent!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Default({})
  @Type(() => TransactedPercentClonedDto)
  transacted!: TransactedPercentClonedDto;

  @Expose()
  brokerName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone!: PhoneNumber;

  @Expose()
  attrs!: {
    [key: string]: any;
  };
}

export class ProjectTransactionRelatedPartyClonedDto extends BaseModel {
  @Expose()
  @Type(() => ProjectTransactionRelatedAgentClonedDto)
  main!: ProjectTransactionRelatedAgentClonedDto;

  @Expose()
  @Type(() => ProjectTransactionLeadersClonedDto)
  leaders!: ProjectTransactionLeadersClonedDto[];

  @Expose()
  @Type(() => ProjectTransactionRelatedInternalCobrokeClonedDto)
  internals!: ProjectTransactionRelatedInternalCobrokeClonedDto[];

  @Expose()
  @Type(() => ProjectTransactionRelatedExternalCobrokeClonedDto)
  externals!: ProjectTransactionRelatedExternalCobrokeClonedDto[];
}

export class ProjectTransactionBonusClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id!: null;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;
}

export class ProjectTransactionOverridingClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id!: null;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;
}

export class ProjectTransactionPoolClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id!: null;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Default(false)
  @Type(() => Boolean)
  isActive!: boolean;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  effectiveDate!: string;
}

export class ProjectTransactionBdsClonedDto extends BaseModel {
  @Expose()
  @Transform(({ value }) => null)
  id!: null;

  @Expose()
  @Type(() => Number)
  splitMatrixId!: number;

  @Expose()
  @Default(EBusinessDirector.BUSINESS_DIRECTOR)
  type!: `${EBusinessDirector}`;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => Number)
  salespersonId!: number;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedDirectPercent!: number;
}

export class ProjectTransactionBuyerClonedDto extends ProjectTransactionBuyerDto {
  @Expose()
  @Transform(({ value }) => null)
  override id!: number;
}

export class ProjectTransactionBonusCloneSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionBonusClonedDto)
  data!: ProjectTransactionBonusClonedDto[];
}
export class ProjectTransactionOverridingCloneSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionOverridingClonedDto)
  data!: ProjectTransactionOverridingClonedDto[];
}
export class ProjectTransactionPoolCloneSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionPoolClonedDto)
  data!: ProjectTransactionPoolClonedDto[];
}
export class ProjectTransactionBuyerCloneSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionBuyerDto)
  data!: ProjectTransactionBuyerDto[];
}

export class ProjectTransactionBdsCloneSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  transactionId!: number;

  @Expose()
  @Default([])
  @Type(() => ProjectTransactionBdsClonedDto)
  data!: ProjectTransactionBdsClonedDto[];
}

export class ProjectTransactionCreateClonedDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  unitId!: number;

  @Expose()
  optionNo!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  optionDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) return null;
    return value;
  })
  eotDate!: string;

  @Expose()
  // propertyType!: EProjectTransactionPropertyType;
  propertyType!: EProjectPropertyType;

  @Expose()
  landAreaSqft!: number;

  @Expose()
  landAreaSqm!: number;

  @Expose()
  @Type(() => Number)
  projectCommissionId!: number;

  @Expose()
  @Type(() => Number)
  projectCommInternationalId!: number;

  @Expose()
  @Type(() => ProjectTransactionTypeClonedDto)
  transactedTypes!: ProjectTransactionTypeClonedDto[];

  @Expose()
  @Type(() => ProjectTransactionTypeClonedDto)
  hiTransactedTypes!: ProjectTransactionTypeClonedDto[];

  @Expose()
  @Type(() => Number)
  transactedPrice!: number;

  @Expose()
  @Type(() => ProjectTransactionRelatedPartyClonedDto)
  relatedAgent!: ProjectTransactionRelatedPartyClonedDto;

  @Expose()
  remarks!: string;

  @Expose()
  buyer!: {
    remark: string;
  };

  @Expose()
  @Type(() => Number)
  originalTransactedPrice?: number;

  @Expose()
  originalCurrencyId?: number;

  @Expose()
  originalCurrencyCode?: string;

  @Expose()
  @Default('SGD')
  currencyCode?: string;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionBdsCloneSetUpDto || null)
  bds!: ProjectTransactionBdsCloneSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionBonusCloneSetUpDto || null)
  bonuses!: ProjectTransactionBonusCloneSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionOverridingCloneSetUpDto || null)
  overridings!: ProjectTransactionOverridingCloneSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionPoolCloneSetUpDto || null)
  pools!: ProjectTransactionPoolCloneSetUpDto | null;

  @Expose({ groups: ['full'] })
  @Type(() => ProjectTransactionBuyerCloneSetUpDto || null)
  buyers!: ProjectTransactionBuyerCloneSetUpDto | null;
}
