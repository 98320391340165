import { BaseModel, Default, EndOfDate, FormattedDateString, IsoString, StartOfDate, Timestamp } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { DirectDebitPaymentTransactionCode } from '../types/direct-debit-payment.type';
import { AbstractListFilters } from '@shared/core';
import { LedgerAccountModel } from '../models';

export class DirectDebitPaymentRecordDto extends BaseModel {
  @Expose()
  @Default('SupplierInvoice')
  paymentMemoType!: string;

  @Expose()
  contactId!: number;

  @Expose()
  @Type(() => Number)
  paymentMemoDetailId!: number;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => Number)
  receiptId!: number;
}

export class DirectDebitPaymentCreateDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  submissionDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  valueDate!: string;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  @Transform((oject) => {
    if (oject.obj?.account) {
      return oject.obj?.account?.code
    }
    return oject.obj?.accountCode
  })
  accountCode!: string;


  @Expose()
  @Default('MiscCredit')
  transactionCode!: DirectDebitPaymentTransactionCode;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => DirectDebitPaymentRecordDto)
  items!: DirectDebitPaymentRecordDto[];
}

export class DirectDebitPaymentRecordUpdateDto extends BaseModel {
  @Expose()
  @Transform(({ obj }) => obj.recordId)
  id!: number;

  @Expose()
  @Default('SupplierInvoice')
  paymentMemoType!: string;

  @Expose()
  contactId!: number;

  @Expose()
  @Type(() => Number)
  paymentMemoDetailId!: number;

  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  @Type(() => Number)
  receiptId!: number;
}

export class DirectDebitPaymentUpdateDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  submissionDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  valueDate!: string;

  @Expose()
  @Type(() => Number)
  accountId!: number;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  @Transform((object) => {
    if (object.obj?.account) {
      return object.obj?.account?.code
    }
    return object.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Default('MiscCredit')
  transactionCode!: DirectDebitPaymentTransactionCode;

  @Expose()
  description!: string;

  @Expose()
  @Type(() => DirectDebitPaymentRecordUpdateDto)
  items!: DirectDebitPaymentRecordUpdateDto[];
}

export class DirectDebitPaymentFilters extends AbstractListFilters {
  @Expose()
  type!: string;

  @Expose()
  searchType!: string;

  @Expose()
  code!: string;

  @Expose()
  bankAccount!: string;

  @Expose()
  @StartOfDate()
  @Timestamp()
  startDate!: string;

  @Expose()
  @EndOfDate()
  @Timestamp()
  endDate!: string;

  // ---------- //

  @Expose()
  @StartOfDate()
  @Timestamp()
  valueDateStart!: string;

  @Expose()
  @EndOfDate()
  @Timestamp()
  valueDateEnd!: string;

  @Expose()
  status!: string;

  @Expose()
  updatedBy!: string;

  // ---------- //

  @Expose()
  @StartOfDate()
  @Timestamp()
  updatedAtStart!: string;

  @Expose()
  @EndOfDate()
  @Timestamp()
  updatedAtEnd!: string;

}
