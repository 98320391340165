import { BaseModel, Default, FormattedDateString, PhoneNumber, StartOfDate, EndOfDate } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import {
  EClientType,
  ECommType,
  EFurnished,
  EGstType,
  EPaymentAdvice,
  EResaleAgentType,
  EResaleEmailStatus,
  EResalePaymentMethod,
  EResalePropertyType,
  EResaleSubmitLateReason,
  EResaleType,
} from '../enums/resale.enum';
import { AddressDto } from './address.dto';
import { ContactAddressDto } from './contact-address.dto';
import { CountryModel } from '../models';

export class ResaleAreaDto extends BaseModel {
  @Expose()
  @Default(0)
  @Type(() => Number)
  areaSqft!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  areaSqm!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  landSqft!: number;

  @Expose()
  @Default(0)
  @Type(() => Number)
  landSqm!: number;
}

export class ResaleUpdateRemarksDto extends BaseModel {
  @Expose()
  remarks!: string;

  @Expose()
  paymentMethod!: EResalePaymentMethod;

  @Expose()
  @Type(() => Date)
  // @StartOfDate()
  @FormattedDateString()
  chequeDate!: string;

  @Expose()
  metadata!: object;
}

export class ResaleBillingCommissionDto extends BaseModel {
  @Expose()
  type!: 'percentage' | 'number';

  @Expose()
  value!: number;
}
export class InvoiceOptionItemDto extends BaseModel {
  @Expose()
  @Type(() => Boolean)
  @Default(false)
  isChoosen!: boolean;

  @Expose()
  @Type(() => String)
  @Transform(({ value }) => !value ? null : value)
  @Default(null)
  email!: string;
}

export class InvoiceOptionDto extends BaseModel {
  @Expose()
  @Type(() => InvoiceOptionItemDto)
  agent!: InvoiceOptionItemDto;

  @Expose()
  @Type(() => InvoiceOptionItemDto)
  ecb!: InvoiceOptionItemDto;

  @Expose()
  @Type(() => InvoiceOptionItemDto)
  client!: InvoiceOptionItemDto;

  @Expose()
  @Type(() => InvoiceOptionItemDto)
  solicitor!: InvoiceOptionItemDto;

  @Expose()
  @Type(() => InvoiceOptionItemDto)
  hardcopy!: InvoiceOptionItemDto;
}

export class ResaleDocumentDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  name!: string;

  @Expose()
  url!: string;

  @Expose()
  size!: number;

  @Expose()
  type!: string;

  @Expose()
  @Type(() => Date)
  //@StartOfDate()
  @FormattedDateString()
  createdAt!: string;

  @Expose()
  @Type(() => Date)
  //@StartOfDate()
  @FormattedDateString()
  updatedAt!: string;
}

export class ResaleDocumentSetUpDto extends BaseModel {
  @Expose()
  isOverride!: boolean;

  @Expose()
  resaleId!: number;

  @Expose()
  @Default([])
  @Type(() => ResaleDocumentDto)
  data!: ResaleDocumentDto[];
}

export class GrossCommDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  amount!: number;

  @Expose()
  currency!: string;

  @Expose()
  grossType!: string;

  @Expose()
  includeGst!: boolean;

  @Expose()
  @Type(() => Number)
  subTotalAmount!: number;

  @Expose()
  @Type(() => Number)
  taxAmount!: number;

  @Expose()
  taxType!: 'number' | 'percentage';

  @Expose()
  @Type(() => Number)
  taxValue!: number;

  @Expose()
  @Type(() => Number)
  totalAmount!: number;

  @Expose()
  isStupidFollow!: boolean;
}

export class ResaleBillingMetadataDto extends BaseModel {
  @Expose()
  isCobroke!: boolean;

  @Expose()
  isWithPayment!: boolean;

  @Expose()
  isPropertyAddress!: boolean;

  @Expose()
  isContactAddress!: boolean;

  @Expose()
  isBillToAll!: boolean;
}

export class ResaleMetadataDto extends BaseModel {
  @Expose()
  agent?: string | null;

  @Expose()
  admin?: string | null;

  @Expose()
  ecbGreaterPercentReason?: string;

  @Expose()
  submitLateReason?: {
    type: EResaleSubmitLateReason;
    reason: string;
  };

  @Expose()
  isPC?: boolean;

  @Expose()
  skipEmail?: boolean;

  @Expose()
  emailStatus?: EResaleEmailStatus;

  @Expose()
  isAbleSubmitted?: boolean;

  @Expose()
  isCobroke!: boolean;

  @Expose()
  isWithPayment!: boolean;

  @Expose()
  isPropertyAddress!: boolean;

  @Expose()
  isContactAddress!: boolean;

  @Expose()
  isBillToAll!: boolean;

  @Expose()
  isFirstTimeSubmitted!: boolean;
}

export class ResaleBillingDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  resaleId!: number;

  @Expose()
  name!: string;

  @Expose()
  paymentAdvice!: EPaymentAdvice;

  @Expose()
  contactId!: number;

  @Expose()
  remarks!: string;

  @Expose()
  envDelivery!: boolean;

  @Expose()
  attention!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  billingDate!: string;

  @Expose()
  clientType!: EClientType;

  @Expose()
  clientName!: string;

  @Expose()
  clientNric!: string;

  @Expose()
  @Type(() => PhoneNumber)
  clientContact!: PhoneNumber;

  @Expose()
  clientEmail!: string;

  @Expose()
  clientCountryId!: number;

  @Expose()
  countryId!: number;

  @Expose()
  gstCategoryId!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  @Default(0)
  transactionAmount!: number;

  @Expose()
  @Type(() => Number)
  @Default(0)
  valuationPrice!: number;

  @Expose()
  @Type(() => Number)
  percentage!: number;

  @Expose()
  gstType!: EGstType;

  @Expose()
  gstPercent!: number;

  @Expose()
  @Type(() => GrossCommDto)
  grossComm!: GrossCommDto;

  @Expose()
  @Type(() => Number)
  subAmount!: number;

  @Expose()
  choosenAddressId!: number;

  @Expose()
  @Type(() => ContactAddressDto)
  choosenAddress!: ContactAddressDto;

  // @Expose()
  // @Type(() => ResaleBillingMetadataDto)
  // metadata!: ResaleBillingMetadataDto;

  @Expose()
  @Type(() => AddressDto)
  clientAddress!: AddressDto;

  @Expose()
  billingParty!: string;

  @Expose()
  billingCountryId!: number;

  @Expose()
  @Type(() => CountryModel)
  billingCountry?: CountryModel;

  @Expose()
  @Type(() => AddressDto)
  billingAddress!: AddressDto;

  @Expose()
  @Type(() => Number)
  ecddIds!: number[];

  @Expose()
  paymentMethod!: EResalePaymentMethod;

  @Expose()
  @Type(() => ResaleBillingCommissionDto)
  commission!: ResaleBillingCommissionDto;

  @Expose()
  @Type(() => Date)
  // @StartOfDate()
  @FormattedDateString()
  chequeDate!: string;

  @Expose()
  @Type(() => InvoiceOptionDto)
  invoiceOption?: InvoiceOptionDto;

  @Expose()
  ecddRef!: string;
}

export class ResaleBillingUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'full';

  @Expose()
  @Type(() => ResaleBillingDto)
  data!: ResaleBillingDto;
}

export class ResaleBillingSpecialDto extends BaseModel {
  @Expose()
  envDelivery!: boolean;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  billingDate!: string;

  @Expose()
  @Type(() => InvoiceOptionDto)
  invoiceOption?: InvoiceOptionDto;
}

export class ResaleInternalCoBrokeDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  agentId!: number;

  @Expose()
  @Transform(({ value }) => value?.trim())
  brokerIdNo!: string;

  @Expose()
  brokerName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone!: PhoneNumber;

  @Expose()
  brokerEmail!: string;

  @Expose()
  @Transform(({ value }) => (value ? Number(value) : 0))
  receivedPercent!: number;

  @Expose()
  @Transform(({ value }) => (value ? Number(value) : 0))
  receivedValue!: number;

  @Expose()
  absorbType!: { agent: boolean; hr: boolean };

  @Expose()
  attrs!: any;

  @Expose()
  commType!: ECommType;

  @Expose()
  grouping!: string;
}

export class ResaleExternalCoBrokeDto extends BaseModel {
  @Expose()
  id!: number;

  @Expose()
  type!: EResaleAgentType;

  @Expose()
  contactId!: number;

  @Expose()
  gstType!: EGstType;

  @Expose()
  brokerIdNo!: string;

  @Expose()
  brokerName!: string;

  @Expose()
  @Type(() => PhoneNumber)
  brokerPhone!: PhoneNumber;

  @Expose()
  brokerEmail!: string;

  @Expose()
  remarks!: string;

  @Expose()
  commType!: ECommType;

  @Expose()
  @Type(() => Number)
  receivedPercent!: number;

  @Expose()
  @Type(() => Number)
  receivedValue!: number;

  @Expose()
  @Type(() => GrossCommDto)
  grossComm!: GrossCommDto;

  @Expose()
  attrs!: any;
}

export class ResaleRelatedAgentDto extends BaseModel {
  @Expose()
  @Default([])
  @Type(() => ResaleInternalCoBrokeDto)
  mains!: ResaleInternalCoBrokeDto[];

  @Expose()
  @Default([])
  @Type(() => ResaleInternalCoBrokeDto)
  internals!: ResaleInternalCoBrokeDto[];

  @Expose()
  @Default([])
  @Type(() => ResaleExternalCoBrokeDto)
  externals!: ResaleExternalCoBrokeDto[];
}

export class ResaleBillingPreviewDto extends BaseModel {
  @Expose()
  gstCategory!: string;

  @Expose()
  @Type(() => Number)
  @Default(0)
  transactionAmount!: number;

  @Expose()
  gstType!: EGstType;

  @Expose()
  gstPercent!: number;

  @Expose()
  @Type(() => ResaleBillingCommissionDto)
  commission!: ResaleBillingCommissionDto;

  @Expose()
  @Transform(() => ({}))
  grossComm!: object;
}

export class ResaleTransactionPreviewDto extends BaseModel {
  @Expose()
  @Type(() => ResaleRelatedAgentDto)
  relatedAgent!: ResaleRelatedAgentDto;

  @Expose()
  @Type(() => ResaleBillingPreviewDto)
  billing!: ResaleBillingPreviewDto;
}

export class ResaleTransactionDto extends BaseModel {
  @Expose()
  name!: string;

  @Expose()
  resaleType!: EResaleType;

  @Expose()
  propertyType!: EResalePropertyType;

  @Expose()
  companyId!: number;

  @Expose()
  @Type(() => Date)
  @FormattedDateString()
  submissionDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  agreementDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  commencementDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  optionDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  expireDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Transform(({ value }) => {
    if (!value) {
      return null;
    }
    return value;
  })
  completionDate!: string;

  @Expose()
  remarksInternal!: string;

  @Expose()
  remarks!: string;

  @Expose()
  countryId!: number;

  @Expose()
  furnished!: EFurnished;

  @Expose()
  @Type(() => ResaleRelatedAgentDto)
  relatedAgent!: ResaleRelatedAgentDto;

  @Expose()
  isInternal!: boolean;

  @Expose()
  paymentMethod!: EResalePaymentMethod;

  @Expose()
  @Type(() => ResaleBillingDto)
  billing!: ResaleBillingDto;

  @Expose()
  @Type(() => AddressDto)
  propertyAddress!: AddressDto;

  @Expose()
  metadata!: ResaleMetadataDto;

  @Expose()
  @Type(() => ResaleAreaDto)
  area!: ResaleAreaDto;

  @Expose()
  agreeToTerms!: boolean;
}

export class ResaleTransactionUpdateDto extends BaseModel {
  @Expose()
  @Default('full')
  group!: 'full';

  @Expose()
  @Type(() => ResaleTransactionDto)
  data!: ResaleTransactionDto;
}

export class ResaleTransactionUpdateSpecialFieldsDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  @Default(null)
  completionDate!: string;

  @Expose()
  @Type(() => ResaleBillingSpecialDto)
  billing!: ResaleBillingSpecialDto;
}

export class ResaleTransactionUpdatePaymentDto extends BaseModel {
  @Expose()
  metadata!: ResaleMetadataDto;

  @Expose()
  paymentMethod!: EResalePaymentMethod;

  @Expose()
  @Type(() => Date)
  @FormattedDateString()
  chequeDate!: string;
}

export class ResaleTransactionRemarkDto extends BaseModel {
  @Expose({ groups: ['remark'] })
  remarks?: string;

  @Expose({ groups: ['remark'] })
  remarksInternal?: string;
}

export class ResaleTransactionUpdateRemarkDto extends BaseModel {
  @Expose()
  @Default('remark')
  group!: 'remark';

  @Expose()
  @Transform(params => {
    return ResaleTransactionRemarkDto.fromJson(params.value, { groups: [params.obj.group] });
  })
  data!: ResaleTransactionRemarkDto;
}

export class ResaleTransactionDateFilterDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;
}

export class ResaleTransactionCheckDuplicateDto extends BaseModel {
  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  optionDate!: string;

  @Expose()
  clientName!: string;

  @Expose()
  salespersonId!: number;

  @Expose()
  @Type(() => AddressDto)
  propertyAddress!: AddressDto;
}


export class ResaleTransactionFiltersDto extends BaseModel {
  @Expose({ groups: ['default', 'advanced'] })
  key!: string;

  @Expose({ groups: ['default', 'advanced'] })
  orderBy!: string;

  @Expose({ groups: ['default', 'advanced'] })
  orderType!: string;

  @Expose({ groups: ['default', 'advanced'] })
  limit!: number;

  @Expose({ groups: ['default', 'advanced'] })
  page!: number;

  @Expose({ groups: ['advanced'] })
  searchType!: string;

  @Expose({ groups: ['advanced'] })
  transactionNo!: string;

  @Expose({ groups: ['advanced'] })
  @StartOfDate()
  @FormattedDateString()
  submittedDateStart!: string;

  @Expose({ groups: ['advanced'] })
  @EndOfDate()
  @FormattedDateString()
  submittedDateEnd!: string;

  // @Expose({ groups: ['advanced'] })
  // @StartOfDate()
  // @FormattedDateString()
  // resubmittedDateStart!: string;

  // @Expose({ groups: ['advanced'] })
  // @EndOfDate()
  // @FormattedDateString()
  // resubmittedDateEnd!: string;

  @Expose({ groups: ['advanced'] })
  transactionType!: string;

  @Expose({ groups: ['advanced'] })
  propertyAddress!: string;

  @Expose({ groups: ['advanced'] })
  agent!: string;

  @Expose({ groups: ['advanced'] })
  adminInCharge!: string;

  @Expose({ groups: ['advanced'] })
  invoiceNo!: string;

  @Expose({ groups: ['advanced'] })
  status!: string;

  @Expose({ groups: ['advanced'] })
  isWithPayment!: string;
}