import { PurposeRecord } from '../types';
import { EPurpose } from '../enums';

export const PURPOSE_OPTIONS: PurposeRecord = {
  [EPurpose.None]: {
    id: EPurpose.None,
    value: null,
    viewValue: '',
  },
  [EPurpose.OwnStay]: {
    id: EPurpose.OwnStay,
    value: EPurpose.OwnStay,
    viewValue: 'Own Stay',
  },
  [EPurpose.Investment]: {
    id: EPurpose.Investment,
    value: EPurpose.Investment,
    viewValue: 'Investment',
  },
};
