import { BaseModel, IPhoneNumber, ValidPhoneNumber } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { ContactType } from '../enums';
import { IContactAddressBase, IContactBase, IContactCategoryBase, IContactEmailBase, IContactStatusBase } from '../interfaces';
import { LedgerAccountModel } from './ledger-account.model';
import { UserAccountModel } from './user-account.model';

export class ContactBaseModel extends BaseModel implements IContactBase {
  @Expose()
  id!: number;

  @Expose()
  type!: ContactType;

  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  gstRegNo!: string;

  @Expose()
  ceaRegNo!: string;

  @Expose()
  businessRegNo!: string;

  @Expose()
  categoryId!: number;

  @Expose()
  category!: IContactCategoryBase;

  @Expose()
  creditTerm!: number;

  @Expose()
  creditLimit!: number;

  @Expose()
  gstRegistered!: boolean;

  @Expose()
  email!: string;

  @Expose()
  addresses!: IContactAddressBase[];

  @Expose()
  emails!: IContactEmailBase[];

  @Expose()
  // @ValidPhoneNumber()
  phone!: IPhoneNumber;

  @Expose()
  fax!: IPhoneNumber;

  @Expose()
  paidAccountId!: number;

  @Expose()
  @Transform((oject) => {
    if (oject.obj?.paidAccount) {
      return oject.obj?.paidAccount?.code
    }
    return oject.obj?.accountPayableCode
  })
  accountPayableCode!: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  paidAccount!: LedgerAccountModel;

  @Expose()
  receiveAccountId!: number;

  @Expose()
  @Transform((oject) => {
    if (oject.obj?.receiveAccount) {
      return oject.obj?.receiveAccount?.code
    }
    return oject.obj?.accountReceivableCode
  })
  accountReceivableCode!: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  receiveAccount!: LedgerAccountModel;

  @Expose()
  remark!: string;

  @Expose()
  status!: IContactStatusBase;

  @Expose()
  // commerceDate!: Date;
  commerceDate!: string;

  @Expose()
  @Type(() => UserAccountModel)
  updatedBy!: UserAccountModel;

  @Expose()
  // @Type(() => Date)
  // updatedAt!: Date;
  updatedAt!: string;

}
