import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { PersonalVerifyDto } from '@shared/data-access/dto';
import { IQuerySearch } from '@shared/data-access/interfaces';
import { EPersonalVerifyType } from '@shared/data-access/enums';
import { PersonalParticularModel, PersonalVerifyModel } from '@shared/data-access/models';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PersonalVerificationInfoApiService {
  static ROOT_POINT = 'hrm/salespersons-verify';
  private _type!: EPersonalVerifyType;

  constructor(private _apiService: ApiService, private location: Location) {
    const currentUrl = this.location.path();
    const hasBankInfo = currentUrl.includes('bank-info');
    this._type = hasBankInfo ? EPersonalVerifyType.bank : EPersonalVerifyType.general
  }

  // set type(type: EPersonalVerifyType) {
  //   this._type = type;
  // }

  get(itemId: number): Observable<PersonalVerifyModel[]> {
    const query: IQuerySearch = { limit: 1000, status: 'submitted' };
    return this._apiService
      .get([PersonalVerificationInfoApiService.ROOT_POINT, this._type, itemId].join('/'), query)
      .pipe(map(res => res.items.map((item: unknown) => PersonalVerifyModel.fromJson(item) as PersonalVerifyModel)));
  }

  update(id: number, data: PersonalVerifyDto[]): Observable<unknown> {
    return this._apiService.post([PersonalVerificationInfoApiService.ROOT_POINT, this._type, id, 'action'].join('/'), { data }, { excludeFields: [''] });
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<PersonalParticularModel>> {
    return this._apiService
      .get([PersonalVerificationInfoApiService.ROOT_POINT, this._type].join('/'), query, option)
      .pipe(map(data => new PaginationAdapter(PersonalParticularModel, data)));
  }
}
