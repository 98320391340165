import { EProjectTransactionStatus } from '../enums';
import { StatusRecord } from '../types/status.type';

export const PROJECT_TRANSACTION_STATUS = [
  {
    id: 'draft',
    name: 'Draft',
  },
  {
    id: 'submitted',
    name: 'Pending Verification',
  },
  {
    id: 'reword1',
    name: 'Send Back',
  },
  {
    id: 'reword2',
    name: 'Send Back',
  },
  {
    id: 'confirmed',
    name: 'Pending Approval',
  },
  {
    id: 'approved',
    name: 'Approved',
  },
] as const;

export const PROJECT_TRANSACTION_VERIFY_STATUS: StatusRecord<EProjectTransactionStatus> = {
  [EProjectTransactionStatus.draft]: {
    id: EProjectTransactionStatus.draft,
    name: 'Draft',
    filterName: 'Draft',
    class: 'status-box-default',
    canSubmit: true,
    canAgentSubmit: true,
    canEdit: true,
    canAgentEdit: true,
    canDelete: true,
    canAgentDelete: true,
  },
  [EProjectTransactionStatus.rework1]: {
    id: EProjectTransactionStatus.rework1,
    name: 'Send Back',
    filterName: 'Send Back Verification',
    class: 'status-box-danger',
    canSubmit: true,
    canAgentSubmit: true,
    canEdit: true,
    canAgentEdit: true,
    canDelete: true,
  },
  [EProjectTransactionStatus.rework2]: {
    id: EProjectTransactionStatus.rework2,
    name: 'Send Back',
    filterName: 'Send Back Approval',
    class: 'status-box-danger',
    canSubmit: true,
    canEdit: true,
    canDelete: true,
  },
  [EProjectTransactionStatus.rework3]: {
    id: EProjectTransactionStatus.rework3,
    name: 'Send Back',
    filterName: 'Send Back Invoice',
    class: 'status-box-danger',
    canDelete: true,
  },
  [EProjectTransactionStatus.submitted]: {
    id: EProjectTransactionStatus.submitted,
    name: 'Pending Verification',
    filterName: 'Pending Verification',
    class: 'status-box-warning',
    canEdit: true,
    canAgentEdit: true,
    canAgentSubmit: false,
    canDelete: true,
  },
  [EProjectTransactionStatus.confirmed]: {
    id: EProjectTransactionStatus.confirmed,
    name: 'Pending Approval',
    filterName: 'Pending Approval',
    class: 'status-box-warning',
    canEdit: true,
    canDelete: true,
  },
  [EProjectTransactionStatus.verified]: {
    id: EProjectTransactionStatus.verified,
    name: 'Pending Invoice',
    filterName: 'Pending Invoice',
    class: 'status-box-warning',
    canEdit: true,
    canDelete: true,
  },
  [EProjectTransactionStatus.approved]: {
    id: EProjectTransactionStatus.approved,
    name: 'Approved',
    filterName: 'Approved',
    class: 'status-box-success',
  },
  [EProjectTransactionStatus.invoiced]: {
    id: EProjectTransactionStatus.invoiced,
    name: 'Invoiced',
    filterName: 'Invoiced',
    class: 'status-box-turquoise-surf',
  },
  [EProjectTransactionStatus.completed]: {
    id: EProjectTransactionStatus.completed,
    name: 'Completed',
    filterName: 'Completed',
    class: 'status-box-success',
  },
};

export const PROJECT_TRANSACTION_STATUS_FILTER_OPTIONS = [
  {
    name: 'Draft',
    value: EProjectTransactionStatus.draft,
  },
  {
    name: 'Send Back',
    value: [EProjectTransactionStatus.rework1, EProjectTransactionStatus.rework2, EProjectTransactionStatus.rework3],
  },
  {
    name: 'Pending Verification',
    value: EProjectTransactionStatus.submitted,
  },
  {
    name: 'Pending Approval',
    value: EProjectTransactionStatus.confirmed,
  },
  {
    name: 'Pending Invoice',
    value: EProjectTransactionStatus.verified,
  },
  {
    name: 'Approved',
    value: EProjectTransactionStatus.approved,
  },
  {
    name: 'Invoiced',
    value: EProjectTransactionStatus.invoiced,
  },
];

export const PROJECT_TRANSACTION_STATUS_FILTER_OPTIONS_2 = [
  {
    name: 'Draft',
    value: EProjectTransactionStatus.draft,
  },
  {
    name: 'Send Back',
    value: EProjectTransactionStatus.rework1,
  },
  {
    name: 'Pending Verification',
    value: EProjectTransactionStatus.submitted,
  },
  {
    name: 'Pending Approval',
    value: EProjectTransactionStatus.confirmed,
  },
  {
    name: 'Pending Invoice',
    value: EProjectTransactionStatus.verified,
  },
  {
    name: 'Approved',
    value: EProjectTransactionStatus.approved,
  },
  {
    name: 'Invoiced',
    value: EProjectTransactionStatus.invoiced,
  },
];