import { Expose } from 'class-transformer';
import { ICurrency } from '../interfaces';
import { BaseFixedModel } from './base.model';

export class CurrencyModel extends BaseFixedModel implements ICurrency {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  value!: string;

  @Expose()
  exchange!: string;
}
