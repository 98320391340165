import { Injectable } from '@angular/core';
import { BlankFormApiService, KeyAppointmentApiService, ProjectApiService, ProjectTeamMemberApiService } from '@cms/apis';
import { BlankFormService } from '@cms/services';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BLankFormPdsPreviewDto } from '@shared/data-access/dto';
import { EBlankFormStatus, EProjectType } from '@shared/data-access/enums';
import {
  BlankFormBdModel,
  BlankFormIcModel,
  BlankFormInternalCoBrokeModel,
  BlankFormRelatedAgentModel,
  SplitMatrixModel,
  SplitPartModel,
} from '@shared/data-access/models';
import { DateTimeConverter } from '@shared/utils/datetime-converter';
import { uniqBy } from 'lodash-es';
import * as moment from 'moment';
import { forkJoin, iif, mapTo, of, switchMap, throwError, filter } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BlankFormAction } from '../actions';
import { BlankFormSelector } from '../selectors';

@Injectable()
export class BlankFormEffects {
  loadDefaultDataFromProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlankFormAction.loadDefaultDataFromProject),
      mergeMap(({ project, optionDate }) => {
        const optionDateDto = DateTimeConverter.formatISO(optionDate ? new Date(optionDate) : new Date(), { hours: 0, minutes: 0, seconds: 0 });
        return forkJoin([
          optionDate
            ? this.projectTeamMemberApiService.getTeamLeaders({
              projectId: project.id,
              getTiers: true,
              optionDate: optionDateDto,
            })
            : this.projectTeamMemberApiService.getTeamLeaders({
              projectId: project.id,
              getTiers: true,
            }),
          this.keyAppointmentApiService.search({ projectIds: project.id, optionDate: optionDateDto, getTiers: true }),
          this.apiService.previewPds(
            BLankFormPdsPreviewDto.fromJson({
              projectId: project.id,
              managementCommId: project.managementCommId,
              optionDate: optionDateDto,
            })
          ).pipe(catchError(() => of([])))
        ]).pipe(
          map(([teamLeaders, keyAppointmentsPaginated, pds]) => ({ teamLeaders, keyAppointmentsPaginated, pds })),
          switchMap(({ teamLeaders, keyAppointmentsPaginated, pds }) => {
            const leadersDefault = teamLeaders.reduce((acc, curr) => {
              const { splitMatrices } = curr.salesperson.commissionScheme;
              const defaultSplitMatrices = splitMatrices.map(({ tiers, parties }) => {
                tiers = tiers.map(tier => SplitPartModel.merge(tier, { value: tier.salespersonId ? 0 : null }));
                parties = parties.map(party => SplitPartModel.merge(party, { value: party.salespersonId ? 0 : null }));
                return BlankFormInternalCoBrokeModel.fromJson({
                  agentId: tiers[0].salespersonId,
                  agent: tiers[0].salesperson,
                  tiers,
                  parties,
                });
              });
              return [...acc, ...defaultSplitMatrices];
            }, [] as BlankFormInternalCoBrokeModel[]);
            const leaders = uniqBy(leadersDefault, 'agentId');
            const bonuses = keyAppointmentsPaginated.results.map(keyAppointment => {
              const { salesperson, salespersonId, appTypes } = keyAppointment;
              const { splitMatrices } = salesperson.commissionScheme;
              const splitMatrixDefault = SplitMatrixModel.createEmpty();
              let tiers: SplitPartModel[], parties: SplitPartModel[];
              if (splitMatrices.length > 0) {
                tiers = splitMatrices[0].tiers.map(tier =>
                  SplitPartModel.merge(tier, {
                    defaultValue: tier.salespersonId || tier.salesperson?.id ? tier.value ?? null : null,
                    value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
                  })
                );
                parties = splitMatrices[0].parties.map(party =>
                  SplitPartModel.merge(party, {
                    defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
                    value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
                  })
                );
              } else {
                splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
                  level: 1,
                  salespersonId,
                  salesperson,
                  value: 0,
                });
                tiers = splitMatrixDefault.tiers;
                parties = splitMatrixDefault.parties;
              }

              return BlankFormIcModel.fromJson({
                tiers,
                parties,
                salespersonId,
                salesperson,
                appointmentType: appTypes?.[0]?.appType,
                appointmentTypeId: appTypes?.[0]?.appType?.id,
              });
            });
            const overridings = [...bonuses];
            return of(
              BlankFormAction.updateDraftItem({
                data: {
                  relatedAgent: BlankFormRelatedAgentModel.merge(BlankFormRelatedAgentModel.createEmpty(), {
                    leaders,
                  }),
                  optionDate: optionDateDto,
                },
              }),
              BlankFormAction.setDefaultValueBonusesFromReference({ bonuses }),
              BlankFormAction.setDefaultValueOverridingsFromReference({ overridings }),
              BlankFormAction.setDefaultValuePdsFromReference({ pds }),
              BlankFormAction.setDefaultValuePoolsFromReference({ pools: [] }),
              BlankFormAction.setDefaultValueOtherFeeFromReference({ otherFees: [] }),
              BlankFormAction.setLoadingStatus({ loading: false })
            );
          })
        );
      })
    )
  );

  loadTeamLeaderDataFromProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlankFormAction.loadTeamLeaderDataFromProject),
      mergeMap(({ project, optionDate }) => {
        const optionDateDto = DateTimeConverter.formatISO(optionDate ? new Date(optionDate) : new Date(), { hours: 0, minutes: 0, seconds: 0 });
        return this.projectTeamMemberApiService.getTeamLeaders({
          projectId: project.id,
          getTiers: true,
          optionDate: optionDateDto,
        })
          .pipe(
            map((teamLeaders) => (teamLeaders)),
            switchMap((teamLeaders) => {
              const leadersDefault = teamLeaders.reduce((acc, curr) => {
                const { splitMatrices } = curr.salesperson.commissionScheme;
                const defaultSplitMatrices = splitMatrices.map(({ tiers, parties }) => {
                  tiers = tiers.map(tier => SplitPartModel.merge(tier, { value: tier.salespersonId ? 0 : null }));
                  parties = parties.map(party => SplitPartModel.merge(party, { value: party.salespersonId ? 0 : null }));
                  return BlankFormInternalCoBrokeModel.fromJson({
                    agentId: tiers[0].salespersonId,
                    agent: tiers[0].salesperson,
                    tiers,
                    parties,
                  });
                });
                return [...acc, ...defaultSplitMatrices];
              }, [] as BlankFormInternalCoBrokeModel[]);
              const leaders = uniqBy(leadersDefault, 'agentId');
              return of(
                BlankFormAction.updateDraftItem({
                  data: {
                    relatedAgent: BlankFormRelatedAgentModel.merge(BlankFormRelatedAgentModel.createEmpty(), {
                      leaders,
                    }),
                    optionDate: optionDateDto,
                  },
                }),
                BlankFormAction.setLoadingStatus({ loading: false })
              );
            })
          );
      })
    )
  );

  loadIcDataFromProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlankFormAction.loadIcDataFromProject),
      mergeMap(({ project, optionDate, typeIc }) => {
        const optionDateDto = DateTimeConverter.formatISO(optionDate ? new Date(optionDate) : new Date(), { hours: 0, minutes: 0, seconds: 0 });
        return this.keyAppointmentApiService.search({ projectIds: project.id, optionDate: optionDateDto, getTiers: true })
          .pipe(
            map((keyAppointmentsPaginated) => keyAppointmentsPaginated),
            switchMap((keyAppointmentsPaginated) => {
              const bonuses = keyAppointmentsPaginated.results.map(keyAppointment => {
                const { salesperson, salespersonId, appTypes } = keyAppointment;
                const { splitMatrices } = salesperson.commissionScheme;
                const splitMatrixDefault = SplitMatrixModel.createEmpty();
                let tiers: SplitPartModel[], parties: SplitPartModel[];
                if (splitMatrices.length > 0) {
                  tiers = splitMatrices[0].tiers.map(tier =>
                    SplitPartModel.merge(tier, {
                      defaultValue: tier.salespersonId || tier.salesperson?.id ? tier.value ?? null : null,
                      value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
                    })
                  );
                  parties = splitMatrices[0].parties.map(party =>
                    SplitPartModel.merge(party, {
                      defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
                      value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
                    })
                  );
                } else {
                  splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
                    level: 1,
                    salespersonId,
                    salesperson,
                    value: 0,
                  });
                  tiers = splitMatrixDefault.tiers;
                  parties = splitMatrixDefault.parties;
                }

                return BlankFormIcModel.fromJson({
                  tiers,
                  parties,
                  salespersonId,
                  salesperson,
                  appointmentType: appTypes?.[0]?.appType,
                  appointmentTypeId: appTypes?.[0]?.appType?.id,
                });
              });
              const overridings = [...bonuses];
              return of(
                BlankFormAction.setDefaultValueBonusesFromReference({ bonuses }),
                BlankFormAction.setDefaultValueOverridingsFromReference({ overridings }),
                BlankFormAction.setLoadingStatus({ loading: false })
              );
            })
          );
      })
    )
  );

  loadBdsFromProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlankFormAction.loadBdsFromProject),
      mergeMap(({ project, optionDate }) => {
        const optionDateDto = DateTimeConverter.formatISO(optionDate ? new Date(optionDate) : new Date(), { hours: 0, minutes: 0, seconds: 0 });
        return optionDate && project.entity === EProjectType.INTERNATIONAL
          ? this.projectApiService.getBusinessDirector({ projectIds: project.id, optionDate: optionDateDto, getTiers: true }).pipe(
            switchMap(bdsPaginated => {
              const bds =
                bdsPaginated.results
                  .filter(bd =>
                    moment(optionDate)
                      .startOf('day')
                      .isBetween(moment(bd.startDate).startOf('day'), moment(bd.endDate ?? optionDate).startOf('day'), 'day', '[]')
                  )
                  .map(bd => {
                    const { salesperson, salespersonId, appTypes, type } = bd;
                    const { splitMatrices } = salesperson.commissionScheme;
                    const splitMatrixDefault = SplitMatrixModel.createEmpty();

                    let tiers: SplitPartModel[], parties: SplitPartModel[];
                    if (splitMatrices.length > 0) {
                      tiers = splitMatrices[0].tiers.map(tier =>
                        SplitPartModel.merge(tier, {
                          defaultValue: tier.salespersonId || tier.salesperson?.id ? tier.value ?? null : null,
                          value: tier.salespersonId ?? tier.salesperson?.id ? 0 : null,
                        })
                      );
                      parties = splitMatrices[0].parties.map(party =>
                        SplitPartModel.merge(party, {
                          defaultValue: party.salespersonId ?? party.salesperson?.id ? party.value : null ?? party.value,
                          value: party.salespersonId ?? party.salesperson?.id ? 0 : null,
                        })
                      );
                    } else {
                      splitMatrixDefault.tiers[0] = SplitPartModel.fromJson({
                        level: 1,
                        salespersonId,
                        salesperson,
                        value: 0,
                      });
                      //const { tiers, parties } = splitMatrixDefault;
                      tiers = splitMatrixDefault.tiers;
                      parties = splitMatrixDefault.parties;
                    }
                    return BlankFormBdModel.fromJson({
                      type,
                      tiers,
                      parties,
                      salespersonId,
                      salesperson,
                      appointmentType: appTypes?.[0]?.appType,
                      appointmentTypeId: appTypes?.[0]?.appType?.id,
                    });
                  }) ?? [];
              return of(
                BlankFormAction.setDefaultValueBdsFromReference({ bds }),
                BlankFormAction.setBdsFromProject({ bdsFromProject: bdsPaginated?.results ?? [] }),
                BlankFormAction.setLoadingStatus({ loading: false })
              );
            })
          )
          : of(BlankFormAction.setDefaultValueBdsFromReference({ bds: [] }), BlankFormAction.setBdsFromProject({ bdsFromProject: [] }))
      })
    )
  );

  saveDratItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlankFormAction.saveDraftItem),
      concatLatestFrom(() => this.store.select(BlankFormSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType }]) =>
        iif(
          () => draftType === 'create' || draftType === 'clone',
          this.blankFormService.createFullData(draftItem),
          this.blankFormService.editFullData(draftItem)
        ).pipe(
          switchMap(res => of(BlankFormAction.submitItemSuccess({ res }))),
          catchError(error => of(BlankFormAction.submitItemFail({ error })))
        )
      )
    )
  );

  saveAndSubmitDraftItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlankFormAction.saveAndSubmitDraftItem),
      concatLatestFrom(() => this.store.select(BlankFormSelector.selectDraftState)),
      mergeMap(([_, { draftItem, draftType }]) =>
        iif(
          () => draftType === 'create' || draftType === 'clone',
          this.blankFormService.createFullData(draftItem),
          this.blankFormService.editFullData(draftItem)
        ).pipe(
          switchMap(id => {
            return this.apiService.submit(id).pipe(
              mapTo({
                id,
                status: EBlankFormStatus.submitted,
                blankFormType: draftItem.blankFormType,
              }),
              catchError(error => {
                return throwError(() => {
                  return { ...error, errorOnSubmit: true, submitData: { id } };
                });
              })
            );
          }),
          switchMap(res => of(BlankFormAction.submitItemSuccess({ res }))),
          catchError(error => of(BlankFormAction.submitItemFail({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private blankFormService: BlankFormService,
    private apiService: BlankFormApiService,
    private projectTeamMemberApiService: ProjectTeamMemberApiService,
    private keyAppointmentApiService: KeyAppointmentApiService,
    private projectApiService: ProjectApiService
  ) { }
}
